/* eslint-disable prefer-destructuring */
import React from 'react';
import {
  withLocalize,
  Translate,
  LocalizeContextProps,
  Language,
} from 'react-localize-redux';
import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import { knownLangs } from '../../config/options';

import '../../scss/components/language-toggle.scss';

interface LanguageToggleProps extends LocalizeContextProps {
  customer: string;
}

interface LanguageToggleState {
  activeKey: string;
}

class LanguageToggle extends React.Component<LanguageToggleProps, LanguageToggleState> {
  constructor(props: LanguageToggleProps) {
    super(props);

    this.state = {
      activeKey: 'de',
    };
  }

  componentDidMount = () => {
    const { setActiveLanguage, translate } = this.props;

    let shortLang = window.navigator.languages ? window.navigator.languages[0] : window.navigator.language;
    if (shortLang.indexOf('-') !== -1) shortLang = shortLang.split('-')[0];
    if (shortLang.indexOf('_') !== -1) shortLang = shortLang.split('_')[0];
    if (knownLangs.includes(shortLang)) {
      this.setState({ activeKey: shortLang }, () => {
        setActiveLanguage(shortLang);
        document.title = translate('product_configurator', undefined, { language: shortLang }).toString();
      });
    }
  };

  handleClick = (language: Language) => {
    const { setActiveLanguage, translate } = this.props;
    this.setState({ activeKey: language.code }, () => {
      setActiveLanguage(language.code);
      document.title = translate('product_configurator', undefined, { language: language.code }).toString();
    });
  };

  render() {
    const { languages, translate, customer } = this.props;
    const { activeKey } = this.state;
    return (
      <DropdownButton
        alignRight
        variant={customer !== 'sontex' ? 'dark' : 'light'}
        className="position-right"
        as={ButtonGroup}
        title={<span className="country-flag"><ReactCountryFlag countryCode={activeKey === 'en' ? 'gb' : activeKey} svg /></span>}
        id="bg-nested-dropdown"
      >
        {languages.map((l: Language) => (
          <Dropdown.Item eventKey={l.code} key={l.code} onClick={() => this.handleClick(l)}>
            <span className="country-flag">
              <ReactCountryFlag alt={translate('choose-language')} countryCode={l.code === 'en' ? 'gb' : l.code} svg />
            </span>
            <Translate id={`lang.${l.name?.toLowerCase()}`} />
          </Dropdown.Item>
        ))}
      </DropdownButton>
    );
  }
}

export default withLocalize(LanguageToggle);
