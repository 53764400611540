import {
  faChevronLeft, faChevronRight, faMinus, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import {
  Col, ProgressBar, Row,
} from 'react-bootstrap';
import DualListBox from 'react-dual-listbox';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { ILoraFrameOption } from '../../../config/models';
import { loraFrameOptions } from '../../../config/options';

interface DualListProps extends LocalizeContextProps {
  setCurrentBytes: (currentBytes: number) => void;
  currentBytes: number;
  maxBytes: number;
  setSelected: (selected: ILoraFrameOption[]) => void;
  selected: ILoraFrameOption[];
  parseOptions: (options: ILoraFrameOption[]) => any;
}

const DualList = (props: DualListProps) => {
  const {
    translate, setCurrentBytes, currentBytes, setSelected, selected, parseOptions, maxBytes,
  } = props;

  useEffect(() => {
    let count = 0;
    selected.forEach((s) => { count += s.bytes; });
    setCurrentBytes(count);
  }, [selected]);

  const onChange = (selectedOptions: any[]) => {
    setSelected(selectedOptions);
  };

  const translatedStrings = {
    moveAllLeft: `${translate('list.move-all-left')}`,
    moveAllRight: `${translate('list.move-all-right')}`,
    moveLeft: `${translate('list.move-left')}`,
    moveRight: `${translate('list.move-right')}`,
    availableHeader: `${translate('list.available')}`,
    selectedHeader: `${translate('list.selected')}`,
  };

  return (
    <>
      <DualListBox
        options={parseOptions(loraFrameOptions)}
        selected={selected}
        onChange={onChange}
        icons={{
          moveLeft: <FontAwesomeIcon icon={faMinus} />,
          moveAllLeft: [
            <FontAwesomeIcon icon={faChevronLeft} key="1" />,
            <FontAwesomeIcon icon={faChevronLeft} key="2" />],
          moveRight: <FontAwesomeIcon icon={faPlus} />,
          moveAllRight: [
            <FontAwesomeIcon icon={faChevronRight} key="1" />,
            <FontAwesomeIcon icon={faChevronRight} key="2" />],
        }}
        filterPlaceholder={`${translate('search')}...`}
        lang={translatedStrings}
        simpleValue={false}
        showHeaderLabels
        canFilter
      />
      <Row className="mt-3">
        <Col>
          <Translate id="frame-length" />
          :
        </Col>
        <Col className="mr-4">
          <ProgressBar
            now={(currentBytes / maxBytes) * 100}
            label={`${currentBytes} / ${maxBytes}`}
            variant={currentBytes > maxBytes ? 'danger' : ''}
          />
        </Col>
      </Row>
    </>
  );
};

export default withLocalize(DualList);
