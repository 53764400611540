/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import {
  Container, Row, Col, Card, Image,
} from 'react-bootstrap';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

import ewzImage from '../../assets/ewz.jpg';
import famImage from '../../assets/fam.jpg';
import weCountImage from '../../assets/wecount.png';
import weCountsImage from '../../assets/wecounts.png';
// import yflowImage from '../../assets/yflow.jpg';
import { DispatchActionTypes } from '../../state/actionTypes';
import setCurrentTab from '../../state/actions/currentTabAction';
import setCurrentPage from '../../state/actions/currentPageAction';
import { ModelType } from '../../config/models';

interface DeviceSelectorProps extends LocalizeContextProps {
  saveCurrentTab: (tab: ModelType) => void;
  saveCurrentPage: (pageTitle: string) => void;
}

interface DeviceSelectorState {
}

class DeviceSelector extends React.Component<DeviceSelectorProps, DeviceSelectorState> {
  render() {
    const { saveCurrentTab, saveCurrentPage, translate } = this.props;

    return (
      <Container>
        <Card body>
          <Row className="d-flex justify-content-center mb-3">
            <h3>
              <Translate id="welcome" />
            </h3>
            <Link to="/about" onClick={() => { saveCurrentPage('/about'); }}>
              <span className="start-welcome-helpicon">
                <FontAwesomeIcon icon={faQuestion} />
              </span>
            </Link>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mb-3">
              <h5>
                <Translate id="choose-product" />
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="text-center mb-3">
              <Image
                className="mb-3"
                src={ewzImage}
                thumbnail
              />
              <Link to="/configure">
                <div
                  className="mb-1 clickable-img"
                  role="button"
                  tabIndex={0}
                  onClick={() => { saveCurrentPage('/configure'); saveCurrentTab('ewz'); }}
                >
                  <Image
                    src={weCountImage}
                    alt={translate('devices.ewz.full').toString()}
                  />
                </div>
              </Link>
              <div className="clickable-img-divider">{translate('or')}</div>
              <Link to="/configure">
                <div
                  className="mb-3 mt-1 clickable-img"
                  role="button"
                  tabIndex={0}
                  onClick={() => { saveCurrentPage('/configure'); saveCurrentTab('ewz2'); }}
                >
                  <Image
                    src={weCountsImage}
                    alt={translate('devices.ewz2.full').toString()}
                  />
                </div>
              </Link>
            </Col>
            <Col xs={12} md={6} className="text-center mb-3">
              <Image
                className="mb-3"
                src={famImage}
                thumbnail
                onClick={() => { saveCurrentPage('/configure'); saveCurrentTab('fam'); }}
              />
              <Link to="/configure">
                <div
                  className="start-device-label clickable-img"
                  role="button"
                  tabIndex={0}
                  onClick={() => { saveCurrentPage('/configure'); saveCurrentTab('fam'); }}
                >
                  MODULARIS Funkaufsatzmodul
                  <br />
                  µflow skyW-2
                </div>
                {/* <Image
                  className="mb-3 clickable-img"
                  src={yflowImage}
                  alt={translate('devices.fam.full').toString()}
                  onClick={() => { saveCurrentPage('/configure'); saveCurrentTab('fam'); }}
                /> */}
              </Link>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveCurrentTab: (tab: ModelType) => dispatch(setCurrentTab(tab)),
  saveCurrentPage: (pageTitle: string) => dispatch(setCurrentPage(pageTitle)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withLocalize(DeviceSelector));
