import { PageAccordionState } from '../components/TabContent/PageAccordion';
import { ModelType } from '../config/models';

export const SET_CURRENT_CUSTOMER = 'SET_CURRENT_CUSTOMER';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_IS_EXPERT = 'SET_IS_EXPERT';
export const SET_ALL_CONFIGS = 'SET_ALL_CONFIGS';
export const RESET_ALL_CONFIGS = 'RESET_ALL_CONFIGS';
export const SET_WALKBY_CONFIG = 'SET_WALKBY_CONFIG';
export const SET_STATIONARY_CONFIG = 'SET_STATIONARY_CONFIG';

/* STATE */
export type CurrentPageState = string;
export type CurrentCustomerState = string;
export type CurrentTabState = ModelType;
export type IsExpertState = boolean;
export type CollapseState = {
  generalCollapsed: boolean;
  wirelessCollapsed: boolean;
  radio_protocol?: string;
};

/* Setter functions */
interface SetCurrentCustomer {
  type: typeof SET_CURRENT_CUSTOMER;
  payload: CurrentCustomerState;
}
interface SetCurrentPage {
  type: typeof SET_CURRENT_PAGE;
  payload: CurrentPageState;
}
interface SetCurrentTab {
  type: typeof SET_CURRENT_TAB;
  payload: CurrentTabState;
}
interface SetIsExpert {
  type: typeof SET_IS_EXPERT;
  payload?: IsExpertState;
}
interface SetAllConfigs {
  type: typeof SET_ALL_CONFIGS;
  payload: PageAccordionState;
}
interface ResetAllConfigs {
  type: typeof RESET_ALL_CONFIGS;
  payload: CollapseState;
}
interface SetWalkByConfig {
  type: typeof SET_WALKBY_CONFIG;
  payload: CollapseState;
}
interface SetStationaryConfig {
  type: typeof SET_STATIONARY_CONFIG;
  payload: CollapseState;
}

/* Action Types */
export type CurrentCustomerActionTypes = SetCurrentCustomer;
export type CurrentPageActionTypes = SetCurrentPage;
export type CurrentTabActionTypes = SetCurrentTab;
export type IsExpertActionTypes = SetIsExpert;
export type AllConfigsActionTypes =
  SetAllConfigs
  | ResetAllConfigs
  | SetWalkByConfig
  | SetStationaryConfig;

/* Dispatch Action Types */
export type DispatchActionTypes =
  SetCurrentCustomer
  | SetCurrentPage
  | SetCurrentTab
  | SetIsExpert
  | SetAllConfigs
  | ResetAllConfigs
  | SetWalkByConfig
  | SetStationaryConfig;
