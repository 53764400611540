import React from 'react';
import { withLocalize, LocalizeContextProps, Translate } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  Tabs, Tab, TabContent, Image, Row, Col, Button, OverlayTrigger, Tooltip,
} from 'react-bootstrap';

import '../../scss/components/page-tabs.scss';
import { faCogs, faUserCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ewzIcon from '../../assets/ewz_icon.jpg';
import famIcon from '../../assets/fam_icon.jpg';
import { DispatchActionTypes } from '../../state/actionTypes';
import setCurrentTab from '../../state/actions/currentTabAction';
import setIsExpert from '../../state/actions/isExpertAction';
import PageAccordion from './PageAccordion';
import HashInput from '../LoadConfig/ConfigLoader';
import { AppState } from '../../state/reducers';
import { ModelType } from '../../config/models';

interface PageTabsProps extends LocalizeContextProps {
  customer: string;
  page: string;
  currentTab: ModelType;
  saveCurrentTab: (tab: ModelType) => void;
  isExpert: boolean;
  saveIsExpert: (isExpert?: boolean) => void;
}

interface PageTabsState {
}

class PageTabs extends React.Component<PageTabsProps, PageTabsState> {
  getTabContent = (tab: ModelType) => (
    <TabContent>
      <br />
      <PageAccordion key={tab} />
    </TabContent>
  );

  handleDeviceSelect = (activeKey: string | null) => {
    const { saveCurrentTab } = this.props;
    if (activeKey) {
      saveCurrentTab(`${activeKey as ModelType}`);
    }
  };

  getExpertButton = () => {
    const { isExpert, saveIsExpert } = this.props;
    const icon = isExpert ? faUserCog : faCogs;
    const text = isExpert ? 'easy-mode' : 'expert-mode';
    return (
      <OverlayTrigger
        key="expert-settings-button"
        placement="top"
        trigger={['hover']}
        overlay={(
          <Tooltip id="tooltip-expert-settings-button">
            <Translate id={isExpert
              ? 'easy-settings-button-desc'
              : 'expert-settings-button-desc'}
            />
          </Tooltip>
        )}
      >
        <Button
          className="expert-mode-btn"
          onClick={() => saveIsExpert()}
          variant="outline-secondary"
        >
          <FontAwesomeIcon icon={icon} />
          {' '}
          <Translate id={text} />
        </Button>
      </OverlayTrigger>
    );
  };

  getTabComponent = (page: string) => {
    const { customer, currentTab, translate } = this.props;
    switch (page) {
      default:
        return (
          <>
            <Tabs
              id="controlled_tab"
              activeKey={currentTab}
              onSelect={this.handleDeviceSelect}
              key={currentTab}
            >
              {customer !== 'sontex' && (
                <Tab
                  eventKey="ewz"
                  title={(
                    <>
                      <Image className="tab-icon" src={ewzIcon} />
                      {translate('devices.ewz.short')}
                    </>
                  )}
                >
                  {this.getTabContent('ewz')}
                </Tab>
              )}
              <Tab
                eventKey="ewz2"
                title={(
                  <>
                    <Image className="tab-icon" src={ewzIcon} />
                    {customer !== 'sontex' ? translate('devices.ewz2.short')
                      : translate('devices.superaqua.short')}
                  </>
                )}
              >
                {this.getTabContent('ewz2')}
              </Tab>
              {customer !== 'sontex' && (
                <Tab
                  eventKey="fam"
                  title={(
                    <>
                      <Image className="tab-icon" src={famIcon} />
                      {translate('devices.fam.short')}
                    </>
                  )}
                >
                  {this.getTabContent('fam')}
                </Tab>
              )}
            </Tabs>
            <br />
            <Row className="mt-3" id="config-footer-buttons">
              {this.getExpertButton()}
              <Col>
                <HashInput />
              </Col>
            </Row>
          </>
        );
    }
  };

  render() {
    const { page } = this.props;
    return (
      this.getTabComponent(page)
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  customer: state.currentCustomer,
  currentTab: state.currentTab,
  isExpert: state.isExpert,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveCurrentTab: (tab: ModelType) => dispatch(setCurrentTab(tab)),
  saveIsExpert: (isExpert?: boolean) => dispatch(setIsExpert(isExpert)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(PageTabs));
