import { SET_CURRENT_CUSTOMER, CurrentCustomerActionTypes, CurrentCustomerState } from '../actionTypes';

const initialState: CurrentCustomerState = '';

const currentCustomerReducer = (
  state: CurrentCustomerState = initialState,
  action: CurrentCustomerActionTypes,
): CurrentCustomerState => {
  switch (action.type) {
    case SET_CURRENT_CUSTOMER:
      return action.payload;

    default:
      return state;
  }
};

export default currentCustomerReducer;
