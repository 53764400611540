/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import {
  Container, Row, Col, Card, Image,
} from 'react-bootstrap';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

import ewzImage from '../../assets/ewz.jpg';
import superaquaImage from '../../assets/superaqua.png';
// import yflowImage from '../../assets/yflow.jpg';
import { DispatchActionTypes } from '../../state/actionTypes';
import setCurrentTab from '../../state/actions/currentTabAction';
import setCurrentPage from '../../state/actions/currentPageAction';
import { ModelType } from '../../config/models';

interface DeviceSelectorProps extends LocalizeContextProps {
  saveCurrentTab: (tab: ModelType) => void;
  saveCurrentPage: (pageTitle: string) => void;
}

interface DeviceSelectorState {
}

class DeviceSelector extends React.Component<DeviceSelectorProps, DeviceSelectorState> {
  render() {
    const { saveCurrentTab, saveCurrentPage, translate } = this.props;

    return (
      <Container>
        <Card body>
          <Row className="d-flex justify-content-center mb-3">
            <h3>
              <Translate id="welcome" />
            </h3>
            <Link to="/about" onClick={() => { saveCurrentPage('/about'); }}>
              <span className="start-welcome-helpicon">
                <FontAwesomeIcon icon={faQuestion} />
              </span>
            </Link>
          </Row>
          <Row>
            <Col xs={12} className="text-center px-5 mb-3">
              <Link to="/configure">
                <Image
                  className="px-5 mb-3"
                  src={ewzImage}
                  thumbnail
                  onClick={() => { saveCurrentPage('/configure'); saveCurrentTab('ewz2'); }}
                />
                <div
                  className="mb-3 mt-1 clickable-img"
                  role="button"
                  tabIndex={0}
                  onClick={() => { saveCurrentPage('/configure'); saveCurrentTab('ewz2'); }}
                >
                  <Image
                    src={superaquaImage}
                    alt={translate('devices.superaqua.full').toString()}
                  />
                </div>
              </Link>
            </Col>
          </Row>
        </Card>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveCurrentTab: (tab: ModelType) => dispatch(setCurrentTab(tab)),
  saveCurrentPage: (pageTitle: string) => dispatch(setCurrentPage(pageTitle)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withLocalize(DeviceSelector));
