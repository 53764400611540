import React from 'react';

import PageCard from './PageCard';

const ConfigurePage = () => (
  <>
    <PageCard
      title="configure"
      useTabs
    />
  </>
);

export default ConfigurePage;
