import React from 'react';
import {
  Modal as BsModal, Button, Col, Row,
} from 'react-bootstrap';
import { Translate, withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { toastr } from 'react-redux-toastr';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faUndo } from '@fortawesome/free-solid-svg-icons';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import RadioHoursSelector from './RadioHoursSelector';
import MonthSelector from './MonthSelector';
import BooleanSelector from '../InputFields/BooleanSelector';
import WeekdaysSelector from './WeekdaysSelector';
import MonthlyDaysSelector from './MonthlyDaysSelector';
import { loraRadioCalendar } from '../../../config/deviceConfigs';
import { AppState } from '../../../state/reducers';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { PageAccordionState } from '../PageAccordion';
import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { ModelType } from '../../../config/models';

interface RadioCalendarModalProps extends LocalizeContextProps {
  tab: ModelType;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
  showModal: boolean;
  label: string;
  handleClose: () => void;
  showRadioDays: boolean;
}

const RadioCalendarModal = (props: RadioCalendarModalProps) => {
  const {
    label, showRadioDays, saveAllConfigs, config, tab,
    showModal, handleClose, translate,
  } = props;

  const inputFields = [
    {
      label: 'radio_months',
      component: <MonthSelector />,
    },
    {
      label: 'radio_days_of_week_active',
      component: <BooleanSelector label="radio_days_of_week_active" />,
    },
    {
      label: 'radio_days',
      component: <WeekdaysSelector />,
      hide: !showRadioDays,
    },
    {
      label: 'radio_days_of_month',
      component: <MonthlyDaysSelector />,
      hide: showRadioDays,
    },
    {
      label: 'radio_hours',
      component: <RadioHoursSelector />,
    },
  ];

  const resetRadioCalendar = () => {
    toastr.success((translate('success').toString()), (translate('radio-calendar-reset').toString()));
    saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: {
        ...config[tab],
        ...loraRadioCalendar,
      },
    });
  };

  return ReactDOM.createPortal(
    <BsModal show={showModal} onHide={handleClose} style={{ zIndex: 9999 }}>
      <BsModal.Header closeButton>
        <BsModal.Title>
          <FontAwesomeIcon icon={faCalendarAlt} />
          {' '}
          <Translate id={label} />
        </BsModal.Title>
      </BsModal.Header>
      <BsModal.Body>
        {inputFields.map((field) => {
          if (field.hide) return null;
          return (
            <div key={`radio-calendar-${field.label}`} className="setting-row">
              <Row className={field.label === 'radio_hours' ? 'pt-1' : ''}>
                <Col xs={4}>
                  <div className="setting__label">
                    <Translate id={`wireless_settings.settings.${field.label}.label`} />
                  </div>
                </Col>
                <Col xs={8}>
                  {field.component}
                </Col>
              </Row>
            </div>
          );
        })}

      </BsModal.Body>
      <BsModal.Footer>
        <Button variant="secondary" onClick={resetRadioCalendar} className="ok-button">
          <FontAwesomeIcon icon={faUndo} />
          {' '}
          <Translate id="reset" />
        </Button>
        <Button variant="primary" onClick={handleClose} className="ok-button">
          <FontAwesomeIcon icon={faCheck} />
          {' '}
          <Translate id="close" />
        </Button>
      </BsModal.Footer>
    </BsModal>,
    document.getElementById('root')!,
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(RadioCalendarModal));
