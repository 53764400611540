import React, { ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import {
  radioFrequencies,
  radioFrames,
  billingDateMonths,
  getBillingDateRadioDays,
  getMonthlyRadioDays,
  calibrationPeriods,
  lcdSequenceOptions,
  ewz2BatteryOptions,
  radioModes,
  radioProtocols,
  encryptionModes,
  encryptionModesSontex,
  loraConfirmModeOptions,
  RadioProtocol,

} from '../../../config/options';
import { PageAccordionState } from '../PageAccordion';
import { AppState } from '../../../state/reducers';
import BooleanSelector from './BooleanSelector';
import { EWZ2BatterOption, ModelType } from '../../../config/models';

interface RadioFieldsSelectorProps extends LocalizeContextProps {
  config: PageAccordionState;
  tab: ModelType;
  label: string;
  handleTextInput: (tab: ModelType, label: string, input: string) => void;
  saveAllConfigs: (config: PageAccordionState) => void;
  input: string;
  currentCustomer: string;
}

const RadioFieldsSelector = (props: RadioFieldsSelectorProps) => {
  const {
    config, tab, label, translate, handleTextInput, input, saveAllConfigs, currentCustomer,
  } = props;

  const handleNumberInput = (value: number) => {
    saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: { ...config[tab], [label]: Number(value) },
    });
  };

  const handleObject = (value: number) => {
    saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: {
        ...config[tab],
        [label]: {
          duration: value,
          active: +value ? config[tab][label]?.active : false,
          lcdActive: +value ? config[tab][label]?.lcdActive : false,
        },
      },
    });
  };

  const getSelectOptions = () => {
    let radioDays;
    switch (label) {
      case 'billing_date_radio_days':
        radioDays = getBillingDateRadioDays();
        return (
          <>
            {radioDays.map((radioDay: number) => <option key={radioDay} value={radioDay}>{radioDay}</option>)}
          </>
        );
      case 'monthly_radio_days':
        radioDays = getMonthlyRadioDays();
        return (
          <>
            {radioDays.map((radioDay: number) => <option key={radioDay} value={Number(radioDay)}>{radioDay}</option>)}
          </>
        );
      case 'radio_frequency':
        return (
          <>
            {radioFrequencies.map((frequency: number) => (
              <option key={frequency} value={frequency}>{translate(`wireless_settings.settings.radio_frequency.values.${frequency}`)}</option>
            ))}
          </>
        );
      case 'radio_mode':
        return (
          <>
            {radioModes.map((mode: string) => (
              <option key={mode} value={mode}>{translate(`wireless_settings.settings.radio_mode.values.${mode}`)}</option>
            ))}
          </>
        );
      case 'radio_frame':
        return (
          <>
            {radioFrames.map((protocol: string) => (
              <option key={protocol} value={protocol}>{translate(`wireless_settings.settings.radio_frame.values.${protocol}`)}</option>
            ))}
          </>
        );
      case 'billing_date': {
        const billingDateMonthArray = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const month in billingDateMonths) {
          billingDateMonthArray.push(
            <option key={month} value={month}>
              {billingDateMonths[month]}
              {' '}
              {translate(`months.${month}`)}
            </option>,
          );
        }
        return (
          <>
            {billingDateMonthArray.map((month: ReactElement) => month)}
          </>
        );
      }
      case 'calibration_period': {
        return (
          <>
            {calibrationPeriods.map((period: number) => (
              <option key={period} value={period}>{translate(`general_settings.settings.calibration_period.values.${period}`)}</option>
            ))}
          </>
        );
      }
      case 'lcd_sequence': {
        return (
          <>
            {lcdSequenceOptions.map((sequence: string) => (
              <option key={sequence} value={sequence}>{translate(`general_settings.settings.lcd_sequence.values.${sequence}`)}</option>
            ))}
          </>
        );
      }
      case 'battery_size': {
        return (
          <>
            {ewz2BatteryOptions.map((battery: EWZ2BatterOption) => (
              <option
                disabled={battery.disabled}
                key={battery.label}
                value={battery.label}
              >
                {translate(`general_settings.settings.battery_size.values.${battery.label}`)}
              </option>
            ))}
          </>
        );
      }
      case 'radio_protocol': {
        if (currentCustomer === 'sontex') {
          return (
            <>
              {radioProtocols.map((protocol: string) => (
                <option key={protocol} value={protocol}>{translate(`wireless_settings.settings.radio_protocol.values.${protocol}`)}</option>
              ))}
            </>
          );
        }
        return (
          <>
            {radioProtocols.filter((protocol: string) => protocol !== RadioProtocol.SONTEX_433).map((protocol: string) => (
              <option key={protocol} value={protocol}>{translate(`wireless_settings.settings.radio_protocol.values.${protocol}`)}</option>
            ))}
          </>
        );
      }
      case 'encryption_mode': {
        if (config[tab].radio_protocol === RadioProtocol.SONTEX_433) {
          return (
            <>
              {encryptionModesSontex.map((encryption: string) => (
                <option key={encryption} value={encryption}>{translate(`wireless_settings.settings.encryption_mode.values.${encryption}`)}</option>
              ))}
            </>
          );
        }
        return (
          <>
            {encryptionModes.map((encryption: string) => (
              <option key={encryption} value={encryption}>{translate(`wireless_settings.settings.encryption_mode.values.${encryption}`)}</option>
            ))}
          </>
        );
      }
      case 'lorawan_confirm_mode':
        return (
          <>
            {loraConfirmModeOptions.map((mode: string) => (
              <option key={mode} value={mode}>{translate(`wireless_settings.settings.lorawan_confirm_mode.values.${mode}`)}</option>
            ))}
          </>
        );
      default:
        return null;
    }
  };

  const getClassNames = () => {
    if (tab === 'ewz2') {
      if (label === 'radio_protocol' && config[tab].radio_protocol === 'please-choose') return 'invalid-input';
    }
    return '';
  };

  if (input === 'select_number') {
    if (label === 'calibration_period') {
      return (
        <>
          <BooleanSelector label={label} />
          {(config[tab][label].active || config[tab][label].lcdActive) && (
            <Form.Control
              as="select"
              value={config[tab][label]?.duration}
              className={config.invalidValueFields.calibration_period ? 'invalid-input my-2' : 'my-2'}
              onChange={(event: React.FormEvent) => { handleObject(Number((event.target as HTMLInputElement).value)); }}
            >
              {getSelectOptions()}
            </Form.Control>
          )}
        </>
      );
    }
    return (
      <Form.Control
        as="select"
        value={config[tab][label]}
        onChange={(event: React.FormEvent) => handleNumberInput(Number((event.target as HTMLInputElement).value))}
      >
        {getSelectOptions()}
      </Form.Control>
    );
  }

  return (
    <Form.Control
      as="select"
      value={config[tab][label]}
      className={getClassNames()}
      onChange={(event: React.FormEvent) => { handleTextInput(tab, label, (event.target as HTMLInputElement).value); }}
    >
      {getSelectOptions()}
    </Form.Control>
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
  currentCustomer: state.currentCustomer,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(RadioFieldsSelector));
