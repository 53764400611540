import {
  SET_ALL_CONFIGS, RESET_ALL_CONFIGS, SET_WALKBY_CONFIG, SET_STATIONARY_CONFIG, AllConfigsActionTypes, CollapseState,
} from '../actionTypes';
import { PageAccordionState } from '../../components/TabContent/PageAccordion';

export const setAllConfigs = (configs: PageAccordionState): AllConfigsActionTypes => ({
  type: SET_ALL_CONFIGS,
  payload: configs,
});

export const resetAllConfigs = (collapseState: CollapseState): AllConfigsActionTypes => ({
  type: RESET_ALL_CONFIGS,
  payload: collapseState,
});

export const setWalkByConfig = (collapseState: CollapseState): AllConfigsActionTypes => ({
  type: SET_WALKBY_CONFIG,
  payload: collapseState,
});

export const setStationaryConfig = (collapseState: CollapseState): AllConfigsActionTypes => ({
  type: SET_STATIONARY_CONFIG,
  payload: collapseState,
});
