/* eslint-disable no-mixed-operators */
/**
 * Calculate the battery lifetime for Sontex electronic water meter device.
 * This function calculates with the worst case values were the maximum currents for the single operations are used.
 * Information is based on the "Calculation_autononmie.xlsx"
 * @param {number[]} batterySizes - Array of battery sizes with their max. current in mAh
 * @param {number} telegramsPerHour - Average number of telegrams that is sent per year
 * @param {string} encryptionMode - wM-Bus/Sontex encryption mode (off, mode_5, mode_7)
 * @param {string} radioMode - wM-Bus mode (t1/c1)
 * @param {string} radioFrame - wM-Bus frame (short/long)
 * @returns {number[]} Lifetime in years
 */
const calcBatteryLifetime = (
  batterySizes = [900, 1200, 2400],
  telegramsPerHour,
  encryptionMode,
  radioMode,
  radioFrame,
) => batterySizes.map((batSize) => {
  if (!telegramsPerHour) return undefined;
  const annualBaseCurrent = 50.04; // mAh

  let msgBaseCurrent;
  // eslint-disable-next-line default-case
  switch (true) {
    case radioFrame === 'short' && radioMode === 'c1' && encryptionMode === 'off':
      msgBaseCurrent = 241.40;
      break;
    case radioFrame === 'short' && radioMode === 'c1' && encryptionMode === 'mode_5':
      msgBaseCurrent = 263.80;
      break;
    case radioFrame === 'short' && radioMode === 'c1' && encryptionMode === 'mode_7':
      msgBaseCurrent = 378.42;
      break;
    case radioFrame === 'short' && radioMode === 't1' && encryptionMode === 'off':
      msgBaseCurrent = 363.99;
      break;
    case radioFrame === 'short' && radioMode === 't1' && encryptionMode === 'mode_5':
      msgBaseCurrent = 383.42;
      break;
    case radioFrame === 'short' && radioMode === 't1' && encryptionMode === 'mode_7':
      msgBaseCurrent = 644.41;
      break;
    case radioFrame === 'long' && radioMode === 'c1' && encryptionMode === 'off':
      msgBaseCurrent = 533.28;
      break;
    case radioFrame === 'long' && radioMode === 'c1' && encryptionMode === 'mode_5':
      msgBaseCurrent = 562.90;
      break;
    case radioFrame === 'long' && radioMode === 'c1' && encryptionMode === 'mode_7':
      msgBaseCurrent = 700.23;
      break;
    case radioFrame === 'long' && radioMode === 't1' && encryptionMode === 'off':
      msgBaseCurrent = 793.14;
      break;
    case radioFrame === 'long' && radioMode === 't1' && encryptionMode === 'mode_5':
      msgBaseCurrent = 836.61;
      break;
    case radioFrame === 'long' && radioMode === 't1' && encryptionMode === 'mode_7':
      msgBaseCurrent = 1010.58;
      break;
  }

  // in mAh/year
  const annualMsgCurrent = (msgBaseCurrent * telegramsPerHour / 3600) * 24 * 365.25 / 1000;
  const annualTotalCurrent = annualBaseCurrent + annualMsgCurrent;

  const sleepModeOneYear = 35; // mAh

  // console.log(batSize, telegramsPerHour, radioFrame, radioMode, encryptionMode, msgBaseCurrent, (batSize - sleepModeOneYear) / annualTotalCurrent);
  const lifetimeInYears = (batSize - sleepModeOneYear) / annualTotalCurrent;
  const maxLifetimeByBatterySize = batSize === 900 ? 15 : 16;

  return lifetimeInYears > maxLifetimeByBatterySize ? maxLifetimeByBatterySize : lifetimeInYears;
});

export default calcBatteryLifetime;
