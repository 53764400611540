import React, { ChangeEvent, useState } from 'react';
import {
  Col, Form, InputGroup, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IErrorAlertValues, ModelType } from '../../../config/models';
import {
  exitStorageValues, volumeUntilErrorActiveValues, radioFrequencyValues, flowCutOffValues,
  lorawanRejoinIntervalValues, rfTransmissionIntervalSF7Values, rfTransmissionIntervalSF8Values,
  rfTransmissionIntervalSF9Values, rfTransmissionIntervalSF10Values,
  rfTransmissionIntervalSF11Values, rfTransmissionIntervalSF12Values,
} from '../../../config/options';
import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';

interface NumberInputProps extends LocalizeContextProps {
  label: string;
  tab: ModelType;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
}

function NumberInput(props: NumberInputProps) {
  const {
    label, config, tab, translate, saveAllConfigs,
  } = props;

  const getFreeValues = () => {
    switch (label) {
      case 'exit_storage_volume':
        return exitStorageValues;
      case 'volume_until_error_active':
        return volumeUntilErrorActiveValues;
      case 'radio_frequency':
        return radioFrequencyValues;
      case 'flow_cut_off':
        return flowCutOffValues;
      case 'lorawan_rejoin_interval':
        return lorawanRejoinIntervalValues;
      case 'rf_transmission_interval_sf7':
        return rfTransmissionIntervalSF7Values;
      case 'rf_transmission_interval_sf8':
        return rfTransmissionIntervalSF8Values;
      case 'rf_transmission_interval_sf9':
        return rfTransmissionIntervalSF9Values;
      case 'rf_transmission_interval_sf10':
        return rfTransmissionIntervalSF10Values;
      case 'rf_transmission_interval_sf11':
        return rfTransmissionIntervalSF11Values;
      case 'rf_transmission_interval_sf12':
        return rfTransmissionIntervalSF12Values;
      default:
        return {
          description: '', limits: [], unit: '', type: '', step: 0,
        };
    }
  };

  const [values] = useState<IErrorAlertValues>(getFreeValues());
  const {
    limits, step, unit, description,
  } = values;

  const handleInput = (event: ChangeEvent) => {
    const value = Number((event?.target as HTMLInputElement)?.value);
    let valid = true;

    // (value * 10000) % (step * 10000) => because module of float doesn't work
    if (Number.isNaN(value) || value < limits[0] || value > limits[1] || (value * 10000) % (step * 10000) !== 0) {
      valid = false;
    }

    saveAllConfigs({
      ...config,
      currentConfig: '',
      invalidValueFields: {
        ...config.invalidValueFields,
        [label]: valid ? '' : tab,
      },
      [tab]: {
        ...config[tab],
        [label]: value,
      },
    });
  };

  return (
    <InputGroup className={label.includes('rf_transmission') ? 'append-animate' : ''}>
      <Col xs={7}>
        <Form.Label>{translate(description)}</Form.Label>
      </Col>
      <OverlayTrigger
        key={`${tab}${label}`}
        placement="bottom"
        trigger={['hover']}
        delay={{ show: 500, hide: 0 }}
        overlay={(
          <Tooltip id={`tooltip-${label}`}>
            <Translate id="units.limit-description" data={{ min: limits[0], max: limits[1] }} />
            {' '}
            <Translate id={`${unit}.long`} />
          </Tooltip>
        )}
      >
        <Col xs={5} className="d-flex">
          <Form.Control
            className={`${config.invalidValueFields && config.invalidValueFields[label] === tab ? 'invalid-input' : ''}`}
            type="number"
            value={config[tab][label]}
            min={limits[0]}
            step={step}
            max={limits[1]}
            required
            onChange={handleInput}
          />
          <InputGroup.Append>
            <InputGroup.Text>{translate(`${unit}.short`)}</InputGroup.Text>
          </InputGroup.Append>
        </Col>
      </OverlayTrigger>
    </InputGroup>
  );
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(NumberInput));
