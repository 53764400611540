import {
  EWZ2BatterOption,
  IBackflow,
  IErrorAlertValues, ILeakage, ILeakageEwz2, ILoraFrameOption, IPipeBreak, IQmax, IZeroFlow, LCDSequence,
} from './models';

export const knownLangs = [
  'de',
  'en',
];

export const lcdSequenceOptions = [
  'unknown',
];

export const ewz2BatteryOptions: EWZ2BatterOption[] = [
  {
    label: 'small',
    size: 1400,
    current: 900,
    disabled: true,
  },
  {
    label: 'big',
    size: 2400,
    current: 1200,
    disabled: false,
  },
  {
    label: 'double',
    size: 4800,
    current: 2400,
    disabled: false,
  },
];

export enum RadioProtocol {
  OMS_868 = 'oms-868',
  SONTEX_433 = 'sontex-433',
  LORA_868 = 'lora-868',
}

export const radioProtocols = [
  'please-choose',
  RadioProtocol.OMS_868,
  RadioProtocol.SONTEX_433,
  RadioProtocol.LORA_868,
];

export const encryptionModes = [
  'off',
  'mode_5',
  'mode_7',
];

export const encryptionModesSontex = [
  'off',
  'on',
];

export const radioModes = [
  't1',
  'c1',
];

export const radioFrames = [
  'short',
  'long',
];

export const getBillingDateRadioDays = () => {
  const radioDays = [];
  for (let index = 1; index <= 127; index += 1) {
    radioDays.push(index);
  }
  return (radioDays);
};

export const getMonthlyRadioDays = () => {
  const radioDays = [];
  for (let index = 1; index <= 31; index += 1) {
    radioDays.push(index);
  }
  return (radioDays);
};

export const MonthlyRadioDaysEWZ2 = {
  oneToFour: true,
  fiveToEight: true,
  nineToTwelve: true,
  thirteenToSixteen: true,
  seventeenToTwenty: true,
  twentyOneToTwentyFour: true,
  twentyFiveToTwentyEight: true,
  twentyNineToEnd: true,
};

export const RadioHoursEWZ2 = {
  0: false,
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: true,
  7: true,
  8: true,
  9: true,
  10: true,
  11: true,
  12: true,
  13: true,
  14: true,
  15: true,
  16: true,
  17: true,
  18: true,
  19: false,
  20: false,
  21: false,
  22: false,
  23: false,
};

export const getRadioHours = (type?: string) => {
  const hours = RadioHoursEWZ2;
  switch (type) {
    case 'walkBy':
      return {
        ...hours,
        6: false,
        18: false,
      };
    case 'stationary': {
      const filteredHours = { ...Object.keys(hours).reduce((reduced, key) => ({ ...reduced, [key]: true }), {}) };
      return { ...hours, ...filteredHours };
    }
    case 'unused': {
      const filteredHours = { ...Object.keys(hours).reduce((reduced, key) => ({ ...reduced, [key]: false }), {}) };
      return { ...hours, ...filteredHours };
    }
    case 'sontex':
      return {
        ...hours,
        19: true,
      };
    default: return hours;
  }
};

export const allRadioMonthsOn = {
  january: true,
  february: true,
  march: true,
  april: true,
  mai: true,
  june: true,
  july: true,
  august: true,
  september: true,
  october: true,
  november: true,
  december: true,
};

export const radioDays = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
};

export const getRadioDays = (type?: string) => {
  switch (type) {
    case 'weekDaysOnly':
      return {
        ...radioDays,
        saturday: false,
        sunday: false,
      };
    default: return radioDays;
  }
};

export const radioFrequencies = [
  10,
  15,
  20,
  25,
  30,
  45,
  60,
  120,
  180,
  240,
  300,
  600,
  900,
  1200,
  1500,
  1800,
  3600,
];

export const radioFrequencyValues: IErrorAlertValues = {
  description: 'every',
  limits: [15, 3600],
  unit: 'units.seconds',
  type: 'duration',
  step: 1,
};

export const flowCutOffValues: IErrorAlertValues = {
  description: 'below',
  limits: [1, 20],
  unit: 'units.liter-per-hour',
  type: 'limit',
  step: 1,
};

export const billingDateMonths: { [key: string]: string } = {
  january: '31.',
  february: '28.',
  march: '31.',
  april: '30.',
  mai: '31.',
  june: '30.',
  july: '31.',
  august: '31.',
  september: '30.',
  october: '31.',
  november: '30.',
  december: '31.',
};

export const calibrationPeriods = [
  0,
  12,
  24,
  36,
  48,
  60,
  72,
  84,
  96,
  108,
  120,
  132,
  144,
  156,
  168,
  180,
  192,
  204,
  216,
  228,
  240,
];

export const pipeBreakPresets: IPipeBreak[] = [
  {
    limit: 400,
    duration: 1440,
  },
  {
    limit: 540,
    duration: 40,
  },
  {
    limit: 400,
    duration: 250,
  },
  {
    limit: 1000,
    duration: 250,
  },
  {
    limit: 2500,
    duration: 250,
  },
];

export const pipeBreakFieldValues: IErrorAlertValues[] = [
  {
    limits: [100, 60000],
    description: 'flow-rate-limit',
    unit: 'units.liter-per-hour',
    type: 'limit',
    step: 1,
  },
  {
    limits: [1, 1440],
    description: 'duration-of-transgression',
    unit: 'units.minutes',
    type: 'duration',
    step: 1,
  },
];

export const leakagePresets: ILeakage[] = [
  {
    min: 1,
    max: 399,
    duration: 1440,
  },
  {
    min: 4,
    max: 399,
    duration: 770,
  },
  {
    min: 4,
    max: 399,
    duration: 1440,
  },
];

export const leakageFieldValues: IErrorAlertValues[] = [
  {
    limits: [1, 900],
    description: 'flow-rate-min',
    unit: 'units.liter-per-hour',
    type: 'min',
    step: 1,
  },
  {
    limits: [1, 900],
    description: 'flow-rate-max',
    unit: 'units.liter-per-hour',
    type: 'max',
    step: 1,
  },
  {
    limits: [1, 1440],
    description: 'duration-of-transgression',
    unit: 'units.minutes',
    type: 'duration',
    step: 1,
  },
];

export const ewz2LeakagePresets: ILeakageEwz2[] = [
  {
    min: 1,
    max: 399,
    duration: 24,
    pipeBreakLcdActive: true,
  },
  {
    min: 4,
    max: 399,
    duration: 12,
    pipeBreakLcdActive: true,
  },
  {
    min: 4,
    max: 399,
    duration: 24,
    pipeBreakLcdActive: true,
  },
];

export const ewz2LeakageFieldValues: IErrorAlertValues[] = [
  {
    limits: [1, 900],
    description: 'flow-rate-leakage',
    unit: 'units.liter-per-hour',
    type: 'min',
    step: 1,
  },
  {
    limits: [1, 900],
    description: 'flow-rate-pipe-break',
    unit: 'units.liter-per-hour',
    type: 'max',
    step: 1,
  },
  {
    limits: [1, 120],
    description: 'duration-of-transgression',
    unit: 'units.hours',
    type: 'duration',
    step: 1,
  },
];

export const qmaxPresets: IQmax[] = [
  {
    limit: 3438,
    label: 'Q3=2,5',
  },
  {
    limit: 5500,
    label: 'Q3=4',
  },
];

export const qmaxFieldValues: IErrorAlertValues[] = [
  {
    limits: [1, 99999],
    description: 'flow-rate-limit',
    unit: 'units.liter-per-hour',
    type: 'limit',
    step: 1,
  },
];

export const refluxPresets: IBackflow[] = [
  {
    volume: 1,
    duration: 13,
    limit: 9,
  },
  {
    volume: 1,
    duration: 13,
    limit: 7,
  },
  {
    volume: 1,
    duration: 13,
    limit: 13,
  },
  {
    volume: 2,
    duration: 13,
    limit: 1,
  },
  {
    volume: 1,
    duration: 13,
    limit: 18,
  },
];

export const refluxFieldValues: IErrorAlertValues[] = [
  {
    limits: [1, 7500],
    description: 'reflux-volume',
    unit: 'units.liter',
    type: 'volume',
    step: 1,
  },
  {
    limits: [13, 120],
    description: 'duration-of-transgression',
    unit: 'units.seconds',
    type: 'duration',
    step: 1,
  },
  {
    limits: [1, 30],
    description: 'flow-rate-limit',
    unit: 'units.liter-per-hour',
    type: 'limit',
    step: 1,
  },
];

export const ewz2RefluxPresets: IBackflow[] = [
  {
    duration: 15,
    limit: 9,
  },
  {
    duration: 15,
    limit: 1,
  },
  {
    duration: 15,
    limit: 7,
  },
  {
    duration: 15,
    limit: 13,
  },
  {
    duration: 15,
    limit: 18,
  },
];

export const ewz2RefluxFieldValues: IErrorAlertValues[] = [
  {
    limits: [5, 120],
    description: 'duration-of-transgression',
    unit: 'units.seconds',
    type: 'duration',
    step: 5,
  },
  {
    limits: [1, 300],
    description: 'flow-rate-limit',
    unit: 'units.liter-per-hour',
    type: 'limit',
    step: 1,
  },
];

export const zeroFlowPresets: IZeroFlow[] = [
  {
    duration: 21,
  },
];

export const zeroFlowFieldValues: IErrorAlertValues[] = [
  {
    limits: [1, 365],
    description: 'duration-of-transgression',
    unit: 'units.days',
    type: 'duration',
    step: 1,
  },
];

export const lcdSequencePresets: LCDSequence[] = [
  {
    active: true,
    lcdActive: true,
    allOff: 1,
    allOn: 1,
    errors: 2,
    firmwareVersion: 1,
    totalizer: 10,
    volumeAtDueDate: 3,
  },
];

export const lcdSequenceValues: IErrorAlertValues[] = [
  {
    limits: [1, 255],
    description: 'lcd-all-off',
    unit: 'units.seconds',
    type: 'allOff',
    step: 1,
  },
  {
    limits: [1, 10],
    description: 'lcd-all-on',
    unit: 'units.seconds',
    type: 'allOn',
    step: 1,
  },
  {
    limits: [1, 10],
    description: 'display-errors',
    unit: 'units.seconds',
    type: 'errors',
    step: 1,
  },
  {
    limits: [1, 10],
    description: 'firmware-version',
    unit: 'units.seconds',
    type: 'firmwareVersion',
    step: 1,
  },
  {
    limits: [10, 60],
    description: 'display-totalizer-flow',
    unit: 'units.seconds',
    type: 'totalizer',
    step: 1,
  },
  {
    limits: [1, 10],
    description: 'volume-at-due-date',
    unit: 'units.seconds',
    type: 'volumeAtDueDate',
    step: 1,
  },
];

export const exitStorageValues: IErrorAlertValues = {
  description: 'units.from',
  limits: [3, 30],
  unit: 'units.liter',
  type: 'duration',
  step: 1,
};

export const volumeUntilErrorActiveValues: IErrorAlertValues = {
  description: 'units.from',
  limits: [0, 255],
  unit: 'units.liter',
  type: 'duration',
  step: 1,
};

export const loraSettings = [
  'lorawan_individual_appkey', 'lorawan_appkey', 'rf_transmission_interval_sf7', 'rf_transmission_interval_sf8',
  'rf_transmission_interval_sf9', 'rf_transmission_interval_sf10', 'rf_transmission_interval_sf11',
  'rf_transmission_interval_sf12', 'lorawan_join_eui', 'lorawan_rejoin_interval', 'lorawan_confirm_mode',
  'lorawan_private_network_mode', 'lorawan_automatic_rtc_synchronization', 'lorawan_short_frame_config',
  'lorawan_long_frame_config', 'timezone',
];

export const loraConfirmModeOptions = [
  'never',
  'always',
  'daily',
  'twice-daily',
];

export const loraNetworkModeOptions = [
  'public',
  'private',
];

export const rfTransmissionIntervalSF7Values: IErrorAlertValues = {
  description: 'units.every',
  limits: [15, 480],
  unit: 'units.minutes',
  type: 'duration',
  step: 1,
};

export const rfTransmissionIntervalSF8Values: IErrorAlertValues = {
  description: 'units.every',
  limits: [15, 480],
  unit: 'units.minutes',
  type: 'duration',
  step: 1,
};

export const rfTransmissionIntervalSF9Values: IErrorAlertValues = {
  description: 'units.every',
  limits: [15, 480],
  unit: 'units.minutes',
  type: 'duration',
  step: 1,
};

export const rfTransmissionIntervalSF10Values: IErrorAlertValues = {
  description: 'units.every',
  limits: [15, 480],
  unit: 'units.minutes',
  type: 'duration',
  step: 1,
};

export const rfTransmissionIntervalSF11Values: IErrorAlertValues = {
  description: 'units.every',
  limits: [15, 480],
  unit: 'units.minutes',
  type: 'duration',
  step: 1,
};

export const rfTransmissionIntervalSF12Values: IErrorAlertValues = {
  description: 'units.every',
  limits: [15, 480],
  unit: 'units.minutes',
  type: 'duration',
  step: 1,
};

export const lorawanRejoinIntervalValues: IErrorAlertValues = {
  description: 'units.every',
  limits: [5, 255],
  unit: 'units.days',
  type: 'duration',
  step: 1,
};

export const loraFrameOptions: ILoraFrameOption[] = [
  { value: 'volume-totalizer', bytes: 6, sontexId: '0101' },
  { value: 'set-day', bytes: 4, sontexId: '0102' },
  { value: 'volume-totalizer-at-set-day', bytes: 6, sontexId: '0103' },
  { value: 'date-stored-at-month-15', bytes: 5, sontexId: '0104' },
  { value: 'volume-stored-at-month-15', bytes: 7, sontexId: '0105' },
  { value: 'volume-compact-profile', bytes: 63, sontexId: '0106' },
  { value: 'fabrication-number', bytes: 6, sontexId: '0107' },
  { value: 'current-date-time', bytes: 6, sontexId: '0108' },
  { value: 'device-use-duration', bytes: 6, sontexId: '0109' },
  { value: 'commissioning-date', bytes: 5, sontexId: '010A' },
  { value: 'internal-firmware', bytes: 6, sontexId: '010D' },
  { value: 'device-reset-counter', bytes: 5, sontexId: '0113' },
  { value: 'detailed-error-flags', bytes: 5, sontexId: '0114' },
  { value: 'volume-stored-at-hour-1', bytes: 7, sontexId: '0115' },
  { value: 'volume-stored-at-hour-2', bytes: 7, sontexId: '0116' },
  { value: 'volume-stored-at-hour-3', bytes: 7, sontexId: '0117' },
  { value: 'volume-stored-at-hour-4', bytes: 7, sontexId: '0118' },

  { value: 'timestamp-of-first-fraud', bytes: 7, sontexId: '0201' },
  { value: 'volume-at-fraud', bytes: 7, sontexId: '0202' },
  { value: 'backflow-volume', bytes: 7, sontexId: '0208' },

  { value: 'manufacturing-date', bytes: 5, sontexId: '0308' },
  { value: 'flowmeter-model', bytes: 5, sontexId: '0309' },
  { value: 'define-battery-type', bytes: 4, sontexId: '030B' },

  { value: 'lorawan-parameters-set', bytes: 5, sontexId: '0503' },
  { value: 'lorawan-status', bytes: 6, sontexId: '050E' },
  { value: 'manufacturer-id', bytes: 5, sontexId: '050F' },
  { value: 'device-type', bytes: 4, sontexId: '0510' },
  { value: 'version-of-meter', bytes: 4, sontexId: '0511' },
  { value: 'status', bytes: 5, sontexId: '0512' },
];
