import { SET_CURRENT_PAGE, CurrentPageActionTypes, CurrentPageState } from '../actionTypes';

const initialState: CurrentPageState = '/';

const currentPageReducer = (
  state: CurrentPageState = initialState,
  action: CurrentPageActionTypes,
): CurrentPageState => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return action.payload;

    default:
      return state;
  }
};

export default currentPageReducer;
