/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryFull, faBatteryHalf } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faClock, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { addDays } from '../../../helpers';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';
import WarningComponent from './WarningComponent';
import { ModelType } from '../../../config/models';
import CustomTooltip from '../../CustomTooltip';

interface EndDateComponentProps {
  tab: ModelType;
  config: PageAccordionState;
}

function EndDateComponent(props: EndDateComponentProps) {
  const { tab, config } = props;
  const [batteryLifetime, setBatterLifetime] = useState<number>(0);
  const [invalidBatteryLifetime, setInvalidBatteryLifetime] = useState<string>('');
  const values = config[tab];

  const getRadioEndDate = (
    residualBudget: number,
    budgetPerRadioTelegram: number,
    radioInterval: number,
    sumRadioHoursPerDay: number,
    sumRadioMonth: number,
    sumRadioWeek: number,
    radioDaysPerMonth: number,
    radioDueDaysPerMonth: number,
    shouldValidate: boolean,
    maxBatteryLifetimeInYears: number,
  ) => {
    const referenceDate = new Date();
    const averageDaysPerYear = 365.25;
    const averageDaysPerMonth = averageDaysPerYear / 12;
    const averageWeeksPerMonth = averageDaysPerMonth / 7;
    const averageRadioTelegramsPerHour = (60 * 60) / radioInterval;
    const possibleRadioONDaysPerMonth = averageWeeksPerMonth * sumRadioWeek;
    let averageRadioONDaysPerYear = 0;

    if (radioDueDaysPerMonth > radioDaysPerMonth) {
      if (possibleRadioONDaysPerMonth > radioDueDaysPerMonth) {
        averageRadioONDaysPerYear += radioDueDaysPerMonth;
      } else {
        averageRadioONDaysPerYear += possibleRadioONDaysPerMonth;
      }

      if (possibleRadioONDaysPerMonth > radioDaysPerMonth) {
        averageRadioONDaysPerYear += radioDaysPerMonth * (sumRadioMonth - 1);
      } else {
        averageRadioONDaysPerYear += possibleRadioONDaysPerMonth * (sumRadioMonth - 1);
      }
    } else if (possibleRadioONDaysPerMonth > radioDaysPerMonth) {
      averageRadioONDaysPerYear += radioDaysPerMonth * sumRadioMonth;
    } else {
      averageRadioONDaysPerYear += possibleRadioONDaysPerMonth * sumRadioMonth;
    }
    const radioTelegramsPerDay = sumRadioHoursPerDay * averageRadioTelegramsPerHour;
    const radioTelegramsPerYear = radioTelegramsPerDay * averageRadioONDaysPerYear;
    const averageEnergyConsumptionPerDay = (radioTelegramsPerYear * budgetPerRadioTelegram) / averageDaysPerYear;
    let radioDaysLeft = (residualBudget / averageEnergyConsumptionPerDay);
    const yearsLeft = radioDaysLeft / averageDaysPerYear;
    let radioEndDate = addDays(referenceDate, radioDaysLeft);

    const roundedYearsLeft = Math.round(yearsLeft * 10) / 10;
    let maxYearsLeft = roundedYearsLeft;

    if (roundedYearsLeft > maxBatteryLifetimeInYears) {
      maxYearsLeft = maxBatteryLifetimeInYears;
      radioDaysLeft = maxYearsLeft * averageDaysPerYear;
      radioEndDate = addDays(referenceDate, radioDaysLeft);
    }

    if (shouldValidate && (batteryLifetime !== maxYearsLeft)) {
      setBatterLifetime(maxYearsLeft);
    }

    return {
      radioEndDate,
      radioDaysLeft: Math.round(radioDaysLeft),
      yearsLeft: maxYearsLeft,
      radioTelegramsPerYear,
    };
  };

  useEffect(() => {
    if (tab === 'fam' && batteryLifetime < 7) {
      setInvalidBatteryLifetime('fam');
    } else if (tab === 'ewz' && values.small_battery && batteryLifetime < 7) {
      setInvalidBatteryLifetime('ewz');
    } else if (tab === 'ewz' && !values.small_battery && batteryLifetime < 7) {
      setInvalidBatteryLifetime('ewz');
    } else {
      setInvalidBatteryLifetime('');
    }
  }, [batteryLifetime]);

  const getTelegramsPerDay = (radioHours: number, radioFrequency: number) => {
    try {
      const telegramPerDays = (radioHours * 60 * 60) / radioFrequency;
      return Math.round(telegramPerDays);
    } catch (e) {
      console.error('Trying to calculate telegrams per day:', e);
      return 0;
    }
  };

  const getEndDateComponent = () => {
    try {
      const dateOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      };
      let budgetPerRadioTelegram;
      const radioFrequency = values.radio_frequency;
      const radioHours = Object.keys(values.radio_hours).filter((hour) => values.radio_hours[Number(hour)]).length;
      let radioMonths = 0;
      for (const month in values.radio_months) {
        if (values.radio_months[month]) {
          radioMonths += 1;
        }
      }
      let radioWeekdays = 0;
      for (const day in values.radio_days) {
        if (values.radio_days[day]) {
          radioWeekdays += 1;
        }
      }
      const radioDaysPerMonth = values.monthly_radio_days;
      const radioDueDaysPerMonth = values.billing_date_radio_days;
      switch (tab) {
        case 'ewz': {
          switch (`${values.radio_mode}${values.radio_frame}`) {
            case 't1short':
              budgetPerRadioTelegram = 38;
              break;
            case 'c1short':
              budgetPerRadioTelegram = 25;
              break;
            case 'c1long':
              budgetPerRadioTelegram = 50;
              break;
            default:
              budgetPerRadioTelegram = 80;
              break;
          }
          const {
            radioEndDate: radioEndDateSmall,
            radioDaysLeft: radioDaysLeftSmall,
            yearsLeft: yearsLeftSmall,
            radioTelegramsPerYear,
          } = getRadioEndDate(
            71542466,
            budgetPerRadioTelegram,
            radioFrequency,
            radioHours,
            radioMonths,
            radioWeekdays,
            radioDaysPerMonth,
            radioDueDaysPerMonth,
            values.small_battery,
            7,
          );
          const {
            radioEndDate: radioEndDateBig,
            radioDaysLeft: radioDaysLeftBig,
            yearsLeft: yearsLeftBig,
          } = getRadioEndDate(
            143084909,
            budgetPerRadioTelegram,
            radioFrequency,
            radioHours,
            radioMonths,
            radioWeekdays,
            radioDaysPerMonth,
            radioDueDaysPerMonth,
            !values.small_battery,
            16,
          );
          return (
            <>
              <Row>
                <Col xs={4}>
                  <FontAwesomeIcon icon={faEnvelope} />
                  {' '}
                  <Translate id="radio-telegrams-per-day" />
                </Col>
                <Col xs={2}>
                  {getTelegramsPerDay(radioHours, radioFrequency)}
                </Col>
                <Col xs={3}>
                  <FontAwesomeIcon icon={faEnvelope} />
                  {' '}
                  <Translate id="per-year" />
                </Col>
                <Col xs={3}>
                  {Math.round(radioTelegramsPerYear)}
                </Col>
              </Row>
              <CustomTooltip id="end-date-component" label="estimated-calculation" placement="bottom">
                <CustomTooltip
                  id="maximum-battery-lifetime-reached-ewz-small"
                  label="maximum-battery-lifetime-reached"
                  data={{ maxBatteryLifetime: 7 }}
                  placement="top"
                  show={values.small_battery && yearsLeftSmall >= 7}
                >
                  <Row className={!values.small_battery ? 'end-date-big my-2' : 'my-2'}>
                    <Col xs={4}>
                      <FontAwesomeIcon icon={faBatteryHalf} />
                      {' '}
                      <Translate id="end-date-small" />
                    </Col>
                    <Col xs={2}>
                      {radioEndDateSmall.toLocaleDateString('de-DE', dateOptions as Intl.DateTimeFormatOptions)}
                    </Col>
                    <Col xs={3}>
                      <FontAwesomeIcon icon={faClock} />
                      {' '}
                      <Translate id="end-date-period" />
                    </Col>
                    <Col xs={3}>
                      {radioDaysLeftSmall}
                      {' '}
                      <Translate id="units.days.long" />
                      {' '}
                      ≈
                      {' '}
                      <b>
                        {yearsLeftSmall}
                        {' '}
                        <Translate id="units.years.long" />
                      </b>
                      {' '}
                      <WarningComponent
                        tab={tab}
                        minBatteryLifetime={7}
                        show={invalidBatteryLifetime === tab && values.small_battery}
                      />
                    </Col>
                  </Row>
                </CustomTooltip>
                <CustomTooltip
                  id="maximum-battery-lifetime-reached-ewz-big"
                  label="maximum-battery-lifetime-reached"
                  data={{ maxBatteryLifetime: 16 }}
                  placement="top"
                  show={!values.small_battery && yearsLeftBig >= 16}
                >
                  <Row className={values.small_battery ? 'end-date-big' : ''}>
                    <Col xs={4}>
                      <FontAwesomeIcon icon={faBatteryFull} />
                      {' '}
                      <Translate id="end-date-big" />
                    </Col>
                    <Col xs={2}>
                      {radioEndDateBig.toLocaleDateString('de-DE', dateOptions as Intl.DateTimeFormatOptions)}
                    </Col>
                    <Col xs={3}>
                      <FontAwesomeIcon icon={faClock} />
                      {' '}
                      <Translate id="end-date-period" />
                    </Col>
                    <Col xs={3}>
                      {radioDaysLeftBig}
                      {' '}
                      <Translate id="units.days.long" />
                      {' '}
                      ≈
                      {' '}
                      <b>
                        {yearsLeftBig}
                        {' '}
                        <Translate id="units.years.long" />
                      </b>
                      {' '}
                      <WarningComponent
                        tab={tab}
                        minBatteryLifetime={7}
                        show={invalidBatteryLifetime === tab && !values.small_battery}
                      />
                    </Col>
                  </Row>
                </CustomTooltip>
              </CustomTooltip>
            </>
          );
        }
        case 'fam': {
          switch (`${values.radio_mode}${values.radio_frame}`) {
            case 't1short':
              budgetPerRadioTelegram = 42;
              break;
            case 'c1short':
              budgetPerRadioTelegram = 29;
              break;
            case 'c1long':
              budgetPerRadioTelegram = 57;
              break;
            default:
              budgetPerRadioTelegram = 75;
              break;
          }
          const {
            radioEndDate: radioEndDateFam,
            radioDaysLeft: radioDaysLeftFam,
            yearsLeft: yearsLeftFam,
            radioTelegramsPerYear: radioTelegramsPerYearFam,
          } = getRadioEndDate(
            156040920,
            budgetPerRadioTelegram,
            radioFrequency,
            radioHours,
            radioMonths,
            radioWeekdays,
            radioDaysPerMonth,
            radioDueDaysPerMonth,
            true,
            15,
          );
          return (
            <>
              <Row>
                <Col xs={3}>
                  <FontAwesomeIcon icon={faEnvelope} />
                  {' '}
                  <Translate id="radio-telegrams-per-day" />
                </Col>
                <Col xs={3}>
                  {getTelegramsPerDay(radioHours, radioFrequency)}
                </Col>
                <Col xs={3}>
                  <FontAwesomeIcon icon={faEnvelope} />
                  {' '}
                  <Translate id="per-year" />
                </Col>
                <Col xs={2}>
                  {Math.round(radioTelegramsPerYearFam)}
                </Col>
              </Row>
              <CustomTooltip id="end-date-component" label="estimated-calculation" placement="bottom">
                <CustomTooltip
                  id="maximum-battery-lifetime-reached-ewz-big"
                  label="maximum-battery-lifetime-reached"
                  data={{ maxBatteryLifetime: 15 }}
                  placement="top"
                  show={yearsLeftFam >= 15}
                >
                  <Row className="mt-2">
                    <Col>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                      {' '}
                      <Translate id="end-date" />
                    </Col>
                    <Col>
                      {radioEndDateFam.toLocaleDateString('de-DE', dateOptions as Intl.DateTimeFormatOptions)}
                    </Col>
                    <Col>
                      <FontAwesomeIcon icon={faClock} />
                      {' '}
                      <Translate id="end-date-period" />
                    </Col>
                    <Col>
                      {radioDaysLeftFam}
                      {' '}
                      <Translate id="units.days.long" />
                      {' '}
                      ≈
                      {' '}
                      <b>
                        {yearsLeftFam}
                        {' '}
                        <Translate id="units.years.long" />
                      </b>
                      {' '}
                      <WarningComponent
                        tab={tab}
                        minBatteryLifetime={7}
                        show={invalidBatteryLifetime === tab}
                      />
                    </Col>
                  </Row>
                </CustomTooltip>
              </CustomTooltip>
            </>
          );
        }
        default:
          return null;
      }
    } catch (e) {
      console.error('Trying to calculate battery lifetime:', e);
      return null;
    }
  };

  return (
    <>
      {getEndDateComponent()}
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EndDateComponent);
