import React, { useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';

import {
  resetAllConfigs, setAllConfigs, setStationaryConfig, setWalkByConfig,
} from '../../state/actions/allConfigsAction';
import { initialState, stationaryState, walkByState } from '../../config/deviceConfigs';
import { CollapseState, DispatchActionTypes } from '../../state/actionTypes';
import { AppState } from '../../state/reducers';
import { PageAccordionState } from './PageAccordion';
import { objectEquals } from '../../helpers';
import { RadioProtocol } from '../../config/options';
import { ModelType } from '../../config/models';

interface DefaultConfigButtonsProps extends LocalizeContextProps {
  tab: ModelType;
  currentConfig: string;
  generalCollapsed: boolean;
  wirelessCollapsed: boolean;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
  saveResetAllConfigs: (collapseState: CollapseState) => void;
  saveWalkByConfig: (collapseState: CollapseState) => void;
  saveStationaryConfig: (collapseState: CollapseState) => void;
}

const DefaultConfigButtons = (props: DefaultConfigButtonsProps) => {
  const {
    tab,
    config,
    currentConfig,
    saveAllConfigs,
    saveResetAllConfigs,
    saveWalkByConfig,
    saveStationaryConfig,
    wirelessCollapsed,
    generalCollapsed,
    translate,
  } = props;

  const handleResetButton = (event: React.MouseEvent) => {
    event.stopPropagation();
    toastr.success((translate('success').toString()), (translate('factory-settings-loaded').toString()));
    saveResetAllConfigs({ generalCollapsed, wirelessCollapsed, radio_protocol: config[tab].radio_protocol });
  };

  const handleWalkByButton = (event: React.MouseEvent) => {
    event.stopPropagation();
    toastr.success((translate('success').toString()), (translate('walk-by-settings-loaded').toString()));
    saveWalkByConfig({ generalCollapsed, wirelessCollapsed, radio_protocol: config[tab].radio_protocol });
  };

  const handleStationaryButton = (event: React.MouseEvent) => {
    event.stopPropagation();
    toastr.success((translate('success').toString()), (translate('stationary-settings-loaded').toString()));
    saveStationaryConfig({ generalCollapsed, wirelessCollapsed, radio_protocol: config[tab].radio_protocol });
  };

  useEffect(() => {
    if (config.currentConfig === '') {
      const newConfig = {
        ewz: config.ewz,
        ewz2: config.ewz2,
        fam: config.fam,
      };
      const defaultConfig = {
        ewz: initialState.ewz,
        ewz2: initialState.ewz2,
        fam: initialState.fam,
      };
      if (objectEquals(defaultConfig, newConfig)) {
        saveAllConfigs({
          ...config,
          currentConfig: 'default',
        });
      }
      const defaultStationaryConfig = {
        ewz: stationaryState.ewz,
        ewz2: stationaryState.ewz2,
        fam: stationaryState.fam,
      };
      if (objectEquals(defaultStationaryConfig, newConfig)) {
        saveAllConfigs({
          ...config,
          currentConfig: 'stationary',
        });
      }
      const defaultWalkByConfig = {
        ewz: walkByState.ewz,
        ewz2: walkByState.ewz2,
        fam: walkByState.fam,
      };
      if (objectEquals(defaultWalkByConfig, newConfig)) {
        saveAllConfigs({
          ...config,
          currentConfig: 'walkby',
        });
      }
    }
  }, [config]);

  const showButton = () => {
    if (tab !== 'ewz2' || (tab === 'ewz2' && config[tab].radio_protocol === RadioProtocol.OMS_868)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <OverlayTrigger
        key={`${tab}-reset`}
        placement="right"
        trigger={['hover']}
        overlay={(
          <Tooltip id={`tooltip-${tab}-reset`}>
            <Translate id="reset" />
          </Tooltip>
        )}
      >
        <Button
          className="reset-button active-config-button"
          variant={currentConfig === 'default' ? 'primary' : 'outline-dark'}
          onClick={(e: React.MouseEvent) => { handleResetButton(e); }}
        >
          <Translate id="factory-settings" />
        </Button>
      </OverlayTrigger>
      {showButton() && (
        <OverlayTrigger
          key={`${tab}-walkby-button`}
          placement="right"
          trigger={['hover']}
          overlay={(
            <Tooltip id="tooltip-walkby-button">
              <Translate id="walk-by-description" />
            </Tooltip>
          )}
        >
          <Button
            className="walkby-button active-config-button"
            variant={currentConfig === 'walkby' ? 'primary' : 'outline-dark'}
            onClick={(e: React.MouseEvent) => { handleWalkByButton(e); }}
          >
            <Translate id="walk-by" />
            {' '}
          </Button>
        </OverlayTrigger>
      )}
      {showButton() && (
        <OverlayTrigger
          key={`${tab}-stationary-button`}
          placement="right"
          trigger={['hover']}
          overlay={(
            <Tooltip id="tooltip-stationary-button">
              <Translate id="stationary-description" />
            </Tooltip>
          )}
        >
          <Button
            className="stationary-button active-config-button"
            variant={currentConfig === 'stationary' ? 'primary' : 'outline-dark'}
            onClick={(e: React.MouseEvent) => { handleStationaryButton(e); }}
          >
            <Translate id="stationary" />
            {' '}
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
  saveResetAllConfigs: (collapseState: CollapseState) => dispatch(resetAllConfigs(collapseState)),
  saveWalkByConfig: (collapseState: CollapseState) => dispatch(setWalkByConfig(collapseState)),
  saveStationaryConfig: (collapseState: CollapseState) => dispatch(setStationaryConfig(collapseState)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(DefaultConfigButtons));
