/* eslint-disable @typescript-eslint/naming-convention */
import {
  SET_ALL_CONFIGS, RESET_ALL_CONFIGS, SET_WALKBY_CONFIG, SET_STATIONARY_CONFIG, AllConfigsActionTypes,
} from '../actionTypes';
import { PageAccordionState } from '../../components/TabContent/PageAccordion';
import {
  initialState, walkByState, stationaryState, sontexStateAddition, loraStateAddition, loraDisabledStateAddition,
} from '../../config/deviceConfigs';
import { RadioProtocol } from '../../config/options';

const allConfigsReducer = (
  state: PageAccordionState = initialState,
  action: AllConfigsActionTypes,
): PageAccordionState => {
  switch (action.type) {
    case SET_ALL_CONFIGS:
      return action.payload;
    case RESET_ALL_CONFIGS: {
      const { generalCollapsed, wirelessCollapsed, radio_protocol } = action.payload;
      const newState = { ...initialState };
      if (radio_protocol === RadioProtocol.OMS_868) {
        newState.ewz2 = {
          ...newState.ewz2,
          radio_protocol: RadioProtocol.OMS_868,
          ...loraDisabledStateAddition,
        };
      } else if (radio_protocol === RadioProtocol.SONTEX_433) {
        newState.ewz2 = {
          ...newState.ewz2,
          radio_protocol: RadioProtocol.SONTEX_433,
          ...sontexStateAddition,
          ...loraDisabledStateAddition,
        };
      } else if (radio_protocol === RadioProtocol.LORA_868) {
        newState.ewz2 = {
          ...newState.ewz2,
          radio_protocol: RadioProtocol.LORA_868,
          ...loraStateAddition,
        };
      }
      return {
        ...newState, generalCollapsed, wirelessCollapsed,
      };
    }
    case SET_WALKBY_CONFIG: {
      const { generalCollapsed, wirelessCollapsed, radio_protocol } = action.payload;
      const newState = { ...walkByState };
      if (radio_protocol === RadioProtocol.OMS_868) {
        newState.ewz2 = {
          ...newState.ewz2,
          radio_protocol: RadioProtocol.OMS_868,
          ...loraDisabledStateAddition,
        };
      }
      return {
        ...newState, generalCollapsed, wirelessCollapsed,
      };
    }
    case SET_STATIONARY_CONFIG: {
      const { generalCollapsed, wirelessCollapsed, radio_protocol } = action.payload;
      const newState = { ...stationaryState };
      if (radio_protocol === RadioProtocol.OMS_868) {
        newState.ewz2 = {
          ...newState.ewz2,
          radio_protocol: RadioProtocol.OMS_868,
          ...loraDisabledStateAddition,
        };
      }
      return {
        ...newState, generalCollapsed, wirelessCollapsed,
      };
    }
    default:
      return state;
  }
};

export default allConfigsReducer;
