import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import currentCustomerReducer from './currentCustomerReducer';
import currentPageReducer from './currentPageReducer';
import currentTabReducer from './currentTabReducer';
import isExpertReducer from './isExpertReducer';
import allConfigsReducer from './allConfigsReducer';

const rootReducer = combineReducers({
  allConfigs: allConfigsReducer,
  currentCustomer: currentCustomerReducer,
  currentPage: currentPageReducer,
  currentTab: currentTabReducer,
  isExpert: isExpertReducer,
  toastr: toastrReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
