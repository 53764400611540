import React, { useEffect, useState } from 'react';
import { Modal as BsModal, Button } from 'react-bootstrap';
import { Translate, withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faTimes, faListOl,
} from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';

import ReactDOM from 'react-dom';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { AppState } from '../../../state/reducers';
import DualList from './DualList';
import { ewz2loraPayloads } from '../../../config/deviceConfigs';
import { PageAccordionState } from '../PageAccordion';
import { ILoraFrameOption, ModelType } from '../../../config/models';

interface ModalProps extends LocalizeContextProps {
  showModal: boolean;
  config: PageAccordionState;
  tab: ModelType;
  label: 'lorawan_short_frame_config' | 'lorawan_long_frame_config';
  handleClose: () => void;
  saveAllConfigs: (config: PageAccordionState) => void;
}
const ConfigureFrameModal = (props: ModalProps) => {
  const {
    config, label, tab,
    translate, saveAllConfigs,
    showModal, handleClose,
  } = props;

  const { maxBytes } = ewz2loraPayloads[label];

  const parseOptions = (options: ILoraFrameOption[]) => options.map((option: ILoraFrameOption) => ({
    ...option,
    label: `${translate(`frame_payload.${option.value}`)} (${option.bytes})`,
    bytes: option.bytes,
  }));

  const [selected, setSelected] = useState<ILoraFrameOption[]>(parseOptions(config[tab][label]));
  const [currentBytes, setCurrentBytes] = useState<number>(0);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    setIsValid(currentBytes <= maxBytes);
  }, [currentBytes]);

  useEffect(() => {
    if (showModal) setSelected(parseOptions(config[tab][label]));
  }, [showModal]);

  const onSaveButtonClick = () => {
    if (!isValid) {
      toastr.error((translate('invalid').toString()), (translate('frame-length-exceeded').toString()));
    } else if (currentBytes === 0) {
      toastr.error((translate('invalid').toString()), (translate('frame-length-is-null').toString()));
    } else {
      handleClose();
      const cleanedSelected = selected.map((s) => ({
        value: s.value,
        bytes: s.bytes,
        sontexId: s.sontexId,
      }));
      saveAllConfigs({
        ...config,
        currentConfig: '',
        [tab]: {
          ...config[tab],
          [label]: cleanedSelected,
        },
      });
      toastr.success((translate('success').toString()), (translate('frame-saved').toString()));
    }
  };

  return ReactDOM.createPortal(
    <BsModal show={showModal} onHide={handleClose} style={{ zIndex: 9999 }}>
      <BsModal.Header closeButton>
        <BsModal.Title>
          <FontAwesomeIcon icon={faListOl} />
          {' '}
          <Translate id={label} />
        </BsModal.Title>
      </BsModal.Header>
      <BsModal.Body>
        <DualList
          currentBytes={currentBytes}
          maxBytes={maxBytes}
          setCurrentBytes={setCurrentBytes}
          selected={selected}
          setSelected={setSelected}
          parseOptions={parseOptions}
        />
      </BsModal.Body>
      <BsModal.Footer>
        <Button variant="secondary" onClick={handleClose} className="ok-button">
          <FontAwesomeIcon icon={faTimes} />
          {' '}
          <Translate id="cancel" />
        </Button>
        <Button
          variant="primary"
          onClick={onSaveButtonClick}
          className="ok-button"
        >
          <FontAwesomeIcon icon={faCheck} />
          {' '}
          <Translate id="save" />
        </Button>
      </BsModal.Footer>
    </BsModal>,
    document.getElementById('root')!,
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ConfigureFrameModal));
