import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

import { DispatchActionTypes } from '../state/actionTypes';
import setCurrentCustomer from '../state/actions/currentCustomerAction';
import setCurrentPage from '../state/actions/currentPageAction';
import Header, { Headers } from './Header/Header';
import Start from '../pages/Start/Start';
import ConfigurePage from '../pages/Configure/ConfigurePage';
import About from '../pages/About/About';
import setCurrentTab from '../state/actions/currentTabAction';
import { ModelType } from '../config/models';

interface AppProps extends LocalizeContextProps {
  saveCurrentPage: (pageTitle: string) => void;
  saveCurrentCustomer: (customer: string) => void;
  saveCurrentTab: (tab: ModelType) => void;
}

const headers: Headers[] = [
  { link: '/', label: 'start', icon: 'faHome' },
  { link: '/configure', label: 'configure', icon: 'faCog' },
  { link: '/about', label: 'about', icon: 'faInfo' },
];

const App = (props: AppProps) => {
  const {
    saveCurrentPage, saveCurrentTab, saveCurrentCustomer, translate,
  } = props;
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight,
  });

  useEffect(() => {
    const currentCustomer = window.location.host.split('.')[0];
    if (currentCustomer.includes('sontex')) {
      const root = document.documentElement;
      root?.style?.setProperty('--primary', '#d7881d');
      root?.style?.setProperty('--primary-dark', '#e69146');
      root?.style?.setProperty('--primary-darker', '#b06827');
      saveCurrentTab('ewz2');
      saveCurrentCustomer('sontex');
    }

    const currentPage = window.location.pathname.split('/')[1] || '';
    saveCurrentPage(`/${currentPage}`);
  }, [saveCurrentPage]);

  const updateSize = () => setSize({
    x: window.innerWidth,
    y: window.innerHeight,
  });

  // eslint-disable-next-line no-return-assign
  useEffect(() => (window.onresize = updateSize), []);

  useEffect(() => {
    if (size.x < 830) {
      // @ts-ignore `position` is not part of BasicToastrOptions
      toastr.error(translate('warning').toString(), translate('device-too-small').toString(), { timeOut: 0, position: 'top-left' });
    }
    return () => {
      toastr.removeByType('error');
    };
  }, [size, translate]);

  return (
    <Router>
      <Header headers={headers} />
      <Switch>
        <Route path="/configure" exact>
          <ConfigurePage />
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/">
          <Start />
        </Route>
      </Switch>
    </Router>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveCurrentPage: (pageTitle: string) => dispatch(setCurrentPage(pageTitle)),
  saveCurrentCustomer: (customer: string) => dispatch(setCurrentCustomer(customer)),
  saveCurrentTab: (tab: ModelType) => dispatch(setCurrentTab(tab)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withLocalize(App));
