import { ewz2loraPayloads } from './deviceConfigs';

export const ewz10 = { // ewz0d4bb46d57
  type: 'ewz',
  flow_indicator: { active: true, lcdActive: true, lcdOnly: true },
  calibration_period: { active: false, duration: 72 },
  pipe_break: { active: true, limit: 400, duration: 1440 },
  leakage: {
    active: true, min: 1, max: 399, duration: 1440,
  },
  qmax_error: { active: true, limit: 3438, label: 'Q3=2,5' },
  reflux_error: {
    active: true, volume: 1, duration: 13, limit: 9,
  },
  billing_date_indicator: { active: true, lcdActive: true, lcdOnly: true },
  billing_date: 'december',
  timezone: { name: 'Europe/Berlin', offset: 1 },
  password: '0000',
  small_battery: false,
  billing_date_radio_days: 30,
  monthly_radio_days: 31,
  radio_days: {
    monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true,
  },
  radio_months: {
    january: true, february: true, march: true, april: true, mai: true, june: true, july: true, august: true, september: true, october: true, november: true, december: true,
  },
  radio_hours: {
    0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: true, 7: true, 8: true, 9: true, 10: true, 11: true, 12: true, 13: true, 14: true, 15: true, 16: true, 17: true, 18: true, 19: false, 20: false, 21: false, 22: false, 23: false,
  },
  radio_frequency: 120,
  radio_mode: 'c1',
  radio_frame: 'short',
  individual_aes_key: false,
  aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
};

export const ewz20 = { // ewz234e844f30d
  type: 'ewz2',
  lcd_sequence: {
    active: true, lcdActive: true, allOff: 1, allOn: 1, errors: 1, firmwareVersion: 1, totalizer: 10, volumeAtDueDate: 3,
  },
  calibration_period: { active: false, lcdActive: false, duration: 72 },
  flow_indicator: { active: true, lcdActive: true, lcdOnly: true },
  leakage: {
    active: true, lcdActive: true, min: 1, max: 399, duration: 24, pipeBreakLcdActive: true,
  },
  qmax_error: { active: true, lcdActive: true, lcdOnly: true },
  reflux_error: {
    active: true, lcdActive: true, duration: 15, limit: 9,
  },
  zero_flow_error: { active: true, lcdActive: true, duration: 21 },
  flow_cut_off: 1,
  battery_low: { active: true, lcdActive: true },
  system_error: { active: true, lcdActive: true, lcdOnly: true },
  manipulation: { active: true, lcdActive: true, lcdOnly: true },
  radio_error: { active: true, lcdActive: true, lcdOnly: true },
  billing_date_indicator: { active: true, lcdActive: true, lcdOnly: true },
  billing_date: 'december',
  timezone: { name: 'Europe/Berlin', offset: 1 },
  password: '0000',
  battery_size: 'big',
  exit_storage_volume: 5,
  volume_until_error_active: 0,
  radio_protocol: 'oms-868',
  radio_days: {
    monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true,
  },
  radio_months: {
    january: true, february: true, march: true, april: true, mai: true, june: true, july: true, august: true, september: true, october: true, november: true, december: true,
  },
  radio_days_of_week_active: true,
  radio_days_of_month: {
    oneToFour: true, fiveToEight: true, nineToTwelve: true, thirteenToSixteen: true, seventeenToTwenty: true, twentyOneToTwentyFour: true, twentyFiveToTwentyEight: true, twentyNineToEnd: true,
  },
  radio_hours: {
    0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: true, 7: true, 8: true, 9: true, 10: true, 11: true, 12: true, 13: true, 14: true, 15: true, 16: true, 17: true, 18: true, 19: false, 20: false, 21: false, 22: false, 23: false,
  },
  radio_frequency: 120,
  radio_mode: 'c1',
  radio_frame: 'short',
  encryption_mode: 'mode_5',
  individual_aes_key: false,
  aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
  lorawan_individual_appkey: false,
  lorawan_appkey: '00000000000000000000000000000000',
  rf_transmission_interval_sf7: 60,
  rf_transmission_interval_sf8: 60,
  rf_transmission_interval_sf9: 120,
  rf_transmission_interval_sf10: 120,
  rf_transmission_interval_sf11: 240,
  rf_transmission_interval_sf12: 360,
  lorawan_join_eui: '0000000000000000',
  lorawan_rejoin_interval: 30,
  lorawan_confirm_mode: 'never',
  lorawan_private_network_mode: 'public',
  lorawan_automatic_rtc_synchronization: false,
  lorawan_short_frame_config: ewz2loraPayloads.lorawan_short_frame_config.defaultFrame,
  lorawan_long_frame_config: ewz2loraPayloads.lorawan_long_frame_config.defaultFrame,
};

export const fam10 = { // fameceb7a989b
  type: 'fam',
  pipe_break: { active: true, limit: 400, duration: 1440 },
  leakage: {
    active: true, min: 1, max: 399, duration: 1440,
  },
  qmax_error: { active: true, limit: 3438, label: 'Q3=2,5' },
  reflux_error: {
    active: true, volume: 1, duration: 13, limit: 9,
  },
  null_volume_on_montage: false,
  billing_date: 'december',
  timezone: { name: 'Europe/Berlin', offset: 1 },
  password: '0000',
  set_wm_sn: false,
  billing_date_radio_days: 30,
  monthly_radio_days: 31,
  radio_days: {
    monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true,
  },
  radio_months: {
    january: true, february: true, march: true, april: true, mai: true, june: true, july: true, august: true, september: true, october: true, november: true, december: true,
  },
  radio_hours: {
    0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: true, 7: true, 8: true, 9: true, 10: true, 11: true, 12: true, 13: true, 14: true, 15: true, 16: true, 17: true, 18: true, 19: false, 20: false, 21: false, 22: false, 23: false,
  },
  radio_frequency: 120,
  radio_mode: 'c1',
  radio_frame: 'short',
  individual_aes_key: false,
  aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
};
