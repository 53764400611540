import { SET_CURRENT_TAB, CurrentTabActionTypes, CurrentTabState } from '../actionTypes';

const initialState: CurrentTabState = 'ewz';

const currentTabReducer = (
  state: CurrentTabState = initialState,
  action: CurrentTabActionTypes,
): CurrentTabState => {
  switch (action.type) {
    case SET_CURRENT_TAB:
      return action.payload;

    default:
      return state;
  }
};

export default currentTabReducer;
