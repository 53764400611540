import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';

import RadioCalendarModal from './RadioCalendarModal';

interface RadioCalendarRowProps extends LocalizeContextProps {
  label: string;
  showRadioDays: boolean;
}

const RadioCalendarRow = (props: RadioCalendarRowProps) => {
  const { label, showRadioDays, translate } = props;
  const [showModal, setShowModal] = useState(false);

  const toggleShowModal = () => {
    toastr.warning((translate('warning').toString()), (translate('do-not-change-radio-calendar').toString()));
    setShowModal(!showModal);
  };

  return (
    <>
      <OverlayTrigger
        key={`button-${label}`}
        placement="top"
        trigger={['hover']}
        overlay={(
          <Tooltip id={`tooltip-button-${label}`}>
            <Translate id="edit" />
          </Tooltip>
        )}
      >
        <Button
          className="generate-random-button"
          variant="primary"
          onClick={toggleShowModal}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
          {' '}
        </Button>
      </OverlayTrigger>
      <RadioCalendarModal
        showModal={showModal}
        handleClose={toggleShowModal}
        label={label}
        showRadioDays={showRadioDays}
      />
    </>
  );
};

export default withLocalize(RadioCalendarRow);
