import React from 'react';
import * as htmlToImage from 'html-to-image';

import { jsPDF } from 'jspdf';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  LocalizeContextProps, Translate, withLocalize,
} from 'react-localize-redux';
import { PageAccordionState } from '../TabContent/PageAccordion';
import { ModelType } from '../../config/models';
import { AppState } from '../../state/reducers';
import { DispatchActionTypes } from '../../state/actionTypes';
import { setAllConfigs } from '../../state/actions/allConfigsAction';

interface PrintButtonProps extends LocalizeContextProps {
  tab: ModelType;
  configCode: string;
  customer: string;
  company: string;
  project: string;
}

const PrintButton = (props: PrintButtonProps) => {
  const {
    tab, configCode, customer, translate, company, project,
  } = props;

  function getDimensions(element: HTMLElement) {
    const elementRect = element.getBoundingClientRect();

    const pageWidth = 400;
    const pageHeight = 566;

    const elementAspectRatio = elementRect.width / elementRect.height;
    const pageAspectRatio = pageWidth / pageHeight;

    let newWidth; let newHeight;

    if (elementAspectRatio > pageAspectRatio) {
      newWidth = pageWidth;
      newHeight = pageWidth / elementAspectRatio;
    } else {
      newHeight = pageHeight;
      newWidth = pageHeight * elementAspectRatio;
    }

    return { newWidth, newHeight };
  }

  function loadImage(src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  function getFirstVisibleElement(elements: HTMLCollectionOf<Element>): Element {
    if (elements.length === 1) return elements[0];
    if (tab === 'ewz') return elements[0];
    if (tab === 'ewz2') return elements[1];
    return elements[2];
  }

  function addPageLabel(resultPDF: jsPDF, appLabel: string, deviceModel: string) {
    resultPDF.setFontSize(13);
    resultPDF.text(appLabel, 25, 40);
    resultPDF.setFontSize(10);
    resultPDF.text(deviceModel, 105, 40);
    resultPDF.setFontSize(20);
    resultPDF.text(configCode, 312, 40);
  }

  function addPageFooter(resultPDF: jsPDF) {
    resultPDF.setFontSize(10);
    const currentDate = new Date().toLocaleDateString('de-DE');
    resultPDF.text(currentDate, 25, 620);
    resultPDF.text(company, 70, 620);
    resultPDF.text(project, 250, 620);
  }

  function removeElement(elementId: string) {
    const styleElement = document.getElementById(elementId);
    if (styleElement) {
      document.head.removeChild(styleElement);
    }
  }

  const handleButtonClick = async () => {
    const backdropStyle = document.createElement('style');
    backdropStyle.id = 'print-backdrop-style';
    backdropStyle.innerHTML = '.modal-backdrop.show { opacity: 1; } #generate-pdf-button { display: none; }';
    document.head.appendChild(backdropStyle);

    const style = document.createElement('style');
    style.id = 'print-PDF-style';
    style.innerHTML += '.collapse:not(.show) { display: block !important; } #root { max-width: 1100px !important; } .append-animate { transform-origin: unset !important; animation: unset !important; }';
    style.innerHTML += '.radioHours-selector input[type="checkbox"] + label, .weekDays-selector input[type="checkbox"] + label, .monthlyDays-selector input[type="checkbox"] + label, .values-selector input[type="checkbox"] + label, .month-selector input[type="checkbox"] + label, .battery-selector input[type="checkbox"] + label { box-shadow: unset; }';
    style.innerHTML += '.radioHours-selector input[type="checkbox"]:checked + label, .weekDays-selector input[type="checkbox"]:checked + label, .monthlyDays-selector input[type="checkbox"]:checked + label, .values-selector input[type="checkbox"]:checked + label, .month-selector input[type="checkbox"]:checked + label, .battery-selector input[type="checkbox"]:checked + label { box-shadow: unset; }';
    style.innerHTML += '.accordion .form-control { box-shadow: unset; }';
    style.innerHTML += '#config-footer-buttons { display: none; }';
    document.head.appendChild(style);

    const generalSettings = getFirstVisibleElement(document.getElementsByClassName('config-card-1')) as HTMLElement;
    const wirelessSettings = getFirstVisibleElement(document.getElementsByClassName('config-card-2')) as HTMLElement;
    if (!generalSettings || !wirelessSettings) {
      return;
    }
    // eslint-disable-next-line new-cap
    const resultPDF = new jsPDF({ unit: 'px' });
    const appLabel = translate('product_configurator').toString();
    const deviceModel = (customer !== 'sontex' ? translate(`devices.${tab}.full`) : translate('devices.superaqua.full')).toString();
    addPageLabel(resultPDF, appLabel, deviceModel);
    addPageFooter(resultPDF);

    const { newWidth: generalSettingsWidth, newHeight: generalSettingsHeight } = getDimensions(generalSettings);
    const { newWidth: wirelessSettingsWidth, newHeight: wirelessSettingsHeight } = getDimensions(wirelessSettings);

    const generalSettingsImageSrc = await htmlToImage.toPng(generalSettings);
    const wirelessSettingsImageSrc = await htmlToImage.toPng(wirelessSettings);

    try {
      const [generalSettingsImage, wirelessSettingsImage] = await Promise.all([
        loadImage(generalSettingsImageSrc),
        loadImage(wirelessSettingsImageSrc),
      ]) as HTMLImageElement[];

      resultPDF.addImage(generalSettingsImage, 'PNG', 25, 50, generalSettingsWidth, generalSettingsHeight);

      if (generalSettingsHeight + wirelessSettingsHeight <= 570) {
        resultPDF.addImage(wirelessSettingsImage, 'PNG', 25, 50 + generalSettingsHeight, wirelessSettingsWidth, wirelessSettingsHeight);
      } else {
        resultPDF.addPage();

        addPageLabel(resultPDF, appLabel, deviceModel);
        addPageFooter(resultPDF);
        resultPDF.addImage(wirelessSettingsImage, 'PNG', 25, 50, wirelessSettingsWidth, wirelessSettingsHeight);
      }

      resultPDF.save(`${translate('product_configurator').toString()}-${translate('config-code').toString()}-${configCode}.pdf`);

      removeElement('print-PDF-style');
      removeElement('print-backdrop-style');
    } catch (error) {
      console.error('Error loading images:', error);
    }
  };

  return (
    <div className="my-3">
      <OverlayTrigger
        key="expert-settings-button"
        placement="top"
        trigger={['hover']}
        overlay={(
          <Tooltip id="tooltip-expert-settings-button">
            <Translate id="save-to-pdf" />
          </Tooltip>
        )}
      >
        <Button
          className="ok-button"
          onClick={handleButtonClick}
          variant="secondary"
          id="generate-pdf-button"
        >
          <FontAwesomeIcon icon={faFileAlt} />
          {' '}
          <Translate id="generate-config-pdf" />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  customer: state.currentCustomer,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(PrintButton));
