/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */

// eslint-disable-next-line import/prefer-default-export

import { ILoraFrameOption } from './config/models';
import { getRadioHours, loraFrameOptions, RadioProtocol } from './config/options';
import { ewz10, ewz20, fam10 } from './config/factory-configs';
import { ewz2loraPayloads } from './config/deviceConfigs';

export const EWZ_1_0_REGEX = new RegExp(/^ewz[a-z0-9]{10}$/);
export const FAM_1_0_REGEX = new RegExp(/^fam[a-z0-9]{10}$/);
export const EWZ_2_0_REGEX = new RegExp(/^ewz2[a-z0-9]{10}$/);

const timezones = require('./timezones.json');

export function objectEquals(obj1: any, obj2: any) {
  const JSONStringifyOrder = (obj: any) => {
    const allKeys: string[] = [];
    JSON.stringify(obj, (key, value) => {
      allKeys.push(key);
      return value;
    });
    allKeys.sort();
    return JSON.stringify(obj, allKeys);
  };
  return JSONStringifyOrder(obj1) === JSONStringifyOrder(obj2);
}

export const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const convertToSql = (sourceJson: any) => {
  const resultingJson = sourceJson.map((device: any) => {
    const newDevice = JSON.parse(JSON.stringify(device));
    const hashCode = Object.keys(newDevice)[0];

    Object.keys(newDevice[hashCode]).forEach((param) => {
      if (param === 'lcd_sequence') {
        delete newDevice[hashCode].lcd_sequence.active;
        delete newDevice[hashCode].lcd_sequence.lcdActive;
      } else if (param === 'encryption_mode' && newDevice[hashCode].encryption_mode === 'on') {
        newDevice[hashCode].encryption_mode = 'mode_5';
      } else if (param === 'flow_indicator'
        || param === 'qmax_error'
        || param === 'system_error'
        || param === 'manipulation'
        || param === 'radio_error'
        || param === 'billing_date_indicator') {
        delete newDevice[hashCode][param].active;
        delete newDevice[hashCode][param].lcdOnly;
      } else if (param === 'lorawan_short_frame_config'
        || param === 'lorawan_long_frame_config') {
        newDevice[hashCode][param] = {};
        loraFrameOptions.forEach((frameOption: ILoraFrameOption) => {
          newDevice[hashCode][param][frameOption.value] = !!device[hashCode][param]
            .find((option: ILoraFrameOption) => option.value === frameOption.value);
        });
      }
    });
    return {
      id: hashCode,
      info:
        newDevice[hashCode],
    };
  });
  return JSON.stringify(resultingJson);
};

const doConfigsDiffer = (defaultConfig: any, newConfig: any): string => {
  let isDifferent = '';
  Object.keys(defaultConfig).forEach((key) => {
    if (!newConfig.hasOwnProperty(key)) isDifferent = key;
    if (typeof defaultConfig[key] !== typeof newConfig[key]) isDifferent += ` typeof: ${key}`;
    if (typeof defaultConfig[key] === 'object') {
      Object.keys(defaultConfig[key]).forEach((subKey) => {
        if (typeof newConfig[key] === 'object' && !newConfig[key]?.hasOwnProperty(subKey)) isDifferent += ` subKey: ${key}`;
        if (!newConfig[key]?.hasOwnProperty(subKey) || typeof defaultConfig[key][subKey] !== typeof newConfig[key][subKey]) {
          isDifferent += ` subKey typeof: ${key}.${subKey}`;
        }
      });
    }
  });

  return isDifferent;
};

interface TimeZone {
  name: string;
  offset: string;
}

export const migrateToNewFormat = (sourceJson: any) => {
  const convertHours = (start: number, end: number) => {
    const unusedRadioHours = getRadioHours('unused');
    let convertedHours = {};
    Object.keys(unusedRadioHours).forEach((hour: string) => {
      const hourN = Number(hour);
      if (hourN >= start && hourN < end) convertedHours = { ...convertedHours, [hour]: true };
      else convertedHours = { ...convertedHours, [hour]: false };
    });
    return convertedHours;
  };

  const resultingJson = sourceJson.map((json: any) => {
    const hashCode = Object.keys(json)[0];
    const config = json[hashCode];

    if (config.type === 'ewz' && (
      typeof config.billing_date_indicator === 'boolean'
            || typeof config.flow_indicator === 'boolean'
            || typeof config.timezone === 'string'
            || !config.hasOwnProperty('calibration_period')
            || !config.hasOwnProperty('leakage')
            || !config.hasOwnProperty('pipe_break')
            || !config.hasOwnProperty('qmax_error')
            || !config.hasOwnProperty('radio_frame')
            || !config.hasOwnProperty('radio_mode')
            || !config.hasOwnProperty('reflux_error')
            || !config.radio_hours.hasOwnProperty('0')
            || config.radio_months.hasOwnProperty('juni')
            || config.hasOwnProperty('radio_protocol')
            || config.hasOwnProperty('smallBattery')
    )) {
      let flow_indicator;
      if (config.flow_indicator) {
        flow_indicator = {
          active: true,
          lcdActive: true,
          lcdOnly: true,
        };
      } else {
        flow_indicator = {
          active: false,
          lcdActive: false,
          lcdOnly: true,
        };
      }

      let billing_date_indicator;
      if (config.billing_date_indicator) {
        billing_date_indicator = {
          active: true,
          lcdActive: true,
          lcdOnly: true,
        };
      } else {
        billing_date_indicator = {
          active: false,
          lcdActive: false,
          lcdOnly: true,
        };
      }

      let timezone;
      timezones.forEach((tz: TimeZone) => {
        if (tz.name === config.timezone) timezone = { name: tz.name, offset: tz.offset };
      });

      const radio_months = {
        ...config.radio_months,
        june: config.radio_months.juni,
      };
      delete radio_months.juni;

      const radio_frequency = Number(config.radio_frequency.slice(0, -1));
      const radio_hours = convertHours(config.radio_hours.begin, config.radio_hours.end);

      if (config.hasOwnProperty('radio_protocol')) {
        config.radio_mode = config.radio_protocol.slice(0, 2);
        config.radio_frame = config.radio_protocol.slice(2);
        delete config.radio_protocol;
      }

      const small_battery = config.smallBattery || false;
      delete config.smallBattery;

      const resultingConfig = {
        ...config,
        flow_indicator,
        billing_date_indicator,
        timezone,
        radio_months,
        radio_hours,
        radio_frequency,
        calibration_period: {
          active: false,
          duration: 72,
        },
        pipe_break: {
          active: true,
          limit: 400,
          duration: 1440,
        },
        leakage: {
          active: true,
          min: 1,
          max: 399,
          duration: 1440,
        },
        qmax_error: {
          active: true,
          limit: 3438,
          label: 'Q3=2,5',
        },
        reflux_error: {
          active: true,
          volume: 1,
          duration: 13,
          limit: 9,
        },
        small_battery,
      };

      const invalidFields = doConfigsDiffer(ewz10, resultingConfig);
      if (invalidFields) console.info(invalidFields);

      return {
        [hashCode]: resultingConfig,
      };
    }
    if (config.type === 'fam' && (
      typeof config.timezone === 'string'
            || typeof config.leakage === 'boolean'
            || typeof config.pipe_break === 'boolean'
            || typeof config.qmax_error === 'boolean'
            || typeof config.reflux_error === 'boolean'
            || !config.hasOwnProperty('radio_frame')
            || !config.hasOwnProperty('radio_mode')
            || !config.radio_hours.hasOwnProperty('0')
            || config.radio_months.hasOwnProperty('juni')
            || config.hasOwnProperty('radio_protocol')
    )) {
      let pipe_break;
      if (config.pipe_break) {
        pipe_break = {
          active: true,
          limit: 400,
          duration: 1440,
        };
      } else {
        pipe_break = {
          active: false,
          limit: 400,
          duration: 1440,
        };
      }

      let leakage;
      if (config.leakage) {
        leakage = {
          active: true,
          min: 1,
          max: 399,
          duration: 1440,
        };
      } else {
        leakage = {
          active: false,
          min: 1,
          max: 399,
          duration: 1440,
        };
      }

      let qmax_error;
      if (config.qmax_error) {
        qmax_error = {
          active: true,
          limit: 3438,
          label: 'Q3=2,5',
        };
      } else {
        qmax_error = {
          active: false,
          limit: 3438,
          label: 'Q3=2,5',
        };
      }

      let reflux_error;
      if (config.reflux_error) {
        reflux_error = {
          active: true,
          volume: 1,
          duration: 13,
          limit: 9,
        };
      } else {
        reflux_error = {
          active: false,
          volume: 1,
          duration: 13,
          limit: 9,
        };
      }

      let timezone;
      timezones.forEach((tz: TimeZone) => {
        if (tz.name === config.timezone) timezone = { name: tz.name, offset: tz.offset };
      });

      const radio_months = {
        ...config.radio_months,
        june: config.radio_months.juni,
      };
      delete radio_months.juni;

      const radio_hours = convertHours(config.radio_hours.begin, config.radio_hours.end);

      const radio_frequency = Number(config.radio_frequency.slice(0, -1));

      if (config.hasOwnProperty('radio_protocol')) {
        config.radio_mode = config.radio_protocol.slice(0, 2);
        config.radio_frame = config.radio_protocol.slice(2);
        delete config.radio_protocol;
      }

      const resultingConfig = {
        ...config,
        pipe_break,
        leakage,
        qmax_error,
        reflux_error,
        timezone,
        radio_months,
        radio_hours,
        radio_frequency,
      };

      const invalidFields = doConfigsDiffer(fam10, resultingConfig);
      if (invalidFields) console.info(hashCode, invalidFields);

      return {
        [hashCode]: resultingConfig,
      };
    }
    if (config.type === 'ewz2' && (
      !config.hasOwnProperty('lorawan_individual_appkey')
        || !config.hasOwnProperty('lorawan_appkey')
        || !config.hasOwnProperty('rf_transmission_interval_sf7')
        || !config.hasOwnProperty('rf_transmission_interval_sf8')
        || !config.hasOwnProperty('rf_transmission_interval_sf9')
        || !config.hasOwnProperty('rf_transmission_interval_sf10')
        || !config.hasOwnProperty('rf_transmission_interval_sf11')
        || !config.hasOwnProperty('rf_transmission_interval_sf12')
        || !config.hasOwnProperty('lorawan_join_eui')
        || !config.hasOwnProperty('lorawan_rejoin_interval')
        || !config.hasOwnProperty('lorawan_confirm_mode')
        || !config.hasOwnProperty('lorawan_private_network_mode')
        || !config.hasOwnProperty('lorawan_automatic_rtc_synchronization')
        || !config.hasOwnProperty('lorawan_short_frame_config')
        || !config.hasOwnProperty('lorawan_long_frame_config')
    )) {
      if (!config.leakage.hasOwnProperty('pipeBreakLcdActive')) {
        config.leakage.pipeBreakLcdActive = true;
      }

      if (config.hasOwnProperty('pipe_break')) {
        delete config.pipe_break;
      }

      if (typeof config.timezone === 'string') {
        timezones.forEach((tz: TimeZone) => {
          if (tz.name === config.timezone) config.timezone = { name: tz.name, offset: tz.offset };
        });
      }

      const resultingConfig = {
        flow_cut_off: 1,
        lorawan_individual_appkey: false,
        lorawan_appkey: '00000000000000000000000000000000',
        rf_transmission_interval_sf7: 60,
        rf_transmission_interval_sf8: 60,
        rf_transmission_interval_sf9: 120,
        rf_transmission_interval_sf10: 120,
        rf_transmission_interval_sf11: 240,
        rf_transmission_interval_sf12: 360,
        lorawan_join_eui: '0000000000000000',
        lorawan_rejoin_interval: 30,
        lorawan_confirm_mode: 'never',
        lorawan_private_network_mode: 'public',
        lorawan_automatic_rtc_synchronization: false,
        lorawan_short_frame_config: ewz2loraPayloads.lorawan_short_frame_config.defaultFrame,
        lorawan_long_frame_config: ewz2loraPayloads.lorawan_long_frame_config.defaultFrame,
        ...config,
      };

      const invalidFields = doConfigsDiffer(ewz20, resultingConfig);
      if (invalidFields) console.info(hashCode, invalidFields);

      return {
        [hashCode]: resultingConfig,
      };
    }
    if (config.type === 'ewz2' && config.radio_protocol !== RadioProtocol.LORA_868) {
      const resultingConfig = {
        ...config,
        lorawan_individual_appkey: false,
        lorawan_appkey: '00000000000000000000000000000000',
        rf_transmission_interval_sf7: 60,
        rf_transmission_interval_sf8: 60,
        rf_transmission_interval_sf9: 120,
        rf_transmission_interval_sf10: 120,
        rf_transmission_interval_sf11: 240,
        rf_transmission_interval_sf12: 360,
        lorawan_join_eui: '0000000000000000',
        lorawan_rejoin_interval: 30,
        lorawan_confirm_mode: 'never',
        lorawan_private_network_mode: 'public',
        lorawan_automatic_rtc_synchronization: false,
        lorawan_short_frame_config: ewz2loraPayloads.lorawan_short_frame_config.defaultFrame,
        lorawan_long_frame_config: ewz2loraPayloads.lorawan_long_frame_config.defaultFrame,
      };
      return {
        [hashCode]: resultingConfig,
      };
    }

    return {
      [hashCode]: {
        ...config,
      },
    };
  });

  return JSON.stringify(resultingJson);
};
