import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Translate, withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';

import { DispatchActionTypes } from '../../state/actionTypes';
import setCurrentTab from '../../state/actions/currentTabAction';
import { setAllConfigs } from '../../state/actions/allConfigsAction';
import { AppState } from '../../state/reducers';
import { PageAccordionState } from '../TabContent/PageAccordion';
import { ModelType } from '../../config/models';

export enum ValidationState {
  INVALID = 'INVALID',
  UNVALIDATED = 'UNVALIDATED',
  VALID = 'VALID',
  VALID_ON_MIGRATION = 'VALID_ON_MIGRATION',
}

interface ConfigLoaderModalProps extends LocalizeContextProps {
  customer: string;
  showModal: boolean;
  config: PageAccordionState;
  handleSubmit: () => void;
  handleClose: () => void;
  saveAllConfigs: (config: PageAccordionState) => void;
  saveCurrentTab: (tab: ModelType) => void;
  isValidated: ValidationState;
  setIsValidated: (value: ValidationState) => void;
}

interface ConfigLoaderModalState {
  device: string;
}

class ConfigLoaderModal extends React.Component<ConfigLoaderModalProps, ConfigLoaderModalState> {
  constructor(props: ConfigLoaderModalProps) {
    super(props);

    this.state = {
      device: '',
    };
  }

  setLoadedSettingsString = (settings: string) => {
    const { setIsValidated, saveAllConfigs, config } = this.props;
    setIsValidated(ValidationState.UNVALIDATED);
    saveAllConfigs({
      ...config,
      loadedSettingsString: (settings.replace(/\s/g, '')),
    });
  };

  validateConfigFromServer = () => {
    const {
      handleSubmit, isValidated, setIsValidated, config,
      translate, saveAllConfigs, saveCurrentTab, customer,
    } = this.props;

    if (isValidated === ValidationState.INVALID || isValidated === ValidationState.UNVALIDATED) {
      if (customer === 'sontex' && !config.loadedSettingsString?.includes('ewz2')) {
        toastr.error((translate('receive-error').toString()), (translate('config-invalid').toString()));
      } else {
        try {
          axios.get(`/config?hash=${config.loadedSettingsString}`)
            .then((res) => {
              const loadedSettings = res.data[config.loadedSettingsString];
              if (loadedSettings && loadedSettings.type) {
                this.setState({
                  device: loadedSettings.type,
                }, () => {
                  setIsValidated(ValidationState.VALID);
                  saveCurrentTab(loadedSettings.type as ModelType);
                  saveAllConfigs({
                    ...config,
                    settingsToLoad: loadedSettings,
                  });
                  toastr.success((translate('config-valid').toString()), (translate('config-valid-text').toString()));
                });
              }
            })
            .catch((error) => {
              console.error(error);
              if (error.response?.status === 400) {
                toastr.error((translate('receive-error').toString()), (translate('hash-empty').toString()));
              } else {
                toastr.error((translate('receive-error').toString()), (translate('config-invalid').toString()));
              }
            });
        } catch (e) {
          setIsValidated(ValidationState.INVALID);
          console.error(e);
          toastr.error((translate('parsing-error').toString()), (translate('config-not-loaded').toString()));
        }
      }
    }
    if (isValidated === ValidationState.VALID || isValidated === ValidationState.VALID_ON_MIGRATION) {
      toastr.success((translate('config-loaded').toString()), (translate('config-loaded-text').toString()));
      handleSubmit();
    }
  };

  getValidatedComponent = () => {
    const { isValidated, customer } = this.props;
    const { device } = this.state;

    if (isValidated === ValidationState.VALID || isValidated === ValidationState.VALID_ON_MIGRATION) {
      return (
        <span className="modal-device">
          {customer !== 'sontex' ? <Translate id={`devices.${device}.full`} />
            : <Translate id="devices.superaqua.full" />}
          {' '}
          <FontAwesomeIcon className="text-success" icon={faCheck} />
        </span>
      );
    }

    if (isValidated === ValidationState.INVALID) {
      return (
        <span className="modal-device">
          <Translate id="incorrect-config" />
          {' '}
          <FontAwesomeIcon className="text-danger" icon={faTimes} />
        </span>
      );
    }

    return null;
  };

  render() {
    const {
      config: { loadedSettingsString },
      isValidated,
      translate,
      showModal,
      handleClose,
    } = this.props;

    return (
      <>
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FontAwesomeIcon icon={faFileUpload} />
              {' '}
              <Translate id="load-config" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              autoFocus
              className={`hash-input ${(isValidated === ValidationState.VALID || isValidated === ValidationState.VALID_ON_MIGRATION) && 'valid-input'} ${isValidated === ValidationState.INVALID && 'invalid-input'}`}
              value={loadedSettingsString}
              onChange={
                (event: React.FormEvent) => this.setLoadedSettingsString((event.target as HTMLInputElement).value)
              }
              placeholder={String(translate('enter-code-here'))}
            />
          </Modal.Body>
          <Modal.Footer>
            {this.getValidatedComponent()}
            <Button variant="secondary" onClick={handleClose} className="ok-button">
              <FontAwesomeIcon icon={faTimes} />
              {' '}
              <Translate id="cancel" />
            </Button>
            <Button variant="primary" onClick={() => this.validateConfigFromServer()} className="ok-button">
              <FontAwesomeIcon icon={faCheck} />
              {' '}
              <Translate id={isValidated === ValidationState.VALID || isValidated === ValidationState.VALID_ON_MIGRATION ? 'load' : 'test'} />
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  customer: state.currentCustomer,
  currentTab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveCurrentTab: (tab: ModelType) => dispatch(setCurrentTab(tab)),
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ConfigLoaderModal));
