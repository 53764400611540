/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React from 'react';
import {
  Accordion, Card, Col, Row, OverlayTrigger, Tooltip, Form,
} from 'react-bootstrap';
import { withLocalize, LocalizeContextProps, Translate } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight, faAngleDown, faSlidersH, faBroadcastTower,
} from '@fortawesome/free-solid-svg-icons';
import TimezonePicker, { timezones } from 'react-timezone';

import '../../scss/components/page-tabs.scss';
import inputFieldsByDeviceType from '../../config/inputFieldsByDeviceType.json';
import {
  DeviceSetting, FormDataEWZ, FormDataEWZ2, FormDataFAM, ITimezone, ModelType,
} from '../../config/models';
import { DispatchActionTypes } from '../../state/actionTypes';
import { setAllConfigs } from '../../state/actions/allConfigsAction';
import WeekdaysSelector from './RadioCalendar/WeekdaysSelector';
import BatterySelector from './InputFields/BatterySelector';
import MonthSelector from './RadioCalendar/MonthSelector';
import BatteryLifetime from './BatteryLifetime/BatteryLifetime';
import TextInput from './InputFields/TextInput';
import DefaultConfigButtons from './DefaultConfigButtons';
import RadioFieldsSelector from './InputFields/RadioFieldsSelector';
import FrameFieldsList from './ConfigureFrames/FrameFieldsList';
import BooleanSelector from './InputFields/BooleanSelector';
import ValuesSelector from './InputFields/ValuesSelector';
import { AppState } from '../../state/reducers';
import NumberInput from './InputFields/NumberInput';
import MonthlyDaysSelector from './RadioCalendar/MonthlyDaysSelector';
import RadioCalendarRow from './RadioCalendar/RadioCalendarRow';
import RadioHoursSelector from './RadioCalendar/RadioHoursSelector';
import {
  ewz2radioData, sontexStateAddition, ewz2loraData, loraStateAddition, loraDisabledStateAddition,
} from '../../config/deviceConfigs';
import { loraSettings, RadioProtocol } from '../../config/options';

export interface PageAccordionState {
  generalCollapsed: boolean;
  wirelessCollapsed: boolean;
  ewz: FormDataEWZ;
  ewz2: FormDataEWZ2;
  fam: FormDataFAM;
  loadedSettingsString: string;
  settingsToLoad: any; // FormDataEWZ | FormDataEWZ2 | FormDataFAM
  invalidValueFields: {
    [key: string]: any;
    leakage?: {
      min: string;
      max: string;
      duration: string;
    };
    pipe_break?: {
      limit: string;
      duration: string;
    };
    qmax_error?: {
      limit: string;
    };
    reflux_error?: {
      volume: string;
      duration: string;
      limit: string;
    };
    calibration_period?: string;
    exit_storage_volume?: string;
    volume_until_error_active?: string;
    radio_frequency?: string;
    invalidPassword?: string;
    invalidAesKey?: string;
    batteryLifetime?: string;
  }

  [key: string]: any;
}

interface PageAccordionProps extends LocalizeContextProps {
  tab: ModelType;
  isExpert: boolean;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
  currentCustomer: string;
}

class PageAccordion extends React.Component<PageAccordionProps, PageAccordionState> {
  componentDidMount() {
    const {
      config, tab, currentCustomer, saveAllConfigs,
    } = this.props;
    if (currentCustomer === 'sontex') {
      saveAllConfigs({
        ...config,
        [tab]: {
          ...config[tab],
          flow_cut_off: 5,
          lcd_sequence: {
            ...config[tab].lcd_sequence,
            errors: 1,
          },
        },
      });
    }
  }

  toggleCollapsed = (accordion: string) => {
    const { saveAllConfigs, config } = this.props;
    if (accordion === 'general') {
      saveAllConfigs({
        ...config,
        generalCollapsed: !config.generalCollapsed,
        wirelessCollapsed: true,
      });
    }
    if (accordion === 'wireless') {
      saveAllConfigs({
        ...config,
        wirelessCollapsed: !config.wirelessCollapsed,
        generalCollapsed: true,
      });
    }
  };

  handleTextInput = (tab: ModelType, label: string, input: string) => {
    const { config, saveAllConfigs } = this.props;
    let timezone;
    if (label === 'radio_protocol') {
      switch (input) {
        case RadioProtocol.OMS_868:
          return saveAllConfigs({
            ...config,
            [tab]: {
              ...config[tab],
              [label]: input,
              ...ewz2radioData,
              ...loraDisabledStateAddition,
            },
          });
        case RadioProtocol.SONTEX_433:
          return saveAllConfigs({
            ...config,
            [tab]: {
              ...config[tab],
              [label]: input,
              ...sontexStateAddition,
              ...loraDisabledStateAddition,
            },
          });
        case RadioProtocol.LORA_868:
          return saveAllConfigs({
            ...config,
            [tab]: {
              ...config[tab],
              [label]: input,
              ...ewz2loraData,
              ...loraStateAddition,
            },
          });
        default:
          return saveAllConfigs({
            ...config,
            [tab]: { ...config[tab], [label]: input },
          });
      }
    }
    if (label === 'timezone') {
      timezones.forEach((tz: ITimezone) => {
        if (tz.name === input) timezone = { name: tz.name, offset: tz.offset };
      });
    }
    if (label === 'encryption_mode') {
      return saveAllConfigs({
        ...config,
        invalidValueFields: {
          ...config.invalidValueFields,
          invalidAesKey: '',
        },
        currentConfig: '',
        [tab]: {
          ...config[tab],
          encryption_mode: input,
          aes_key: config[tab].radio_protocol === RadioProtocol.SONTEX_433 ? '00000000000000000000000000000000'
            : 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
        },
      });
    }

    return saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: { ...config[tab], [label]: timezone || input },
    });
  };

  getInputField = (tab: ModelType, label: string, input: string) => {
    const { translate, config } = this.props;
    switch (input) {
      case 'boolean':
        return (
          <BooleanSelector label={label} />
        );
      case 'text':
        return <TextInput label={label} />;
      case 'select_timezone':
        return (
          <TimezonePicker
            value={config[tab][label]?.name}
            onChange={(timezone: string) => this.handleTextInput(tab, label, timezone)}
            className="setting__select__timezone"
            inputProps={{
              placeholder: translate('pick_timezone'),
              name: 'timezone',
            }}
          />
        );
      case 'select':
      case 'select_month':
      case 'select_number':
        return (
          <RadioFieldsSelector
            label={label}
            input={input}
            handleTextInput={this.handleTextInput}
          />
        );
      case 'select_weekdays':
        return (
          <WeekdaysSelector />
        );
      case 'select_monthly_days':
        return (
          <MonthlyDaysSelector />
        );
      case 'select_months':
        return (
          <MonthSelector />
        );
      case 'select_hours':
        return (
          <RadioHoursSelector />
        );
      case 'select_battery':
        return (
          <BatterySelector />
        );
      case 'expert_values':
        return (
          <ValuesSelector label={label} />
        );
      case 'free_values':
        return (
          <NumberInput label={label} />
        );
      case 'frame_fields_list':
        return (
          <FrameFieldsList label={label} />
        );
      case 'radio_calendar':
        return (
          <RadioCalendarRow label={label} showRadioDays={config[tab].radio_days_of_week_active} />
        );
      default:
        return input;
    }
  };

  getActiveAccordion = (generalCollapsed: boolean, wirelessCollapsed: boolean) => {
    if (!generalCollapsed && wirelessCollapsed) return '0';
    if (generalCollapsed && !wirelessCollapsed) return '1';
    return '';
  };

  hideSetting = (accordion: string, setting: DeviceSetting, tab: ModelType, config: PageAccordionState, isExpert: boolean) => {
    const hiddenSontexSettings = [
      'radio_days_of_week_active', 'radio_mode', 'radio_frame', 'radio_frequency', 'radio_months',
    ];
    const hiddenSettingsForLora = [
      'radio_frequency', 'radio_mode', 'radio_frame', 'radio_frequency', 'encryption_mode', 'aes_key', 'individual_aes_key',
      'radio_days_of_week_active', 'radio_months', 'radio_days', 'radio_hours', 'radio_days_of_month',
    ];
    if (!isExpert && setting.input === 'hidden') return true;
    if (isExpert && setting.expert === 'hidden') return true;
    if (config[tab].radio_protocol !== RadioProtocol.LORA_868 && accordion === 'wireless' && loraSettings.includes(setting.label)) return true;
    if (config[tab].radio_protocol === RadioProtocol.LORA_868 && hiddenSettingsForLora.includes(setting.label)) return true;
    if (tab === 'ewz2' && config[tab].radio_days_of_week_active && setting.label === 'radio_days_of_month') return true;
    if (tab === 'ewz2' && !config[tab].radio_days_of_week_active && setting.label === 'radio_days') return true;
    if (config[tab].radio_protocol !== RadioProtocol.LORA_868 && setting.label === 'lorawan_radio_calendar_config') return true;
    if (tab === 'ewz2' && accordion === 'wireless' && setting.label !== 'radio_protocol' && config[tab].radio_protocol === 'please-choose') return true;
    if ((tab === 'ewz2' && config[tab].encryption_mode === 'off') && (setting.label === 'individual_aes_key' || setting.label === 'aes_key')) return true;
    if (config[tab].radio_protocol === RadioProtocol.SONTEX_433 && hiddenSontexSettings.includes(setting.label)) return true;
    return false;
  };

  render() {
    const {
      tab,
      config: {
        generalCollapsed, wirelessCollapsed, invalidValueFields: { invalidAesKey, invalidPassword }, currentConfig,
      },
      config,
      isExpert,
    } = this.props;

    let generalSettings = [];
    let wirelessSettings = [];
    if (inputFieldsByDeviceType && (inputFieldsByDeviceType as any)[tab]) {
      generalSettings = (inputFieldsByDeviceType as any)[tab].general_settings;
      wirelessSettings = (inputFieldsByDeviceType as any)[tab].wireless_settings;
    }

    return (
      <Form key={`${tab}-form`} onSubmit={(e: React.FormEvent<HTMLFormElement>) => { e.preventDefault(); }}>
        <Accordion
          defaultActiveKey=""
          activeKey={this.getActiveAccordion(generalCollapsed, wirelessCollapsed)}
        >
          <Card className="config-card-1">
            <Accordion.Toggle
              className={invalidPassword !== tab ? '' : 'invalid-input'}
              style={{ backgroundColor: generalCollapsed ? '' : '#eee' }}
              as={Card.Header}
              eventKey="0"
              onClick={() => this.toggleCollapsed('general')}
            >
              <DefaultConfigButtons
                currentConfig={currentConfig}
                generalCollapsed={generalCollapsed}
                wirelessCollapsed={wirelessCollapsed}
              />
              <span className="d-flex justify-content-center">
                <FontAwesomeIcon className="setting__accordion__icon" icon={faSlidersH} />
                {' '}
                <Translate id="general_settings.label" />
                <div className="setting__accordion__indicator">
                  {generalCollapsed
                    ? <FontAwesomeIcon icon={faAngleRight} />
                    : <FontAwesomeIcon icon={faAngleDown} />}
                </div>
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="px-0">
                {generalSettings.map((setting: DeviceSetting) => {
                  if (this.hideSetting('general', setting, tab, config, isExpert)) return null;
                  let isLeakagePipeBreak = false;
                  if (tab === 'ewz2' && setting.label === 'leakage') isLeakagePipeBreak = true;
                  return (
                    <div key={setting.label} className="setting-row">
                      <Row>
                        <Col>
                          <OverlayTrigger
                            key={`${tab}${setting.label}`}
                            placement="right"
                            trigger={['hover']}
                            delay={{ show: 500, hide: 0 }}
                            overlay={(
                              <Tooltip id={`tooltip-${setting.label}`}>
                                {isLeakagePipeBreak ? <Translate id="general_settings.settings.leakage-pipe-break.description" />
                                  : <Translate id={`general_settings.settings.${setting.label}.description`} />}
                              </Tooltip>
                            )}
                          >
                            <div className="setting__label">
                              {isLeakagePipeBreak ? <Translate id="general_settings.settings.leakage-pipe-break.label" />
                                : <Translate id={`general_settings.settings.${setting.label}.label`} />}
                            </div>
                          </OverlayTrigger>
                        </Col>
                        {isExpert ? (
                          <OverlayTrigger
                            key={`${tab}${setting.label}`}
                            placement="left"
                            trigger={['hover']}
                            delay={{ show: 500, hide: 0 }}
                            overlay={(
                              <Tooltip id={`tooltip-${setting.label}`}>
                                {setting.expert ? isLeakagePipeBreak ? <Translate id="general_settings.settings.leakage-pipe-break.expert" />
                                  : <Translate id={`general_settings.settings.${setting.label}.expert`} />
                                  : <Translate id={`general_settings.settings.${setting.label}.description`} />}
                              </Tooltip>
                            )}
                          >
                            <Col>
                              {this.getInputField(tab, setting.label, setting.expert || setting.input)}
                            </Col>
                          </OverlayTrigger>
                        )
                          : (
                            <OverlayTrigger
                              key={`${tab}${setting.label}`}
                              placement="left"
                              trigger={['hover']}
                              delay={{ show: 500, hide: 0 }}
                              overlay={(
                                <Tooltip id={`tooltip-${setting.label}`}>
                                  {isLeakagePipeBreak ? <Translate id="general_settings.settings.leakage-pipe-break.description" />
                                    : <Translate id={`general_settings.settings.${setting.label}.description`} />}
                                </Tooltip>
                              )}
                            >
                              <Col>
                                {this.getInputField(tab, setting.label, setting.input)}
                              </Col>
                            </OverlayTrigger>
                          )}
                      </Row>
                    </div>
                  );
                })}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="config-card-2">
            <Accordion.Toggle
              className={(invalidAesKey === tab && !config[tab].individual_aes_key) ? 'invalid-input' : ''}
              style={{ backgroundColor: wirelessCollapsed ? '' : '#eee' }}
              as={Card.Header}
              eventKey="1"
              onClick={() => this.toggleCollapsed('wireless')}
            >
              {tab === 'ewz2' && (
                <span className="setting__accordion__radio-protocol">
                  <Translate id={`wireless_settings.settings.radio_protocol.values.${config[tab].radio_protocol}`} />
                </span>
              )}
              <span className="d-flex justify-content-center">
                <FontAwesomeIcon className="setting__accordion__icon" icon={faBroadcastTower} />
                {' '}
                <Translate id="wireless_settings.label" />
                <div className="setting__accordion__indicator">
                  {wirelessCollapsed
                    ? <FontAwesomeIcon icon={faAngleRight} />
                    : <FontAwesomeIcon icon={faAngleDown} />}
                </div>
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="px-0 pb-0">
                {wirelessSettings.map((setting: DeviceSetting) => {
                  if (this.hideSetting('wireless', setting, tab, config, isExpert)) return null;
                  return (
                    <div key={setting.label} className="setting-row">
                      <Row className={setting.label === 'radio_hours' ? tab === 'ewz2' ? 'pt-1' : 'pb-3' : ''}>
                        <Col xs={4}>
                          <OverlayTrigger
                            key={`${tab}${setting.label}`}
                            placement="right"
                            trigger={['hover']}
                            delay={{ show: 500, hide: 0 }}
                            overlay={(
                              <Tooltip id={`tooltip-${setting.label}`}>
                                <Translate id={`wireless_settings.settings.${setting.label}.description`} />
                              </Tooltip>
                            )}
                          >
                            <div className="setting__label">
                              <Translate id={`wireless_settings.settings.${setting.label}.label`} />
                            </div>
                          </OverlayTrigger>
                        </Col>
                        {isExpert ? (
                          <OverlayTrigger
                            key={`${tab}${setting.label}`}
                            placement="left"
                            trigger={['hover']}
                            delay={{ show: 500, hide: 0 }}
                            overlay={(
                              <Tooltip id={`tooltip-${setting.label}`}>
                                {setting.expert ? <Translate id={`wireless_settings.settings.${setting.label}.expert`} />
                                  : <Translate id={`wireless_settings.settings.${setting.label}.description`} />}
                              </Tooltip>
                            )}
                          >
                            <Col xs={8}>
                              {this.getInputField(tab, setting.label, setting.expert || setting.input)}
                            </Col>
                          </OverlayTrigger>
                        )
                          : (
                            <OverlayTrigger
                              key={`${tab}${setting.label}`}
                              placement="left"
                              trigger={['hover']}
                              delay={{ show: 500, hide: 0 }}
                              overlay={(
                                <Tooltip id={`tooltip-${setting.label}`}>
                                  <Translate id={`wireless_settings.settings.${setting.label}.description`} />
                                </Tooltip>
                              )}
                            >
                              <Col xs={8}>
                                {this.getInputField(tab, setting.label, setting.input)}
                              </Col>
                            </OverlayTrigger>
                          )}
                      </Row>
                    </div>
                  );
                })}
                <BatteryLifetime />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
  isExpert: state.isExpert,
  currentCustomer: state.currentCustomer,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(PageAccordion));
