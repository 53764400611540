import { SET_IS_EXPERT, IsExpertActionTypes, IsExpertState } from '../actionTypes';

const initialState: IsExpertState = false;

const isExpertReducer = (
  state: IsExpertState = initialState,
  action: IsExpertActionTypes,
): IsExpertState => {
  switch (action.type) {
    case SET_IS_EXPERT:
      if (action.payload !== undefined) {
        return action.payload;
      }
      return !state;

    default:
      return state;
  }
};

export default isExpertReducer;
