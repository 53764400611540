import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ModelType } from '../../../config/models';

import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';

interface WeekdaysSelectorProps {
  tab: ModelType;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
}

interface WeekdaysSelectorState {
}

class WeekdaysSelector extends React.Component<WeekdaysSelectorProps, WeekdaysSelectorState> {
  handleWeekdayChange = (tab: ModelType, weekday: string, checked: boolean) => {
    const { config, saveAllConfigs } = this.props;
    saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: {
        ...config[tab],
        radio_days: {
          ...config[tab].radio_days,
          [weekday]: checked,
        },
      },
    });
  };

  render() {
    const { tab, config: state } = this.props;
    return (
      <React.Fragment key={`weekdays${tab}`}>
        <div className="weekDays-selector" key={`weekdays-selector-${tab}`}>
          <input
            type="checkbox"
            id="weekday-mon"
            className="weekday"
            checked={state[tab].radio_days.monday}
            onChange={(event: React.FormEvent) => { this.handleWeekdayChange(tab, 'monday', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="weekday-mon"><Translate id="weekdays.short.mo" /></label>
          <input
            type="checkbox"
            id="weekday-tue"
            className="weekday"
            checked={state[tab].radio_days.tuesday}
            onChange={(event: React.FormEvent) => { this.handleWeekdayChange(tab, 'tuesday', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="weekday-tue"><Translate id="weekdays.short.tu" /></label>
          <input
            type="checkbox"
            id="weekday-wed"
            className="weekday"
            checked={state[tab].radio_days.wednesday}
            onChange={(event: React.FormEvent) => { this.handleWeekdayChange(tab, 'wednesday', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="weekday-wed"><Translate id="weekdays.short.we" /></label>
          <input
            type="checkbox"
            id="weekday-thu"
            className="weekday"
            checked={state[tab].radio_days.thursday}
            onChange={(event: React.FormEvent) => { this.handleWeekdayChange(tab, 'thursday', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="weekday-thu"><Translate id="weekdays.short.th" /></label>
          <input
            type="checkbox"
            id="weekday-fri"
            className="weekday"
            checked={state[tab].radio_days.friday}
            onChange={(event: React.FormEvent) => { this.handleWeekdayChange(tab, 'friday', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="weekday-fri"><Translate id="weekdays.short.fr" /></label>
          <input
            type="checkbox"
            id="weekday-sat"
            className="weekday"
            checked={state[tab].radio_days.saturday}
            onChange={(event: React.FormEvent) => { this.handleWeekdayChange(tab, 'saturday', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="weekday-sat"><Translate id="weekdays.short.sa" /></label>
          <input
            type="checkbox"
            id="weekday-sun"
            className="weekday"
            checked={state[tab].radio_days.sunday}
            onChange={(event: React.FormEvent) => { this.handleWeekdayChange(tab, 'sunday', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="weekday-sun"><Translate id="weekdays.short.su" /></label>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WeekdaysSelector);
