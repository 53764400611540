import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

interface CustomTooltipProps {
  children: React.ReactNode | React.ReactNode[];
  id: string;
  label: string;
  placement: 'top' | 'right' | 'bottom' | 'left';
  show?: boolean;
  data?: { [key: string]: string | number };
}

function CustomTooltip(props: CustomTooltipProps) {
  const {
    children, id, label, placement, show = true, data,
  } = props;

  if (!show) {
    return <>{children}</>;
  }

  return (
    <>
      <OverlayTrigger
        key={`${id}-${label}`}
        placement={placement}
        trigger={['hover']}
        overlay={(
          <Tooltip id={`tooltip-${id}-${label}`}>
            <Translate id={label} data={data} />
          </Tooltip>
                )}
      >
        <span>
          {children}
        </span>
      </OverlayTrigger>
    </>
  );
}

export default CustomTooltip;
