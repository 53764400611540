import React, { useEffect, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { PageAccordionState } from '../PageAccordion';
import { AppState } from '../../../state/reducers/index';
import {
  IBackflow, ILeakage, ILeakageEwz2, IPipeBreak, IQmax, IZeroFlow, LCDSequence, ModelType,
} from '../../../config/models';
import { objectEquals } from '../../../helpers';

interface ValuesPresetSelectorProps extends LocalizeContextProps {
  presets: IPipeBreak | ILeakage | IQmax | IBackflow | LCDSequence | ILeakageEwz2 | IZeroFlow;
  index: number;
  tab: ModelType;
  config: PageAccordionState;
  label: string;
  saveAllConfigs: (config: PageAccordionState) => void;
}

function ValuesPresetSelector(props: ValuesPresetSelectorProps) {
  const {
    tab, config, presets, index, label, saveAllConfigs,
  } = props;
  const { label: presetsLabel } = presets;
  const id = `${tab}-${label}-${index}`;
  const [isChecked, setIsChecked] = useState(false);

  const handleButtonClick = () => {
    saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: {
        ...config[tab],
        [label]: tab === 'ewz2' ? { ...presets, active: true, lcdActive: true }
          : { ...presets, active: true },
      },
    });
  };

  useEffect(() => {
    setIsChecked(objectEquals(config[tab][label],
      tab === 'ewz2' ? { ...presets, active: true, lcdActive: true }
        : { ...presets, active: true }));
  }, [presets, config[tab][label]]);

  return (
    <>
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={handleButtonClick}
      />
      <label htmlFor={id}>
        {presetsLabel ? <Translate id={`general_settings.settings.${label}.values.${presetsLabel}`} /> : index + 1}
      </label>
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ValuesPresetSelector));
