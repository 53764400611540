import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ILeakageEwz2, IQmax, IZeroFlow, ModelType,
} from '../../../config/models';
import { calibrationPeriods, RadioProtocol } from '../../../config/options';

import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';

interface BooleanSelectorProps {
  tab: ModelType;
  label: string;
  isExpert: boolean;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
  presets?: IQmax | IZeroFlow | null;
}

const BooleanSelector = (props: BooleanSelectorProps) => {
  const {
    tab, label, config, saveAllConfigs, isExpert, presets,
  } = props;

  const handleSwitchChange = (isLcd?: boolean, isLeakagePipeBreak?: boolean) => {
    if (label === 'leakage'
      || label === 'leakage_pipe_break'
      || label === 'pipe_break'
      || label === 'qmax_error'
      || label === 'reflux_error'
      || label === 'zero_flow_error'
      || label === 'battery_low'
      || label === 'system_error'
      || label === 'manipulation'
      || label === 'radio_error'
      || label === 'calibration_period'
      || label === 'billing_date_indicator'
      || label === 'flow_indicator') {
      if (isLcd) {
        saveAllConfigs({
          ...config,
          currentConfig: '',
          [tab]: { ...config[tab], [label]: { ...config[tab][label], lcdActive: !config[tab][label]?.lcdActive } },
        });
      } else if (isLeakagePipeBreak) {
        saveAllConfigs({
          ...config,
          currentConfig: '',
          [tab]: {
            ...config[tab],
            leakage: {
              ...config[tab].leakage,
              pipeBreakLcdActive: !(config[tab].leakage as ILeakageEwz2)?.pipeBreakLcdActive,
            },
          },
        });
      } else if (config[tab][label]?.active) {
        if (presets && label === 'leakage') {
          saveAllConfigs({
            ...config,
            currentConfig: '',
            [tab]: {
              ...config[tab],
              [label]: tab === 'ewz2' ? {
                ...config[tab][label],
                ...presets,
                active: false,
                lcdActive: false,
                pipeBreakLcdActive: false,
              }
                : {
                  ...config[tab][label],
                  active: false,
                  ...presets,
                },
            },
          });
        } else if (label === 'calibration_period') {
          saveAllConfigs({
            ...config,
            currentConfig: '',
            [tab]: {
              ...config[tab],
              [label]: tab === 'ewz2' ? {
                ...config[tab][label],
                active: false,
                lcdActive: false,
                duration: calibrationPeriods[6],
              }
                : {
                  ...config[tab][label],
                  active: false,
                  duration: calibrationPeriods[6],
                },
            },
          });
        } else if (presets) {
          saveAllConfigs({
            ...config,
            currentConfig: '',
            [tab]: {
              ...config[tab],
              [label]: tab === 'ewz2' ? {
                ...config[tab][label],
                ...presets,
                active: false,
                lcdActive: false,
              }
                : {
                  ...config[tab][label],
                  active: false,
                  ...presets,
                },
            },
          });
        } else {
          saveAllConfigs({
            ...config,
            currentConfig: '',
            [tab]: {
              ...config[tab],
              [label]: tab === 'ewz2' ? {
                ...config[tab][label],
                active: false,
                lcdActive: false,
              }
                : {
                  ...config[tab][label],
                  active: false,
                },
            },
          });
        }
      } else if (label === 'leakage') {
        saveAllConfigs({
          ...config,
          currentConfig: '',
          [tab]: {
            ...config[tab],
            [label]: tab === 'ewz2' ? {
              ...config[tab][label],
              active: true,
              lcdActive: true,
              pipeBreakLcdActive: true,
            }
              : {
                ...config[tab][label],
                active: true,
              },
          },
        });
      } else {
        saveAllConfigs({
          ...config,
          currentConfig: '',
          [tab]: {
            ...config[tab],
            [label]: tab === 'ewz2' ? {
              ...config[tab][label],
              active: true,
              lcdActive: true,
            }
              : {
                ...config[tab][label],
                active: true,
              },
          },
        });
      }
    } else if (label === 'radio_days_of_week_active') {
      saveAllConfigs({
        ...config,
        currentConfig: '',
        [tab]: {
          ...config[tab],
          [label]: !config[tab][label],
        },
      });
    } else if (label === 'individual_aes_key') {
      saveAllConfigs({
        ...config,
        invalidValueFields: {
          ...config.invalidValueFields,
          invalidAesKey: '',
        },
        currentConfig: '',
        [tab]: {
          ...config[tab],
          [label]: !config[tab][label],
          aes_key: config[tab].radio_protocol === RadioProtocol.SONTEX_433 ? '00000000000000000000000000000000'
            : 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
        },
      });
    } else {
      saveAllConfigs({
        ...config,
        currentConfig: '',
        [tab]: { ...config[tab], [label]: !config[tab][label] },
      });
    }
  };

  if (label === 'leakage_pipe_break') {
    return (
      <Row className="my-2">
        <Col xs={6} />
        <Col xs={6} className="text-right">
          <Translate id="pipe-break-lcd-active" />
          {': '}
          <Form.Check
            type="switch"
            className="expert-switch"
            id={`${tab}_${label}_switch_lcd`}
            label={(config[tab].leakage as ILeakageEwz2)?.pipeBreakLcdActive ? <Translate id="on" /> : <Translate id="off" />}
            onChange={() => { handleSwitchChange(false, true); }}
            checked={(config[tab].leakage as ILeakageEwz2)?.pipeBreakLcdActive}
          />
        </Col>
      </Row>
    );
  }
  if (label === 'leakage'
    || label === 'qmax_error'
    || label === 'reflux_error'
    || label === 'zero_flow_error'
    || label === 'battery_low'
    || label === 'system_error'
    || label === 'manipulation'
    || label === 'radio_error'
    || label === 'calibration_period'
    || label === 'billing_date_indicator'
    || label === 'flow_indicator') {
    if (tab === 'ewz2' && isExpert) {
      return (
        <Row>
          <Col xs={6}>
            {!config[tab][label]?.lcdOnly && (
              <>
                <Translate id="alert-active" />
                {': '}
                <Form.Check
                  type="switch"
                  className="expert-switch"
                  id={`${tab}_${label}_switch`}
                  label={config[tab][label]?.active ? <Translate id="on" /> : <Translate id="off" />}
                  onChange={() => { handleSwitchChange(); }}
                  checked={config[tab][label]?.active}
                />
              </>
            )}
          </Col>
          {config[tab][label]?.active && (
            <Col xs={6} className="text-right">
              {label === 'leakage' ? <Translate id="leakage-lcd-active" /> : <Translate id="lcd-active" />}
              {': '}
              <Form.Check
                type="switch"
                className="expert-switch"
                id={`${tab}_${label}_switch_lcd`}
                label={config[tab][label]?.lcdActive ? <Translate id="on" /> : <Translate id="off" />}
                onChange={() => { handleSwitchChange(true); }}
                checked={config[tab][label]?.lcdActive}
              />
            </Col>
          )}
        </Row>
      );
    }
    if (label === 'billing_date_indicator'
      || label === 'flow_indicator') {
      return (
        <Form.Check
          type="switch"
          className="expert-switch"
          id={`${tab}_${label}_switch`}
          label={config[tab][label]?.lcdActive ? <Translate id="on" /> : <Translate id="off" />}
          onChange={() => { handleSwitchChange(true); }}
          checked={config[tab][label]?.lcdActive}
        />
      );
    }
    return (
      <Form.Check
        type="switch"
        className="expert-switch"
        id={`${tab}_${label}_switch`}
        label={config[tab][label]?.active ? <Translate id="on" /> : <Translate id="off" />}
        onChange={() => { handleSwitchChange(); }}
        checked={config[tab][label]?.active}
      />
    );
  }
  if (label === 'pipe_break') {
    return (
      <Form.Check
        type="switch"
        className="expert-switch"
        id={`${tab}_${label}_switch`}
        label={config[tab][label]?.active ? <Translate id="on" /> : <Translate id="off" />}
        onChange={() => { handleSwitchChange(); }}
        checked={config[tab][label]?.active}
      />
    );
  }

  if (isExpert && tab === 'ewz2' && (label === 'flow_indicator'
    || label === 'billing_date_indicator')) {
    return (
      <>
        <Translate id="alert-active" />
        {': '}
        <Form.Check
          type="switch"
          className="expert-switch"
          id={`${tab}_${label}_switch`}
          label={config[tab][label].lcdActive ? <Translate id="on" /> : <Translate id="off" />}
          onChange={() => { handleSwitchChange(true); }}
          checked={config[tab][label].lcdActive}
        />
      </>
    );
  }
  return (
    <Form.Check
      type="switch"
      className="expert-switch"
      id={`${tab}_${label}_switch`}
      label={config[tab][label] ? <Translate id="on" /> : <Translate id="off" />}
      onChange={() => { handleSwitchChange(); }}
      checked={config[tab][label]}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
  isExpert: state.isExpert,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BooleanSelector);
