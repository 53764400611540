/* eslint-disable @typescript-eslint/naming-convention */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import {
  Col, Row,
} from 'react-bootstrap';
import { faClock, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { connect } from 'react-redux';
import { faWarning } from '@fortawesome/free-solid-svg-icons';

import calcLoRaBatteryLifetime from '../../../sontex-battery-lifetime/LoRa';
import { addDays } from '../../../helpers';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';
import WarningComponent from './WarningComponent';
import { ILoraFrameOption, ModelType } from '../../../config/models';
import CustomTooltip from '../../CustomTooltip';

interface Ewz2EndDateComponentProps {
  tab: ModelType;
  config: PageAccordionState;
}

function Ewz2EndDateComponent(props: Ewz2EndDateComponentProps) {
  const { tab, config } = props;
  const values = config.ewz2;
  const {
    battery_size,
    lorawan_confirm_mode,
    lorawan_long_frame_config, lorawan_short_frame_config,
    rf_transmission_interval_sf7, rf_transmission_interval_sf8,
    rf_transmission_interval_sf9, rf_transmission_interval_sf10,
    rf_transmission_interval_sf11, rf_transmission_interval_sf12,
  } = values;

  if (battery_size === 'double') {
    return (
      <div>
        <FontAwesomeIcon className="alert-icon" icon={faWarning} />
        {' '}
        <Translate id="unsupported-lorawan-battery-size" />
      </div>
    );
  }

  if (lorawan_confirm_mode === 'always') {
    return (
      <div>
        <FontAwesomeIcon className="alert-icon" icon={faWarning} />
        {' '}
        <Translate id="unsupported-lorawan-confirm-mode" />
      </div>
    );
  }

  const [yearsLeft, setYearsLeft] = useState<number[]>();

  useEffect(() => {
    const longFramePayloadSize = (lorawan_long_frame_config as ILoraFrameOption[])?.reduce((acc, curr) => acc + curr.bytes, 0);
    const shortFramePayloadSize = (lorawan_short_frame_config as ILoraFrameOption[])?.reduce((acc, curr) => acc + curr.bytes, 0);
    const payloadSizes = [
      longFramePayloadSize, longFramePayloadSize, longFramePayloadSize,
      shortFramePayloadSize, shortFramePayloadSize, shortFramePayloadSize,
    ];

    const transmissionIntervals = [
      rf_transmission_interval_sf7, rf_transmission_interval_sf8,
      rf_transmission_interval_sf9, rf_transmission_interval_sf10,
      rf_transmission_interval_sf11, rf_transmission_interval_sf12];

    setYearsLeft(calcLoRaBatteryLifetime(payloadSizes, transmissionIntervals));
  }, [
    battery_size,
    lorawan_long_frame_config, lorawan_short_frame_config,
    rf_transmission_interval_sf7, rf_transmission_interval_sf8,
    rf_transmission_interval_sf9, rf_transmission_interval_sf10,
    rf_transmission_interval_sf11, rf_transmission_interval_sf12,
  ]);

  const referenceDate = new Date();
  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return (
    <CustomTooltip id="ewz2-lora-end-date-component" label="estimated-calculation" placement="bottom">
      <div>
        {yearsLeft?.map((lifetime, index) => (
          <CustomTooltip
            id={`maximum-battery-lifetime-reached-${index}`}
            label="maximum-battery-lifetime-reached"
            data={{ maxBatteryLifetime: lifetime }}
            placement="top"
            show={lifetime >= 16}
          >
            {/* eslint-disable-next-line react/no-array-index-key */}
            <Row key={`${lifetime}-${index}`} className="mt-2">
              <Col xs={4}>
                <FontAwesomeIcon icon={faCalendarAlt} />
                {' '}
                <Translate id={`end-date-lora.${index}`} />
              </Col>
              <Col xs={2}>
                {(addDays(referenceDate, lifetime * 365.25)).toLocaleDateString('de-DE', dateOptions as Intl.DateTimeFormatOptions)}
              </Col>
              <Col xs={3}>
                <FontAwesomeIcon icon={faClock} />
                {' '}
                <Translate id="end-date-period" />
              </Col>
              <Col xs={3}>
                {Math.round(lifetime * 365.25)}
                {' '}
                <Translate id="units.days.long" />
                {' '}
                ≈
                {' '}
                <b>
                  {Math.round(lifetime * 10) / 10}
                  {' '}
                  <Translate id="units.years.long" />
                </b>
                {' '}
                <WarningComponent
                  tab={tab}
                  minBatteryLifetime={7}
                  show={lifetime < 7}
                />
              </Col>
            </Row>
          </CustomTooltip>
        ))}
      </div>
    </CustomTooltip>
  );
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Ewz2EndDateComponent);
