import React from 'react';
import PageCard from '../Configure/PageCard';

import '../../scss/pages/start.scss';

const Start = () => (
  <PageCard
    title="start"
  />
);

export default Start;
