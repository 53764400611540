import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';
import { MonthlyRadioDaysEWZ2, RadioProtocol } from '../../../config/options';
import { ModelType } from '../../../config/models';

interface MonthlyDaysSelectorProps {
  tab: ModelType;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
}

interface MonthlyDaysSelectorState {
}

class MonthlyDaysSelector extends React.Component<MonthlyDaysSelectorProps, MonthlyDaysSelectorState> {
  handleMonthlyDayChange = (tab: ModelType, monthlyDay: string, checked: boolean) => {
    const { config, saveAllConfigs } = this.props;
    saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: {
        ...config[tab],
        radio_days_of_month: {
          ...config[tab].radio_days_of_month,
          [monthlyDay]: checked,
        },
      },
    });
  };

  render() {
    const { tab, config } = this.props;
    return (
      <React.Fragment key={`monthlyDays-${tab}`}>
        <div className="monthlyDays-selector" key={`monthlyDays-selector-${tab}`}>
          {Object.keys(MonthlyRadioDaysEWZ2).map((monthlyDay) => (
            <React.Fragment key={`monthlyDays-${tab}-${monthlyDay}`}>
              <input
                type="checkbox"
                id={`monthlyDays-${monthlyDay}`}
                disabled={config[tab].radio_protocol === RadioProtocol.SONTEX_433 && monthlyDay === 'oneToFour'}
                className="monthlyDay"
                checked={config[tab].radio_days_of_month[monthlyDay]}
                onChange={
                  (event: React.FormEvent) => { this.handleMonthlyDayChange(tab, monthlyDay, (event.target as HTMLInputElement).checked); }
                }
              />
              <label
                className={config[tab].radio_protocol === RadioProtocol.SONTEX_433 && monthlyDay === 'oneToFour' ? 'monthlyDay-disabled' : ''}
                htmlFor={`monthlyDays-${monthlyDay}`}
              >
                <Translate id={`wireless_settings.settings.monthly_radio_days.values.${monthlyDay}`} />
              </label>
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonthlyDaysSelector);
