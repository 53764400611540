import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { Translate, withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import { DispatchActionTypes } from '../../state/actionTypes';
import { setAllConfigs } from '../../state/actions/allConfigsAction';
import ConfigLoaderModal, { ValidationState } from './ConfigLoaderModal';
import ConfigCodeModal from './ConfigCodeModal';
import { AppState } from '../../state/reducers';
import { PageAccordionState } from '../TabContent/PageAccordion';
import {
  ewz2BatteryOptions, getRadioDays, loraSettings, MonthlyRadioDaysEWZ2, RadioProtocol,
} from '../../config/options';
import {
  FormDataEWZ, FormDataEWZ2, FormDataFAM, ModelType,
} from '../../config/models';

interface HashInputProps extends LocalizeContextProps {
  config: PageAccordionState;
  currentTab: ModelType;
  saveAllConfigs: (config: PageAccordionState) => void;
}

interface HashInputState {
  showLoaderModal: boolean;
  showCodeModal: boolean;
  modalConfigValidated: ValidationState;
  hash: string;
}

class HashInput extends React.Component<HashInputProps, HashInputState> {
  constructor(props: HashInputProps) {
    super(props);

    this.state = {
      showLoaderModal: false,
      showCodeModal: false,
      modalConfigValidated: ValidationState.UNVALIDATED,
      hash: '',
    };
  }

  handleShowLoader = () => {
    this.setState({ showLoaderModal: true, modalConfigValidated: ValidationState.UNVALIDATED });
  };

  handleCloseLoader = () => {
    this.setState({ showLoaderModal: false });
  };

  handleSubmitLoader = () => {
    this.handleCloseLoader();
    this.setSettingsData();
  };

  isInvalidConfig = () => {
    const { config, translate, currentTab } = this.props;
    const invalidFields = config.invalidValueFields;

    // Invalid combinations/inputs/selections of fields
    if (invalidFields.invalidPassword === currentTab) {
      return toastr.error((translate('invalid').toString()), (translate('copy-invalid-password').toString()));
    }
    if ((invalidFields.invalidAesKey === currentTab && !config[currentTab].individual_aes_key)
      || (currentTab === 'ewz2' && config[currentTab].encryption_mode !== 'off'
        && !config[currentTab].aes_key && !config[currentTab].individual_aes_key)) {
      return toastr.error((translate('invalid').toString()), (translate('copy-invalid-aes').toString()));
    }
    if (invalidFields.exit_storage_volume === currentTab || invalidFields.volume_until_error_active === currentTab) {
      return toastr.error((translate('invalid').toString()), (translate('copy-invalid-volume').toString()));
    }
    if (invalidFields.calibration_period === currentTab) {
      return toastr.error((translate('invalid').toString()), (translate('copy-invalid-calibration-period').toString()));
    }
    if (invalidFields.radio_frequency === currentTab) {
      return toastr.error((translate('invalid').toString()), (translate('copy-invalid-radio-frequency').toString()));
    }
    if (currentTab !== 'ewz2') {
      if (config[currentTab].radio_mode === 't1' && config[currentTab].radio_frame === 'long') {
        return toastr.error((translate('invalid').toString()), (translate('copy-invalid-radio-mode-and-frame').toString()));
      }
      const radioDays = Object.keys(config[currentTab].radio_days).filter((day) => config[currentTab].radio_days[day]);
      if (!radioDays.length) {
        return toastr.error((translate('invalid').toString()), (translate('copy-invalid-radio-days').toString()));
      }
    }
    let invalid = false;
    Object.keys(invalidFields).forEach((label: string) => {
      if (invalidFields[label] === currentTab) {
        invalid = true;
        if (loraSettings.includes(label)) {
          toastr.error((translate('invalid').toString()),
            (translate('invalid-lora-value', { label: translate(`wireless_settings.settings.${label}.label`) }).toString()));
        } else {
          toastr.error((translate('invalid').toString()),
            (translate('invalid-field-value', { label: translate(`general_settings.settings.${label}.label`) }).toString()));
        }
      }
      Object.keys(invalidFields[label]).forEach((key: string) => {
        if (invalidFields[label][key] === currentTab) {
          invalid = true;
          toastr.error((translate('invalid').toString()),
            (translate('invalid-field-value', { label: translate(`general_settings.settings.${label}.label`) }).toString()));
        }
      });
    });
    if (invalid) return true;
    const radioMonths = Object.keys(config[currentTab].radio_months).filter((month) => config[currentTab].radio_months[month]);
    if (!radioMonths.length) {
      return toastr.error((translate('invalid').toString()), (translate('copy-invalid-radio-months').toString()));
    }
    const radioHours = Object.keys(config[currentTab].radio_hours).filter((hour) => config[currentTab].radio_hours[Number(hour)]);
    if (!radioHours.length) {
      return toastr.error((translate('invalid').toString()), (translate('copy-invalid-radio-hours').toString()));
    }
    if (currentTab === 'ewz2') {
      if (config[currentTab].radio_protocol === RadioProtocol.SONTEX_433 && config[currentTab].battery_size === ewz2BatteryOptions[2].label) {
        return toastr.error((translate('invalid').toString()), (translate('copy-invalid-double-battery-sontex').toString()));
      }
      if (config[currentTab].radio_protocol === RadioProtocol.LORA_868 && config[currentTab].battery_size !== ewz2BatteryOptions[1].label) {
        return toastr.error((translate('invalid').toString()), (translate('copy-invalid-battery-lora').toString()));
      }
      if (config[currentTab].battery_size === ewz2BatteryOptions[0].label) {
        return toastr.error((translate('invalid').toString()), (translate('copy-invalid-small-battery').toString()));
      }
      if (config[currentTab].radio_protocol === 'please-choose') {
        return toastr.error((translate('invalid').toString()), (translate('copy-invalid-radio-protocol').toString()));
      }
      if (config[currentTab].radio_days_of_week_active) {
        const radioDays = Object.keys(config[currentTab].radio_days).filter((day) => config[currentTab].radio_days[day]);
        if (!radioDays.length) {
          return toastr.error((translate('invalid').toString()), (translate('copy-invalid-radio-days').toString()));
        }
      } else {
        const radioDays = Object.keys(config[currentTab].radio_days_of_month).filter((day) => config[currentTab].radio_days_of_month[day]);
        if (!radioDays.length) {
          return toastr.error((translate('invalid').toString()), (translate('copy-invalid-radio-days').toString()));
        }
      }
    }

    return false;
  };

  handleShowCode = () => {
    const { config, currentTab } = this.props;

    if (this.isInvalidConfig()) return null;

    this.sendConfigToServer(config, currentTab);
    return this.setState({ showCodeModal: true });
  };

  handleCloseCode = () => {
    this.setState({ showCodeModal: false });
  };

  setModalConfigValidated = (value: ValidationState) => {
    this.setState({ modalConfigValidated: value });
  };

  cleanConfigBeforeSubmit = (oldConfig: FormDataEWZ | FormDataEWZ2 | FormDataFAM) => {
    const cleanedConfig = { ...oldConfig };
    if (Object.prototype.hasOwnProperty.call(cleanedConfig, 'radio_days_of_week_active')) {
      if (cleanedConfig.radio_days_of_week_active) {
        // set all monthly days on true if days of week is active and vice versa
        cleanedConfig.radio_days_of_month = MonthlyRadioDaysEWZ2;
      } else {
        cleanedConfig.radio_days = getRadioDays();
      }
    }
    Object.keys(cleanedConfig).forEach((param) => {
      if (Object.prototype.hasOwnProperty.call(cleanedConfig[param], 'active')) {
        if (!cleanedConfig[param].active) {
          if (Object.prototype.hasOwnProperty.call(cleanedConfig[param], 'lcdActive')) {
            cleanedConfig[param].lcdActive = false;
          }
          if (Object.prototype.hasOwnProperty.call(cleanedConfig[param], 'pipeBreakLcdActive')) {
            cleanedConfig[param].pipeBreakLcdActive = false;
          }
        }
      }
    });
    return cleanedConfig;
  };

  sendConfigToServer = (config: PageAccordionState, tab: ModelType) => {
    const { translate } = this.props;
    try {
      axios.post('/config', this.cleanConfigBeforeSubmit(config[tab]))
        .then((res) => {
          if (res.status === 200) {
            this.setState({ hash: res.data });
            toastr.success((translate('config-save-success').toString()), (translate('config-save-success-text').toString()));
          }
        }).catch((error) => {
          if (error.response.status >= 400) {
            console.error('Trying to save config...', error);
            toastr.error((translate('saving-error').toString()), (translate('config-not-saved').toString()));
          }
        });
    } catch (e) {
      console.error('Trying to send config...', e);
      toastr.error((translate('sending-error').toString()), (translate('config-not-sent').toString()));
    }
  };

  setSettingsData = () => {
    const { saveAllConfigs, config, translate } = this.props;
    try {
      saveAllConfigs({
        ...config,
        currentConfig: '',
        [config.settingsToLoad.type]: config.settingsToLoad,
      });
    } catch (e) {
      console.error('Trying to input config...', e);
      toastr.error((translate('parsing-error').toString()), (translate('config-not-loaded').toString()));
    }
  };

  render() {
    const { showCodeModal, showLoaderModal, modalConfigValidated } = this.state;

    const { hash } = this.state;

    return (
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Button
          className="modal-button-code"
          variant="primary"
          onClick={this.handleShowCode}
        >
          <FontAwesomeIcon icon={faFileExport} />
          {' '}
          <Translate id="create-personal-code" />
        </Button>

        <ConfigCodeModal
          showModal={showCodeModal}
          handleClose={this.handleCloseCode}
          stringValue={hash}
        />

        <Button
          className="modal-button-load"
          variant="outline-dark"
          onClick={this.handleShowLoader}
        >
          <FontAwesomeIcon icon={faFileUpload} />
          {' '}
          <Translate id="load-personal-code" />
        </Button>

        <ConfigLoaderModal
          showModal={showLoaderModal}
          handleClose={this.handleCloseLoader}
          handleSubmit={this.handleSubmitLoader}
          isValidated={modalConfigValidated}
          setIsValidated={this.setModalConfigValidated}
        />

      </Form.Group>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentTab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(HashInput));
