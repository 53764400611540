import React from 'react';
import { } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ModelType } from '../../../config/models';

import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';

interface MonthSelectorProps {
  tab: ModelType;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
}

interface MonthSelectorState { }

class MonthSelector extends React.Component<MonthSelectorProps, MonthSelectorState> {
  handleMonthChange = (tab: ModelType, month: string, checked: boolean) => {
    const { config, saveAllConfigs } = this.props;
    saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: {
        ...config[tab],
        radio_months: {
          ...config[tab].radio_months,
          [month]: checked,
        },
      },
    });
  };

  render() {
    const { tab, config: state } = this.props;

    return (
      <>
        <div className="month-selector">
          <input
            type="checkbox"
            id="month-jan"
            className="month"
            checked={state[tab].radio_months.january}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'january', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-jan"><Translate id="months.short.jan" /></label>
          <input
            type="checkbox"
            id="month-feb"
            className="month"
            checked={state[tab].radio_months.february}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'february', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-feb"><Translate id="months.short.feb" /></label>
          <input
            type="checkbox"
            id="month-mar"
            className="month"
            checked={state[tab].radio_months.march}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'march', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-mar"><Translate id="months.short.mar" /></label>
          <input
            type="checkbox"
            id="month-apr"
            className="month"
            checked={state[tab].radio_months.april}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'april', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-apr"><Translate id="months.short.apr" /></label>
          <input
            type="checkbox"
            id="month-mai"
            className="month"
            checked={state[tab].radio_months.mai}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'mai', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-mai"><Translate id="months.short.mai" /></label>
          <input
            type="checkbox"
            id="month-jun"
            className="month"
            checked={state[tab].radio_months.june}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'june', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-jun"><Translate id="months.short.jun" /></label>
          <input
            type="checkbox"
            id="month-jul"
            className="month"
            checked={state[tab].radio_months.july}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'july', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-jul"><Translate id="months.short.jul" /></label>
          <input
            type="checkbox"
            id="month-aug"
            className="month"
            checked={state[tab].radio_months.august}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'august', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-aug"><Translate id="months.short.aug" /></label>
          <input
            type="checkbox"
            id="month-sep"
            className="month"
            checked={state[tab].radio_months.september}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'september', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-sep"><Translate id="months.short.sep" /></label>
          <input
            type="checkbox"
            id="month-oct"
            className="month"
            checked={state[tab].radio_months.october}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'october', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-oct"><Translate id="months.short.oct" /></label>
          <input
            type="checkbox"
            id="month-nov"
            className="month"
            checked={state[tab].radio_months.november}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'november', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-nov"><Translate id="months.short.nov" /></label>
          <input
            type="checkbox"
            id="month-dec"
            className="month"
            checked={state[tab].radio_months.december}
            onChange={(event: React.FormEvent) => { this.handleMonthChange(tab, 'december', (event.target as HTMLInputElement).checked); }}
          />
          <label htmlFor="month-dec"><Translate id="months.short.dec" /></label>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonthSelector);
