import React from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import PageTabs from '../../components/TabContent/PageTabs';
import { AppState } from '../../state/reducers';
import DeviceSelector from '../Start/DeviceSelector';
import SontexDeviceSelector from '../Start/SontexDeviceSelector';

interface PageCardProps {
  title: string;
  customer?: string;
  useTabs?: boolean;
}

const PageCard = (props: PageCardProps) => {
  const { title, customer, useTabs } = props;

  const getDeviceSelectedComp = () => {
    if (title === 'start') {
      if (customer === 'sontex') return <SontexDeviceSelector />;
      return <DeviceSelector />;
    }
    return null;
  };

  return (
    <Card body key={title}>
      {useTabs && <PageTabs page={title} />}
      {getDeviceSelectedComp()}
    </Card>
  );
};

const mapStateToProps = (state: AppState) => ({
  customer: state.currentCustomer,
});

export default connect(
  mapStateToProps,
  null,
)(PageCard);
