/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Col, Form } from 'react-bootstrap';
import { PageAccordionState } from '../PageAccordion';
import { AppState } from '../../../state/reducers/index';
import BooleanSelector from './BooleanSelector';
import FreeValueInput from './FreeValueInput';
import {
  IErrorAlertValues, ILeakage, ILeakageEwz2, IPipeBreak,
  IQmax,
  IZeroFlow,
  LCDSequence,
  ModelType,
} from '../../../config/models';
import {
  leakagePresets, pipeBreakPresets, qmaxPresets, refluxPresets,
  leakageFieldValues, pipeBreakFieldValues, qmaxFieldValues,
  refluxFieldValues, zeroFlowPresets, zeroFlowFieldValues,
  ewz2RefluxFieldValues, ewz2RefluxPresets, lcdSequencePresets,
  lcdSequenceValues, ewz2LeakagePresets, ewz2LeakageFieldValues,
} from '../../../config/options';
import ValuesPresetSelector from './ValuesPresetSelector';

interface ValuesSelectorProps extends LocalizeContextProps {
  tab: ModelType;
  config: PageAccordionState;
  label: string;
}

const ValuesSelector = (props: ValuesSelectorProps) => {
  const {
    tab, config, label,
  } = props;
  const [values, setValues] = useState<{
    presets: IPipeBreak[] | LCDSequence[] | ILeakageEwz2[] | ILeakage[] | IQmax[] | IZeroFlow[];
    fieldValues: IErrorAlertValues[];
  } | null>(null);

  useEffect(() => {
    switch (label) {
      case 'pipe_break':
        return setValues({ presets: pipeBreakPresets, fieldValues: pipeBreakFieldValues });
      case 'lcd_sequence':
        return setValues({ presets: lcdSequencePresets, fieldValues: lcdSequenceValues });
      case 'leakage':
        if (tab === 'ewz2') {
          return setValues({ presets: ewz2LeakagePresets, fieldValues: ewz2LeakageFieldValues });
        }
        return setValues({ presets: leakagePresets, fieldValues: leakageFieldValues });
      case 'qmax_error':
        return setValues({ presets: qmaxPresets, fieldValues: qmaxFieldValues });
      case 'reflux_error':
        if (tab === 'ewz2') {
          return setValues({ presets: ewz2RefluxPresets, fieldValues: ewz2RefluxFieldValues });
        }
        return setValues({ presets: refluxPresets, fieldValues: refluxFieldValues });
      case 'zero_flow_error':
        return setValues({ presets: zeroFlowPresets, fieldValues: zeroFlowFieldValues });
      default:
        return setValues(null);
    }
  }, []);

  const getPresets = () => {
    switch (label) {
      case 'pipe_break':
        return pipeBreakPresets[0];
      case 'leakage':
        if (tab === 'ewz2') {
          return ewz2LeakagePresets[0];
        }
        return leakagePresets[0];
      case 'qmax_error':
        return qmaxPresets[0];
      case 'reflux_error':
        if (tab === 'ewz2') {
          return ewz2RefluxPresets[0];
        }
        return refluxPresets[0];
      case 'zero_flow_error':
        return zeroFlowPresets[0];
      default:
        return null;
    }
  };

  return (
    <>
      {label !== 'lcd_sequence' && (
        <BooleanSelector
          label={label}
          presets={getPresets()}
        />
      )}
      {values && (
        <>
          {(config[tab][label]?.active) && (
            <>
              {(tab === 'ewz2' && label === 'leakage') && <BooleanSelector label="leakage_pipe_break" />}
              <span className="col input-group values-selector append-animate">
                <Col className="p-0"><Translate id="presets" /></Col>
                {values.presets?.map((presets, index: number) => (
                  <ValuesPresetSelector
                    key={`${tab}-${label}-${index}`}
                    label={label}
                    presets={presets}
                    index={index}
                  />
                ))}
              </span>
              <Form.Group className="mt-2 append-animate">
                {
                  values.fieldValues?.map((value: IErrorAlertValues, index: number) => (
                    <FreeValueInput
                      key={`${tab}-${label}-${index}`}
                      label={label}
                      value={value}
                      disabled={!config[tab][label]?.active && !config[tab][label]?.lcdActive}
                    />
                  ))
                }
              </Form.Group>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(ValuesSelector));
