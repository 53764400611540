import React from 'react';
import ReactDOM from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxToastr from 'react-redux-toastr';
import { renderToStaticMarkup } from 'react-dom/server';

import globalTranslations from './translations/global.json';
import './scss/index.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import * as serviceWorker from './serviceWorker';
import rootReducer from './state/reducers';
import App from './components/App';

require('dotenv').config();

const store = createStore(
  rootReducer,
  composeWithDevTools(),
);

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider initialize={{
      languages: [
        { name: 'Deutsch', code: 'de' },
        { name: 'English', code: 'en' },
      ],
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage: 'de',
      },
    }}
    >
      <App />
    </LocalizeProvider>
    <ReduxToastr
      timeOut={7500}
      preventDuplicates
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
