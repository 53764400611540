import { faRandom } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Form, Button, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { PageAccordionState } from '../PageAccordion';
import { AppState } from '../../../state/reducers';
import { ModelType } from '../../../config/models';

interface TextInputProps extends LocalizeContextProps {
  label: string;
  tab: ModelType;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
}

const TextInput = (props: TextInputProps) => {
  const {
    label, config, tab, saveAllConfigs, translate,
  } = props;

  const generateRandom = (type: string) => {
    let random = '';
    const hex = '0123456789abcdef';
    switch (type) {
      case 'password':
        for (let index = 0; index < 5; index += 1) {
          random = random.concat(String(Math.round(Math.random() * 10)));
        }
        toastr.success((translate('success').toString()), (translate('generated-random-password').toString()));
        break;
      case 'lorawan_appkey':
      case 'aes_key': {
        for (let index = 0; index < 32; index += 1) {
          random += hex.charAt(Math.floor(Math.random() * 16));
        }
        random = random.toUpperCase();
        toastr.success((translate('success').toString()), (translate('generated-random-aes').toString()));
        break;
      }
      case 'lorawan_join_eui': {
        for (let index = 0; index < 16; index += 1) {
          random += hex.charAt(Math.floor(Math.random() * 16));
        }
        random = random.toUpperCase();
        toastr.success((translate('success').toString()), (translate('generated-random-aes').toString()));
        break;
      }
      default:
        break;
    }
    return random;
  };

  const handleTextInput = (input: string) => {
    let invalid = '';
    if (label === 'aes_key') {
      if (config[tab].individual_aes_key || (/^([0-9A-Fa-f]{2}){15}[0-9A-Fa-f]{2}$/.test(input) && input.length <= 32)) {
        invalid = '';
      } else {
        invalid = 'aes';
      }
    } else if (label === 'lorawan_appkey') {
      if (config[tab].lorawan_individual_appkey || (/^([0-9A-Fa-f]{2}){15}[0-9A-Fa-f]{2}$/.test(input) && input.length <= 32)) {
        invalid = '';
      } else {
        invalid = 'appkey';
      }
    } else if (label === 'lorawan_join_eui') {
      if (/^([0-9A-Fa-f]{2}){7}[0-9A-Fa-f]{2}$/.test(input) && input.length <= 16) {
        invalid = '';
      } else {
        invalid = 'joinEui';
      }
    } else if (label === 'password') {
      if (
        (tab === 'ewz2' && /^[0-9]{3,8}$/.test(input) && input.length <= 8)
        || (/^[0-9]{3,32}$/.test(input) && input.length <= 32)
      ) {
        invalid = '';
      } else {
        invalid = 'password';
      }
    }

    saveAllConfigs({
      ...config,
      currentConfig: '',
      invalidValueFields: {
        ...config.invalidValueFields,
        invalidPassword: invalid === 'password' ? tab : '',
        invalidAesKey: invalid === 'aes' ? tab : '',
        lorawan_appkey: invalid === 'appkey' ? tab : '',
        lorawan_join_eui: invalid === 'joinEui' ? tab : '',
      },
      [tab]: { ...config[tab], [label]: input },
    });
  };

  if (label === 'lorawan_appkey') {
    return (
      <>
        <Form.Control
          className={`text-input ${config.invalidValueFields.lorawan_appkey === tab && !config[tab].lorawan_individual_appkey ? 'invalid-input' : ''}`}
          type="input"
          disabled={config[tab].lorawan_individual_appkey}
          id={`${tab}_${label}_text`}
          maxLength={32}
          onChange={(event: React.FormEvent) => { handleTextInput((event.target as HTMLInputElement).value.toUpperCase()); }}
          value={config[tab].lorawan_individual_appkey ? '' : config[tab][label]}
        />
        <OverlayTrigger
          key={`button-${label}`}
          placement="top"
          trigger={['hover']}
          delay={{ show: 500, hide: 0 }}
          overlay={(
            <Tooltip id={`tooltip-button-${label}`}>
              <Translate id="generate-random" />
            </Tooltip>
          )}
        >
          <Button
            className="generate-random-button"
            disabled={config[tab].lorawan_individual_appkey}
            variant="outline-dark"
            onClick={() => { handleTextInput(generateRandom('lorawan_appkey')); }}
          >
            <FontAwesomeIcon icon={faRandom} />
            {' '}
          </Button>
        </OverlayTrigger>
      </>
    );
  }

  if (label === 'lorawan_join_eui') {
    return (
      <>
        <Form.Control
          className={`text-input ${config.invalidValueFields.lorawan_join_eui === tab ? 'invalid-input' : ''}`}
          type="input"
          id={`${tab}_${label}_text`}
          maxLength={16}
          onChange={(event: React.FormEvent) => { handleTextInput((event.target as HTMLInputElement).value.toUpperCase()); }}
          value={config[tab][label]}
        />
      </>
    );
  }

  if (label === 'aes_key') {
    return (
      <>
        <Form.Control
          className={`text-input ${config.invalidValueFields.invalidAesKey === tab && !config[tab].individual_aes_key ? 'invalid-input' : ''}`}
          type="input"
          disabled={config[tab].individual_aes_key}
          id={`${tab}_${label}_text`}
          maxLength={32}
          onChange={(event: React.FormEvent) => { handleTextInput((event.target as HTMLInputElement).value.toUpperCase()); }}
          value={config[tab].individual_aes_key ? '' : config[tab][label]}
        />
        <OverlayTrigger
          key={`button-${label}`}
          placement="top"
          trigger={['hover']}
          delay={{ show: 500, hide: 0 }}
          overlay={(
            <Tooltip id={`tooltip-button-${label}`}>
              <Translate id="generate-random" />
            </Tooltip>
          )}
        >
          <Button
            className="generate-random-button"
            disabled={config[tab].individual_aes_key}
            variant="outline-dark"
            onClick={() => { handleTextInput(generateRandom('aes_key')); }}
          >
            <FontAwesomeIcon icon={faRandom} />
            {' '}
          </Button>
        </OverlayTrigger>
      </>
    );
  }
  // label === password
  return (
    <>
      <OverlayTrigger
        key={`form-${tab}${label}`}
        placement="top"
        trigger={['hover']}
        delay={{ show: 500, hide: 0 }}
        overlay={(
          <Tooltip id={`tooltip-${label}`}>
            <Translate id="general_settings.settings.password.values" data={{ chars: tab !== 'ewz2' ? '32' : '8' }} />
          </Tooltip>
        )}
      >
        <Form.Control
          className={`text-input ${config.invalidValueFields.invalidPassword === tab ? 'invalid-input' : ''}`}
          type="input"
          id={`${tab}_${label}_text`}
          maxLength={tab === 'ewz2' ? 8 : 32}
          onChange={(event: React.FormEvent) => { handleTextInput((event.target as HTMLInputElement).value.toUpperCase()); }}
          value={config[tab][label]}
        />
      </OverlayTrigger>
      <OverlayTrigger
        key={`button-${label}`}
        placement="top"
        trigger={['hover']}
        delay={{ show: 500, hide: 0 }}
        overlay={(
          <Tooltip id={`tooltip-button-${label}`}>
            <Translate id="generate-random" />
          </Tooltip>
        )}
      >
        <Button
          className="generate-random-button"
          variant="outline-dark"
          onClick={() => { handleTextInput(generateRandom('password')); }}
        >
          <FontAwesomeIcon icon={faRandom} />
          {' '}
        </Button>
      </OverlayTrigger>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(TextInput));
