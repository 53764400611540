import React from 'react';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import {
  Navbar,
  Nav,
} from 'react-bootstrap';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCog, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import '../../scss/components/header.scss';
import { DispatchActionTypes } from '../../state/actionTypes';
import setCurrentPage from '../../state/actions/currentPageAction';
import LanguageToggle from './LanguageToggle';
import { AppState } from '../../state/reducers';

export interface Headers {
  link: string;
  label: string;
  icon: string;
}

const icons: { [key: string]: React.ReactElement<any, any> } = {
  start: <FontAwesomeIcon icon={faHome} />,
  configure: <FontAwesomeIcon icon={faCog} />,
  about: <FontAwesomeIcon icon={faInfoCircle} />,
};
interface HeaderProps {
  customer: string;
  currentPage: string;
  saveCurrentPage: (pageTitle: string) => void;
  headers: Headers[];
}

class Header extends React.Component<HeaderProps, {}> {
  componentDidUpdate = () => {
    const { saveCurrentPage } = this.props;
    const currentPage = window.location.pathname.split('/')[1] || '';
    saveCurrentPage(`/${currentPage}`);
  };

  render() {
    const { customer, currentPage, headers } = this.props;
    return (
      <Navbar
        style={customer === 'sontex' ? { backgroundColor: '#f7ad49' } : undefined}
        bg={customer === 'sontex' ? undefined : 'dark'}
        expand="md"
        variant={customer === 'sontex' ? 'light' : 'dark'}
        onClick={this.componentDidUpdate}
      >
        <Navbar.Brand as={Link} to="/">
          <Translate id="product_configurator" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" activeKey={currentPage}>
            {headers.map((header) => (
              <Nav.Link
                eventKey={header.link}
                key={header.link}
                as={Link}
                to={header.link}
              >
                {icons[header.label]}
                {' '}
                <Translate id={header.label} />
              </Nav.Link>
            ))}
            <LanguageToggle customer={customer} />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  customer: state.currentCustomer,
  currentPage: state.currentPage,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveCurrentPage: (pageTitle: string) => dispatch(setCurrentPage(pageTitle)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
