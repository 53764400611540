import React from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ModelType } from '../../../config/models';

import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';

interface BatterySelectorProps {
  tab: ModelType;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
}

const BatterySelector = (props: BatterySelectorProps) => {
  const { tab, config, saveAllConfigs } = props;

  const handleBatteryChange = (type: { id: string }) => {
    saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: {
        ...config[tab],
        small_battery: type.id === 'battery-small',
      },
    });
  };

  return (
    <React.Fragment key={`battery${tab}`}>
      <div className="battery-selector" key={`battery-selector-${tab}`}>
        <input
          type="checkbox"
          id="battery-small"
          className="battery"
          checked={config[tab].small_battery}
          onChange={(event: React.FormEvent) => { handleBatteryChange(event.target as HTMLInputElement); }}
        />
        <label htmlFor="battery-small"><Translate id="general_settings.settings.small_battery.values.small" /></label>
        <input
          type="checkbox"
          id="battery-big"
          className="battery"
          checked={!config[tab].small_battery}
          onChange={(event: React.FormEvent) => { handleBatteryChange(event.target as HTMLInputElement); }}
        />
        <label htmlFor="battery-big"><Translate id="general_settings.settings.small_battery.values.big" /></label>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BatterySelector);
