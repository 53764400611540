/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import {
  Col, Row,
} from 'react-bootstrap';
import { faClock, faEnvelope, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { connect } from 'react-redux';

import { ewz2BatteryOptions } from '../../../config/options';
import calcWMBusBatteryLifetime from '../../../sontex-battery-lifetime/wM-Bus';
import { addDays } from '../../../helpers';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';
import WarningComponent from './WarningComponent';
import { ModelType } from '../../../config/models';
import CustomTooltip from '../../CustomTooltip';

interface Ewz2EndDateComponentProps {
  tab: ModelType;
  config: PageAccordionState;
}

function Ewz2EndDateComponent(props: Ewz2EndDateComponentProps) {
  const { tab, config } = props;
  const values = config.ewz2;
  const {
    radio_hours, radio_days, radio_days_of_week_active,
    radio_days_of_month, radio_months, radio_frequency,
    radio_mode, radio_frame, encryption_mode, battery_size,
  } = values;

  const getAverageTelegrams = () => {
    const averageDaysPerYear = 365.25;
    const averageDaysPerMonth = averageDaysPerYear / 12;
    const averageWeeksPerMonth = averageDaysPerMonth / 7;

    const radioHours = Object.keys(radio_hours).filter((hour) => radio_hours[Number(hour)]).length;
    const radioMonths = Object.keys(radio_months).filter((day) => radio_months[day]).length;
    const interval = radio_frequency;
    let radioDays = 0;
    if (radio_days_of_week_active) {
      radioDays = Object.keys(radio_days).filter((day) => radio_days[day]).length;
    } else {
      Object.keys(radio_days_of_month).forEach((days) => {
        if (radio_days_of_month[days]) {
          switch (days) {
            case 'twentyNineToEnd':
              // monthlyEndDays = [3, 0.25, 3, 2, 3, 2, 3, 3, 2, 3, 2, 3];
              radioDays += 2.4375;
              break;
            default:
              radioDays += 4;
          }
        }
      });
      radioDays /= averageWeeksPerMonth;
    }
    const telegramsPerHour = (60 * 60) / interval;
    const telegramsPerDay = telegramsPerHour * radioHours;
    const telegramsPerMonth = averageWeeksPerMonth * radioDays * telegramsPerDay;
    const telegramsPerYear = radioMonths * telegramsPerMonth;
    return {
      perYear: telegramsPerYear,
      perDay: telegramsPerDay,
      perHour: telegramsPerYear ? (telegramsPerYear / 365.25) / 24 : 0,
    };
  };

  const [averageTelegrams, setAverageTelegrams] = useState(getAverageTelegrams());
  const batteryCurrents = ewz2BatteryOptions.map((battery) => battery.current);
  const [yearsLeft, setYearsLeft] = useState(
    calcWMBusBatteryLifetime(batteryCurrents, averageTelegrams.perHour, encryption_mode, radio_mode, radio_frame),
  );
  const [activeBatteryIndex, setActiveBatteryIndex] = useState(1);
  const [invalidBatteryLifetimeIndex, setInvalidBatteryLifetimeIndex] = useState<number>(-1);

  useEffect(() => {
    setAverageTelegrams(getAverageTelegrams());
  }, [radio_hours, radio_days, radio_days_of_week_active, radio_days_of_month,
    radio_months, radio_frequency, radio_mode, radio_frame, encryption_mode]);

  useEffect(() => {
    setYearsLeft(
      calcWMBusBatteryLifetime(batteryCurrents, averageTelegrams.perHour, encryption_mode, radio_mode, radio_frame),
    );
  }, [averageTelegrams]);

  useEffect(() => {
    ewz2BatteryOptions.forEach((_, index) => {
      if (battery_size === ewz2BatteryOptions[index].label) setActiveBatteryIndex(index);
    });
  }, [battery_size]);

  useEffect(() => {
    if (activeBatteryIndex === 0 && yearsLeft[0] < 7) {
      setInvalidBatteryLifetimeIndex(0);
    } else if (activeBatteryIndex === 1 && yearsLeft[1] < 7) {
      setInvalidBatteryLifetimeIndex(1);
    } else if (activeBatteryIndex === 2 && yearsLeft[2] < 7) {
      setInvalidBatteryLifetimeIndex(2);
    } else {
      setInvalidBatteryLifetimeIndex(-1);
    }
  }, [yearsLeft, activeBatteryIndex]);

  const showMaxBatteryLifetimeTooltip = (index: number, lifetime: number): boolean => {
    if (activeBatteryIndex !== index) return false;
    if (index === 0 && lifetime >= 15) return true;
    if (index > 0 && lifetime >= 16) return true;
    return false;
  };

  const getEwz2EndDates = () => {
    const referenceDate = new Date();
    const dateOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    return (
      <CustomTooltip id="ewz2-wmbus-end-date-component" label="estimated-calculation" placement="bottom">
        <div>
          {yearsLeft.map((lifetime, index) => (
            <CustomTooltip
              id={`maximum-battery-lifetime-reached-${index}`}
              label="maximum-battery-lifetime-reached"
              data={{ maxBatteryLifetime: lifetime }}
              placement="top"
              show={showMaxBatteryLifetimeTooltip(index, lifetime)}
            >
              {/* eslint-disable-next-line react/no-array-index-key */}
              <Row key={`${lifetime}-${index}`} className={activeBatteryIndex === index ? 'my-2' : 'end-date-big mt-2'}>
                <Col xs={4}>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  {' '}
                  <Translate id={`end-date-${ewz2BatteryOptions[index].label}`} />
                </Col>
                <Col xs={2}>
                  {(addDays(referenceDate, lifetime * 365.25)).toLocaleDateString('de-DE', dateOptions as Intl.DateTimeFormatOptions)}
                </Col>
                <Col xs={3}>
                  <FontAwesomeIcon icon={faClock} />
                  {' '}
                  <Translate id="end-date-period" />
                </Col>
                <Col xs={3}>
                  {Math.round(lifetime * 365.25)}
                  {' '}
                  <Translate id="units.days.long" />
                  {' '}
                  ≈
                  {' '}
                  <b>
                    {Math.round(lifetime * 10) / 10}
                    {' '}
                    <Translate id="units.years.long" />
                  </b>
                  {' '}
                  <WarningComponent
                    tab={tab}
                    minBatteryLifetime={7}
                    show={invalidBatteryLifetimeIndex === index}
                  />
                </Col>
              </Row>
            </CustomTooltip>
          ))}
        </div>
      </CustomTooltip>
    );
  };

  return (
    <>
      <Row>
        <Col xs={4}>
          <FontAwesomeIcon icon={faEnvelope} />
          {' '}
          <Translate id="radio-telegrams-per-day" />
        </Col>
        <Col xs={2}>
          {Math.round(averageTelegrams.perDay)}
        </Col>
        <Col xs={3}>
          <FontAwesomeIcon icon={faEnvelope} />
          {' '}
          <Translate id="per-year" />
        </Col>
        <Col xs={3}>
          {Math.round(averageTelegrams.perYear)}
        </Col>
      </Row>
      {getEwz2EndDates()}
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Ewz2EndDateComponent);
