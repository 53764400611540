import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { PageAccordionState } from '../PageAccordion';
import { AppState } from '../../../state/reducers';
import Modal from './ConfigureFrameModal';
import { ILoraFrameOption, ModelType } from '../../../config/models';

interface FrameFieldsListProps extends LocalizeContextProps {
  label: string;
  tab: ModelType;
  config: PageAccordionState;
}

const FrameFieldsList = (props: FrameFieldsListProps) => {
  const { label, config, tab } = props;
  const [showModal, setShowModal] = useState(false);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const sortFrameFieldList = (fieldA: ILoraFrameOption, fieldB: ILoraFrameOption) => {
    if (fieldA.sontexId < fieldB.sontexId) return -1;
    if (fieldA.sontexId > fieldB.sontexId) return 1;
    return 0;
  };

  const getFrameFieldList = () => config[tab][label].sort(sortFrameFieldList)
    .map((payload: ILoraFrameOption, index: number) => (
      index === 0 ? (
        <OverlayTrigger
          key={`frame-${payload.value}`}
          placement="top"
          trigger={['hover']}
          delay={{ show: 100, hide: 0 }}
          overlay={(
            <Tooltip id={`tooltip-frame-${payload.value}`}>
              {payload.bytes}
              {' '}
              <Translate id="bytes" />
            </Tooltip>
          )}
        >
          <span key={payload.value}>
            <Translate id={`frame_payload.${payload.value}`} />
          </span>
        </OverlayTrigger>
      )
        : (
          <span key={payload.value}>
            ,
            {' '}
            <OverlayTrigger
              key={`frame-${payload.value}`}
              placement="top"
              trigger={['hover']}
              delay={{ show: 100, hide: 0 }}
              overlay={(
                <Tooltip id={`tooltip-frame-${payload.value}`}>
                  {payload.bytes}
                  {' '}
                  <Translate id="bytes" />
                </Tooltip>
              )}
            >
              <span>
                <Translate id={`frame_payload.${payload.value}`} />
              </span>
            </OverlayTrigger>
          </span>
        )
    ));

  return (
    <>
      <div className="mr-5 mb-2">
        {getFrameFieldList()}
      </div>
      <OverlayTrigger
        key={`button-${label}`}
        placement="top"
        trigger={['hover']}
        overlay={(
          <Tooltip id={`tooltip-button-${label}`}>
            <Translate id="edit" />
          </Tooltip>
        )}
      >
        <Button
          className="generate-random-button"
          variant="primary"
          onClick={toggleShowModal}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
          {' '}
        </Button>
      </OverlayTrigger>
      <Modal
        showModal={showModal}
        handleClose={toggleShowModal}
        label={label === 'lorawan_short_frame_config'
          ? 'lorawan_short_frame_config'
          : 'lorawan_long_frame_config'}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(FrameFieldsList));
