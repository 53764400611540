/* eslint-disable no-mixed-operators */
/**
 * Calculate the battery lifetime for Sontex electronic water meter device.
 * This function calculates with the worst case values were the maximum currents for the single operations are used.
 * Information is based on the "Calculation_implementation_LoRa.xlsx"
 * @param {number[]} payloadSizes - Frame size of payloads in bytes for [SF7, SF8, SF9, SF10, SF11, SF12]
 * @param {number[]} transmissionIntervals - Array of RF transmission intervals in minutes per SF
 * @returns {number[]} Lifetime in years per RF transmission interval
 */
const calcBatteryLifetime = (
  payloadSizes = [27, 27, 27, 27, 27, 27],
  transmissionIntervals = [15, 15, 15, 120, 240, 360],
) => transmissionIntervals.map((transmissionInterval, index) => {
  const tsym = [1.024, 2.048, 4.096, 8.192, 16.384, 32.768];
  const uplinkAirtimeParameters = [7, 8, 9, 10, 9, 10];
  const uplinkAirtime = tsym[index] * (
    12.25 + (8 + Math.ceil(
      ((8 * (payloadSizes[index] + 13)) - (4 * (7 + index)) + 28 + 16) / (4 * uplinkAirtimeParameters[index]),
    ) * 5)
  );
  const rxTime = (Math.max(((2 * 6 - 8) * (tsym[index] * 1000) + 2 * (20 * 1000)) / (tsym[index] * 1000), 6)) * tsym[index];

  const uplinksPerYear = (365.25 * 24 * 60) / transmissionInterval;

  const uplinkCurrent = (uplinkAirtime / 1000 * 25) + (rxTime / 1000 * 7);
  const radioCurrent = (uplinksPerYear * uplinkCurrent) / 3600;

  const baseCurrentFirmware = 51;
  const totalConsumption = baseCurrentFirmware + radioCurrent;

  const batteryCurrent = 1200;
  const sleepCurrent = 36;

  const lifetimeInYears = (batteryCurrent - sleepCurrent) / totalConsumption;

  return lifetimeInYears > 16 ? 16 : lifetimeInYears;
});

export default calcBatteryLifetime;
