import React, { ChangeEvent } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  Col, Form, InputGroup, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { PageAccordionState } from '../PageAccordion';
import { AppState } from '../../../state/reducers';
import { IErrorAlertValues, ModelType } from '../../../config/models';

interface FreeValueInputProps extends LocalizeContextProps {
  label: string;
  value: IErrorAlertValues;
  disabled: boolean;
  tab: ModelType;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
}

const FreeValueInput = (props: FreeValueInputProps) => {
  const {
    label, config, tab, saveAllConfigs, translate, disabled,
    value: {
      description, limits, unit, type, step,
    },
  } = props;

  const handleInput = (event: ChangeEvent) => {
    const value = Number((event?.target as HTMLInputElement)?.value);
    let valid = true;
    const currentMax = type === 'min' ? config[tab][label].max : undefined;
    const currentMin = type === 'max' ? config[tab][label].min : undefined;

    // (value * 10000) % (step * 10000) => because module of float doesn't work
    if (Number.isNaN(value)
      || value < limits[0] || value > limits[1]
      || value < currentMin || value > currentMax
      || (value * 10000) % (step * 10000) !== 0) {
      valid = false;
    }

    saveAllConfigs({
      ...config,
      currentConfig: '',
      invalidValueFields: {
        ...config.invalidValueFields,
        [label]: {
          ...config.invalidValueFields[label],
          [type]: valid ? '' : tab,
        },
      },
      [tab]: {
        ...config[tab],
        [label]: {
          ...config[tab][label],
          [type]: value,
        },
      },
    });
  };

  return (
    <InputGroup>
      <Col xs={7}>
        <Form.Label className={disabled ? 'opacity-50' : ''}>
          {description === 'display-totalizer-flow' && !config[tab].flow_indicator?.lcdActive
            ? <Translate id="display-totalizer" /> : <Translate id={description} />}
        </Form.Label>
      </Col>
      <OverlayTrigger
        key={`${tab}${label}`}
        placement="top"
        trigger={['hover']}
        delay={{ show: 500, hide: 0 }}
        overlay={(
          <Tooltip id={`tooltip-${label}`}>
            <Translate id="units.limit-description" data={{ min: limits[0], max: limits[1] }} />
            {' '}
            <Translate id={`${unit}.long`} />
          </Tooltip>
        )}
      >
        <Col xs={5} className="d-flex">
          <Form.Control
            className={`${config.invalidValueFields && config.invalidValueFields[label] && config.invalidValueFields[label][type] === tab ? 'invalid-input' : ''}`}
            type="number"
            value={config[tab][label] && config[tab][label][type]}
            min={limits[0]}
            step={step}
            max={limits[1]}
            required
            disabled={disabled}
            onChange={handleInput}
          />
          <InputGroup.Append>
            <InputGroup.Text className={disabled ? 'opacity-50' : ''}>{translate(`${unit}.short`)}</InputGroup.Text>
          </InputGroup.Append>
        </Col>
      </OverlayTrigger>
    </InputGroup>
  );
};

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(FreeValueInput));
