import { PageAccordionState } from '../components/TabContent/PageAccordion';
import {
  calibrationPeriods, pipeBreakPresets, encryptionModes, ewz2BatteryOptions,
  lcdSequencePresets, radioFrames, radioFrequencies, getRadioHours,
  radioModes, radioProtocols, leakagePresets, qmaxPresets, refluxPresets,
  ewz2RefluxPresets, zeroFlowPresets, MonthlyRadioDaysEWZ2, ewz2LeakagePresets,
  encryptionModesSontex, allRadioMonthsOn, getRadioDays, loraConfirmModeOptions,
  loraNetworkModeOptions,
  loraFrameOptions,
} from './options';

export const ewz2loraPayloads = {
  lorawan_short_frame_config: {
    maxBytes: 49,
    defaultFrame: [
      loraFrameOptions[0],
      loraFrameOptions[6],
      loraFrameOptions[11],
      loraFrameOptions[12],
      loraFrameOptions[28],
    ],
  },
  lorawan_long_frame_config: {
    maxBytes: 113,
    defaultFrame: [
      loraFrameOptions[0],
      loraFrameOptions[6],
      loraFrameOptions[11],
      loraFrameOptions[12],
      loraFrameOptions[28],
    ],
  },
};

export const ewz2loraData = {
  lorawan_individual_appkey: false,
  lorawan_appkey: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
  rf_transmission_interval_sf7: 60,
  rf_transmission_interval_sf8: 60,
  rf_transmission_interval_sf9: 120,
  rf_transmission_interval_sf10: 120,
  rf_transmission_interval_sf11: 240,
  rf_transmission_interval_sf12: 360,
  lorawan_join_eui: '0000000000000000',
  lorawan_rejoin_interval: 30,
  lorawan_confirm_mode: loraConfirmModeOptions[2],
  lorawan_private_network_mode: loraNetworkModeOptions[0],
  lorawan_automatic_rtc_synchronization: false,
  lorawan_short_frame_config: ewz2loraPayloads.lorawan_short_frame_config.defaultFrame,
  lorawan_long_frame_config: ewz2loraPayloads.lorawan_long_frame_config.defaultFrame,
};

export const ewz2radioData = {
  /** Radio data */
  radio_days: getRadioDays(),
  radio_months: allRadioMonthsOn,
  radio_days_of_week_active: true,
  radio_days_of_month: MonthlyRadioDaysEWZ2,
  radio_hours: getRadioHours(),
  radio_frequency: radioFrequencies[7],
  radio_mode: radioModes[1],
  radio_frame: radioFrames[0],
  encryption_mode: encryptionModes[1],
  individual_aes_key: false,
  aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
};

export const initialState: PageAccordionState = {
  generalCollapsed: true,
  wirelessCollapsed: true,
  loadedSettingsString: '',
  invalidValueFields: {},
  settingsToLoad: {},
  currentConfig: 'default',
  ewz: {
    type: 'ewz',
    flow_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    calibration_period: {
      active: true,
      duration: calibrationPeriods[0],
    },
    pipe_break: {
      active: true,
      ...pipeBreakPresets[0],
    },
    leakage: {
      active: true,
      ...leakagePresets[0],
    },
    qmax_error: {
      active: true,
      ...qmaxPresets[0],
    },
    reflux_error: {
      active: true,
      ...refluxPresets[0],
    },
    billing_date_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    billing_date: 'december',
    timezone: {
      name: 'Europe/Berlin',
      offset: 1,
    },
    password: '0000',
    small_battery: false,
    /** Radio data */
    billing_date_radio_days: 30,
    monthly_radio_days: 31,
    radio_days: getRadioDays(),
    radio_months: allRadioMonthsOn,
    radio_hours: getRadioHours(),
    radio_frequency: radioFrequencies[7],
    radio_mode: radioModes[1],
    radio_frame: radioFrames[0],
    individual_aes_key: false,
    aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
  },
  ewz2: {
    type: 'ewz2',
    lcd_sequence: lcdSequencePresets[0],
    calibration_period: {
      active: true,
      lcdActive: false,
      duration: calibrationPeriods[0],
    },
    flow_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    leakage: {
      active: true,
      lcdActive: true,
      ...ewz2LeakagePresets[0],
    },
    qmax_error: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    reflux_error: {
      active: true,
      lcdActive: true,
      ...ewz2RefluxPresets[0],
    },
    zero_flow_error: {
      active: true,
      lcdActive: true,
      ...zeroFlowPresets[0],
    },
    flow_cut_off: 1,
    battery_low: {
      active: true,
      lcdActive: true,
    },
    system_error: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    manipulation: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    radio_error: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    billing_date_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    billing_date: 'december',
    timezone: {
      name: 'Europe/Berlin',
      offset: 1,
    },
    password: '0000',
    battery_size: ewz2BatteryOptions[1].label,
    exit_storage_volume: 5,
    volume_until_error_active: 0,
    radio_protocol: radioProtocols[0],
    ...ewz2radioData,
    ...ewz2loraData,
  },
  fam: {
    type: 'fam',
    pipe_break: {
      active: true,
      ...pipeBreakPresets[0],
    },
    leakage: {
      active: true,
      ...leakagePresets[0],
    },
    qmax_error: {
      active: true,
      ...qmaxPresets[0],
    },
    reflux_error: {
      active: true,
      ...refluxPresets[0],
    },
    null_volume_on_montage: false,
    billing_date: 'december',
    timezone: {
      name: 'Europe/Berlin',
      offset: 1,
    },
    password: '0000',
    set_wm_sn: false,
    /** Radio data */
    billing_date_radio_days: 30,
    monthly_radio_days: 31,
    radio_days: getRadioDays(),
    radio_months: allRadioMonthsOn,
    radio_hours: getRadioHours(),
    radio_frequency: radioFrequencies[7],
    radio_mode: radioModes[1],
    radio_frame: radioFrames[0],
    individual_aes_key: false,
    aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
  },
};

export const walkByState: PageAccordionState = {
  generalCollapsed: true,
  wirelessCollapsed: true,
  loadedSettingsString: '',
  invalidValueFields: {},
  settingsToLoad: {},
  currentConfig: 'walkby',
  ewz: {
    type: 'ewz',
    flow_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    calibration_period: {
      active: true,
      duration: calibrationPeriods[0],
    },
    pipe_break: {
      active: true,
      ...pipeBreakPresets[0],
    },
    leakage: {
      active: true,
      ...leakagePresets[0],
    },
    qmax_error: {
      active: true,
      ...qmaxPresets[0],
    },
    reflux_error: {
      active: true,
      ...refluxPresets[0],
    },
    billing_date_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    billing_date: 'december',
    timezone: {
      name: 'Europe/Berlin',
      offset: 1,
    },
    password: '0000',
    small_battery: false,
    /** Radio data */
    billing_date_radio_days: 30,
    monthly_radio_days: 31,
    radio_days: getRadioDays('weekDaysOnly'),
    radio_months: {
      january: true,
      february: true,
      march: false,
      april: false,
      mai: false,
      june: false,
      july: false,
      august: false,
      september: false,
      october: false,
      november: true,
      december: true,
    },
    radio_hours: getRadioHours('walkBy'),
    radio_frequency: radioFrequencies[2],
    radio_mode: radioModes[1],
    radio_frame: radioFrames[1],
    individual_aes_key: false,
    aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
  },
  ewz2: {
    type: 'ewz2',
    lcd_sequence: lcdSequencePresets[0],
    calibration_period: {
      active: true,
      lcdActive: false,
      duration: calibrationPeriods[0],
    },
    flow_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    leakage: {
      active: true,
      lcdActive: true,
      ...ewz2LeakagePresets[0],
    },
    qmax_error: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    reflux_error: {
      active: true,
      lcdActive: true,
      ...ewz2RefluxPresets[0],
    },
    zero_flow_error: {
      active: true,
      lcdActive: true,
      ...zeroFlowPresets[0],
    },
    flow_cut_off: 1,
    battery_low: {
      active: true,
      lcdActive: true,
    },
    system_error: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    manipulation: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    radio_error: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    billing_date_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    billing_date: 'december',
    timezone: {
      name: 'Europe/Berlin',
      offset: 1,
    },
    password: '0000',
    battery_size: ewz2BatteryOptions[1].label,
    exit_storage_volume: 5,
    volume_until_error_active: 0,
    /** Radio data */
    radio_protocol: radioProtocols[0],
    radio_days: getRadioDays('weekDaysOnly'),
    radio_months: {
      january: true,
      february: true,
      march: false,
      april: false,
      mai: false,
      june: false,
      july: false,
      august: false,
      september: false,
      october: false,
      november: true,
      december: true,
    },
    radio_days_of_week_active: true,
    radio_days_of_month: MonthlyRadioDaysEWZ2,
    radio_hours: getRadioHours('walkBy'),
    radio_frequency: radioFrequencies[2],
    radio_mode: radioModes[1],
    radio_frame: radioFrames[1],
    encryption_mode: encryptionModes[1],
    individual_aes_key: false,
    aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
    lorawan_individual_appkey: false,
    lorawan_appkey: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
    rf_transmission_interval_sf7: 60,
    rf_transmission_interval_sf8: 60,
    rf_transmission_interval_sf9: 120,
    rf_transmission_interval_sf10: 120,
    rf_transmission_interval_sf11: 240,
    rf_transmission_interval_sf12: 360,
    lorawan_join_eui: '0000000000000000',
    lorawan_rejoin_interval: 30,
    lorawan_confirm_mode: loraConfirmModeOptions[2],
    lorawan_private_network_mode: loraNetworkModeOptions[0],
    lorawan_automatic_rtc_synchronization: false,
    lorawan_short_frame_config: ewz2loraPayloads.lorawan_short_frame_config.defaultFrame,
    lorawan_long_frame_config: ewz2loraPayloads.lorawan_long_frame_config.defaultFrame,
  },
  fam: {
    type: 'fam',
    pipe_break: {
      active: true,
      ...pipeBreakPresets[0],
    },
    leakage: {
      active: true,
      ...leakagePresets[0],
    },
    qmax_error: {
      active: true,
      ...qmaxPresets[0],
    },
    reflux_error: {
      active: true,
      ...refluxPresets[0],
    },
    null_volume_on_montage: false,
    billing_date: 'december',
    timezone: {
      name: 'Europe/Berlin',
      offset: 1,
    },
    password: '0000',
    set_wm_sn: false,
    /** Radio data */
    billing_date_radio_days: 30,
    monthly_radio_days: 31,
    radio_days: getRadioDays('weekDaysOnly'),
    radio_months: {
      january: true,
      february: true,
      march: false,
      april: false,
      mai: false,
      june: false,
      july: false,
      august: false,
      september: false,
      october: false,
      november: true,
      december: true,
    },
    radio_hours: getRadioHours('walkBy'),
    radio_frequency: radioFrequencies[2],
    radio_mode: radioModes[1],
    radio_frame: radioFrames[1],
    individual_aes_key: false,
    aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
  },
};

export const stationaryState: PageAccordionState = {
  generalCollapsed: true,
  wirelessCollapsed: true,
  loadedSettingsString: '',
  invalidValueFields: {},
  settingsToLoad: {},
  currentConfig: 'stationary',
  ewz: {
    type: 'ewz',
    flow_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    calibration_period: {
      active: true,
      duration: calibrationPeriods[0],
    },
    pipe_break: {
      active: true,
      ...pipeBreakPresets[0],
    },
    leakage: {
      active: true,
      ...leakagePresets[0],
    },
    qmax_error: {
      active: true,
      ...qmaxPresets[0],
    },
    reflux_error: {
      active: true,
      ...refluxPresets[0],
    },
    billing_date_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    billing_date: 'december',
    timezone: {
      name: 'Europe/Berlin',
      offset: 1,
    },
    password: '0000',
    small_battery: false,
    /** Radio data */
    billing_date_radio_days: 30,
    monthly_radio_days: 31,
    radio_days: getRadioDays(),
    radio_months: allRadioMonthsOn,
    radio_hours: getRadioHours('stationary'),
    radio_frequency: radioFrequencies[12],
    radio_mode: radioModes[1],
    radio_frame: radioFrames[0],
    individual_aes_key: false,
    aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
  },
  ewz2: {
    type: 'ewz2',
    lcd_sequence: lcdSequencePresets[0],
    calibration_period: {
      active: true,
      lcdActive: false,
      duration: calibrationPeriods[0],
    },
    flow_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    leakage: {
      active: true,
      lcdActive: true,
      ...ewz2LeakagePresets[0],
    },
    qmax_error: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    reflux_error: {
      active: true,
      lcdActive: true,
      ...ewz2RefluxPresets[0],
    },
    zero_flow_error: {
      active: true,
      lcdActive: true,
      ...zeroFlowPresets[0],
    },
    flow_cut_off: 1,
    battery_low: {
      active: true,
      lcdActive: true,
    },
    system_error: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    manipulation: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    radio_error: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    billing_date_indicator: {
      active: true,
      lcdActive: true,
      lcdOnly: true,
    },
    billing_date: 'december',
    timezone: {
      name: 'Europe/Berlin',
      offset: 1,
    },
    password: '0000',
    battery_size: ewz2BatteryOptions[1].label,
    exit_storage_volume: 5,
    volume_until_error_active: 0,
    /** Radio data */
    radio_protocol: radioProtocols[0],
    radio_days: getRadioDays(),
    radio_months: allRadioMonthsOn,
    radio_days_of_week_active: true,
    radio_days_of_month: MonthlyRadioDaysEWZ2,
    radio_hours: getRadioHours('stationary'),
    radio_frequency: radioFrequencies[12],
    radio_mode: radioModes[1],
    radio_frame: radioFrames[0],
    encryption_mode: encryptionModes[1],
    individual_aes_key: false,
    aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
    lorawan_individual_appkey: false,
    lorawan_appkey: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
    rf_transmission_interval_sf7: 60,
    rf_transmission_interval_sf8: 60,
    rf_transmission_interval_sf9: 120,
    rf_transmission_interval_sf10: 120,
    rf_transmission_interval_sf11: 240,
    rf_transmission_interval_sf12: 360,
    lorawan_join_eui: '0000000000000000',
    lorawan_rejoin_interval: 30,
    lorawan_confirm_mode: loraConfirmModeOptions[2],
    lorawan_private_network_mode: loraNetworkModeOptions[0],
    lorawan_automatic_rtc_synchronization: false,
    lorawan_short_frame_config: ewz2loraPayloads.lorawan_short_frame_config.defaultFrame,
    lorawan_long_frame_config: ewz2loraPayloads.lorawan_long_frame_config.defaultFrame,
  },
  fam: {
    type: 'fam',
    pipe_break: {
      active: true,
      ...pipeBreakPresets[0],
    },
    leakage: {
      active: true,
      ...leakagePresets[0],
    },
    qmax_error: {
      active: true,
      ...qmaxPresets[0],
    },
    reflux_error: {
      active: true,
      ...refluxPresets[0],
    },
    null_volume_on_montage: false,
    billing_date: 'december',
    timezone: {
      name: 'Europe/Berlin',
      offset: 1,
    },
    password: '0000',
    set_wm_sn: false,
    /** Radio data */
    billing_date_radio_days: 30,
    monthly_radio_days: 31,
    radio_days: getRadioDays(),
    radio_months: allRadioMonthsOn,
    radio_hours: getRadioHours('stationary'),
    radio_frequency: radioFrequencies[12],
    radio_mode: radioModes[1],
    radio_frame: radioFrames[0],
    individual_aes_key: false,
    aes_key: 'EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE',
  },
};

export const sontexStateAddition = {
  encryption_mode: encryptionModesSontex[0],
  individual_aes_key: true,
  aes_key: '00000000000000000000000000000000',
  radio_months: allRadioMonthsOn,
  radio_hours: getRadioHours('sontex'),
  radio_days_of_week_active: false,
  radio_days_of_month: MonthlyRadioDaysEWZ2,
  radio_days: getRadioDays(),
  radio_frequency: 0,
  radio_mode: radioModes[1],
  radio_frame: radioFrames[0],
};

export const loraRadioCalendar = {
  radio_months: allRadioMonthsOn,
  radio_hours: getRadioHours('stationary'),
  radio_days_of_week_active: true,
  radio_days_of_month: MonthlyRadioDaysEWZ2,
  radio_days: getRadioDays(),
};

export const loraStateAddition = {
  encryption_mode: encryptionModesSontex[0],
  individual_aes_key: true,
  aes_key: '00000000000000000000000000000000',
  radio_frequency: 0,
  radio_mode: radioModes[1],
  radio_frame: radioFrames[0],
  ...loraRadioCalendar,
};

export const loraDisabledStateAddition = {
  lorawan_individual_appkey: false,
  lorawan_appkey: '00000000000000000000000000000000',
  rf_transmission_interval_sf7: 60,
  rf_transmission_interval_sf8: 60,
  rf_transmission_interval_sf9: 120,
  rf_transmission_interval_sf10: 120,
  rf_transmission_interval_sf11: 240,
  rf_transmission_interval_sf12: 360,
  lorawan_join_eui: '0000000000000000',
  lorawan_rejoin_interval: 30,
  lorawan_confirm_mode: loraConfirmModeOptions[0],
  lorawan_private_network_mode: loraNetworkModeOptions[0],
  lorawan_automatic_rtc_synchronization: false,
  lorawan_short_frame_config: ewz2loraPayloads.lorawan_short_frame_config.defaultFrame,
  lorawan_long_frame_config: ewz2loraPayloads.lorawan_long_frame_config.defaultFrame,
};
