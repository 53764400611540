import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import '../../scss/pages/about.scss';
import { Translate } from 'react-localize-redux';

const FAQ_SIZE = 4;

const About: React.FC = () => {
  const faq = [];
  for (let index = 0; index < FAQ_SIZE; index += 1) {
    faq.push(
      <React.Fragment key={index}>
        <Row className="mt-5 mb-1">
          <Col>
            <Col className="font-weight-bold">
              {index + 1}
              .
              {' '}
              <Translate id={`about-page.faq.${index + 1}.q`} />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <Col>
              <Translate id={`about-page.faq.${index + 1}.a`} />
            </Col>
          </Col>
        </Row>
      </React.Fragment>,
    );
  }
  return (
    <Card body>
      <Col className="mb-5">
        <Col>
          <Row>
            <Col className="text-center mt-3">
              <h3>
                <Translate id="about-page.heading" />
                {' '}
                v
                {process.env.REACT_APP_VERSION}
              </h3>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <h4>
                <FontAwesomeIcon icon={faQuestionCircle} />
                {' '}
                <Translate id="about-page.faq-heading" />
              </h4>
            </Col>
          </Row>
          {faq}
        </Col>
      </Col>
    </Card>
  );
};

export default About;
