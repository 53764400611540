import React from 'react';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { ModelType } from '../../../config/models';

interface WarningComponentProps {
  tab: ModelType;
  minBatteryLifetime: number;
  show: boolean;
}

function WarningComponent(props: WarningComponentProps) {
  const { tab, minBatteryLifetime, show } = props;

  if (!show) return null;

  return (
    <>
      <OverlayTrigger
        key={`${tab}-invalid-battery-lifetime`}
        placement="top"
        trigger={['hover']}
        overlay={(
          <Tooltip id={`tooltip-${tab}-invalid-battery-lifetime`}>
            <Translate id="invalid-battery-lifetime" data={{ minBatteryLifetime }} />
          </Tooltip>
        )}
      >
        <FontAwesomeIcon className="alert-icon" icon={faWarning} />
      </OverlayTrigger>
    </>
  );
}

export default WarningComponent;
