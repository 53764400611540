import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Translate } from 'react-localize-redux';
import { setAllConfigs } from '../../../state/actions/allConfigsAction';
import { DispatchActionTypes } from '../../../state/actionTypes';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';
import { RadioHoursEWZ2, RadioProtocol } from '../../../config/options';
import { ModelType } from '../../../config/models';

interface RadioHoursSelectorProps {
  tab: ModelType;
  config: PageAccordionState;
  saveAllConfigs: (config: PageAccordionState) => void;
}

interface RadioHoursSelectorState {
}

class RadioHoursSelector extends React.Component<RadioHoursSelectorProps, RadioHoursSelectorState> {
  handleRadioHourChange = (tab: ModelType, hour: string, checked: boolean) => {
    const { config, saveAllConfigs } = this.props;
    saveAllConfigs({
      ...config,
      currentConfig: '',
      [tab]: {
        ...config[tab],
        radio_hours: {
          ...config[tab].radio_hours,
          [hour]: checked,
        },
      },
    });
  };

  render() {
    const { tab, config } = this.props;
    if (config[tab].radio_protocol === RadioProtocol.SONTEX_433) {
      return <Translate id="radio-hours-6-20" />;
    }
    return (
      <React.Fragment key={`radioHours-${tab}`}>
        <div className="radioHours-selector" key={`radioHours-selector-${tab}`}>
          {Object.keys(RadioHoursEWZ2).map((hour) => (
            <React.Fragment key={`radioHours-${tab}-${hour}`}>
              <input
                type="checkbox"
                id={`radioHours-${hour}`}
                className="radioHour"
                checked={config[tab].radio_hours[Number(hour)]}
                onChange={(event: React.FormEvent) => { this.handleRadioHourChange(tab, hour, (event.target as HTMLInputElement).checked); }}
              />
              <label htmlFor={`radioHours-${hour}`}>
                {hour}
                :00 -
                {' '}
                {Number(hour) + 1}
                :00
              </label>
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  tab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = (dispatch: Dispatch<DispatchActionTypes>) => ({
  saveAllConfigs: (config: PageAccordionState) => dispatch(setAllConfigs(config)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RadioHoursSelector);
