import React from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

import EndDateComponent from './EndDateComponent';
import Ewz2WMBusEndDateComponent from './Ewz2WMBusEndDateComponent';
import Ewz2LoRaEndDateComponent from './Ewz2LoRaEndDateComponent';
import { AppState } from '../../../state/reducers';
import { PageAccordionState } from '../PageAccordion';
import { RadioProtocol } from '../../../config/options';

interface BatteryLifetimeProps extends LocalizeContextProps {
  config: PageAccordionState;
  currentTab: 'ewz' | 'ewz2' | 'fam';
}

const BatteryLifetime = (props: BatteryLifetimeProps) => {
  const { currentTab, config } = props;
  const { ewz2 } = config;

  if (currentTab === 'ewz2') {
    if (ewz2.radio_protocol === RadioProtocol.OMS_868) {
      return (
        <Card body className="battery-lifetime mt-4">
          <Ewz2WMBusEndDateComponent />
        </Card>
      );
    }
    if (ewz2.radio_protocol === RadioProtocol.LORA_868) {
      return (
        <Card body className="battery-lifetime mt-4">
          <Ewz2LoRaEndDateComponent />
        </Card>
      );
    }
    return null;
  }
  return (
    <Card body className="battery-lifetime mt-4">
      <EndDateComponent />
    </Card>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentTab: state.currentTab,
  config: state.allConfigs,
});

const mapDispatchToProps = () => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLocalize(BatteryLifetime));
