import React from 'react';
import {
  Modal, Form, Button, Row, Col,
} from 'react-bootstrap';
import { Translate, withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboard, faCheck, faFileExport, faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import PrintButton from '../Header/PrintButton';

interface ConfigCodeModalProps extends LocalizeContextProps {
  showModal: boolean;
  stringValue: string;
  handleClose: () => void;
}

const ConfigCodeModal = (props: ConfigCodeModalProps) => {
  const {
    showModal,
    handleClose,
    stringValue,
    translate,
  } = props;

  const textArea = React.useRef<HTMLInputElement>(null);
  const [company, setCompany] = React.useState<string>('');
  const [project, setProject] = React.useState<string>('');

  React.useEffect(() => {
    const companyValue = localStorage.getItem('company');
    const projectValue = localStorage.getItem('project');
    setCompany(companyValue || '');
    setProject(projectValue || '');
  }, []);

  const copyToClipboard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    textArea.current?.select();
    document.execCommand('copy');
    (e.target as HTMLInputElement)?.focus();
    toastr.success((translate('copy-success').toString()), (translate('copy-success-text').toString()));
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FontAwesomeIcon icon={faFileExport} />
            {' '}
            <Translate id="your_personal_code" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="config-code-success p-3">
            <Row>
              <Col>
                <Col>
                  <h5><Translate id="config-code" /></h5>
                  <Translate id="copy-modal-body-1" />
                </Col>
              </Col>
            </Row>
            <br />
            <Row>
              {
                document.queryCommandSupported('copy')
                && (
                  <div
                    className="copy-button"
                  >
                    <Button
                      onClick={copyToClipboard}
                      className="copy-button__dark"
                    >
                      <FontAwesomeIcon icon={faClipboard} />
                      {' '}
                      <Translate id="copy" />
                    </Button>
                  </div>
                )
              }
            </Row>
            <Form.Control
              className="hash-input-short"
              ref={textArea}
              value={stringValue}
              onChange={() => { }}
            />
            <br />
            <Row>
              <Col>
                <Col>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  {' '}
                  <Translate id="copy-modal-body-2" />
                </Col>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="p-3">
            <Row>
              <Col>
                <Col>
                  <h5><Translate id="pdf-file" /></h5>
                  <div className="mb-2"><Translate id="generate-pdf-desctiption" /></div>
                  <Translate id="additional-details" />
                  :
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    id="pdfCompany"
                    placeholder={translate('pdf.company.name').toString()}
                    aria-describedby="pdf-company-description"
                    value={company}
                    onChange={(event) => {
                      setCompany(event.target.value);
                      localStorage.setItem('company', event.target.value);
                    }}
                    maxLength={35}
                  />
                  <Form.Text id="pdf-company-description" muted>
                    <Translate id="pdf.company.description" />
                  </Form.Text>
                  <Form.Control
                    type="text"
                    id="pdfProject"
                    placeholder={translate('pdf.project.name').toString()}
                    aria-describedby="pdf-project-description"
                    value={project}
                    onChange={(event) => {
                      setProject(event.target.value);
                      localStorage.setItem('project', event.target.value);
                    }}
                    maxLength={35}
                  />
                  <Form.Text id="pdf-project-description" muted>
                    <Translate id="pdf.project.description" />
                  </Form.Text>
                </Col>
                <Col className="text-center">
                  <PrintButton
                    configCode={stringValue}
                    company={company}
                    project={project}
                  />
                </Col>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose} className="ok-button">
            <FontAwesomeIcon icon={faCheck} />
            {' '}
            <Translate id="ok" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withLocalize(ConfigCodeModal);
